import { Button, Divider } from 'antd';
import { ColumnsType } from 'antd/lib/table/InternalTable';
import { get } from 'lodash';
import React from 'react';
import TableAnt from '~/components/Antd/TableAnt';
import BtnAuth from '~/components/common/BtnAuthor/BtnAuth';
import { POLICIES_NAME } from '~/modules/policy/policy.auth';
import { CouponAssignToClientSub } from '../clientSubscribe.modal';
type propsType = {
    dataSource : CouponAssignToClientSub[],
    loading : boolean,
    onRemove : (_id:any) => void
    canUpdate : boolean,
}
export default function TableCoupons({dataSource,loading,onRemove,canUpdate}:propsType) : React.JSX.Element {
    const columns : ColumnsType = [
        {
            title : 'Mã giảm giá',
            dataIndex : 'name',
            key : 'name',
            render(value, record, index) {
                return <span>{get(record,'giftCode','')} {value}</span>
            },
        },
        {
            title : 'Thao tác',
            dataIndex : '_id',
            key : '_id',
            width : 100,
            align : 'center',
            render(_id, record, index) {
                return <BtnAuth permissionName={POLICIES_NAME.promotion} isMatch={canUpdate} type='update'>
                    <Button disabled={!canUpdate} size='small' type='primary' danger onClick={() => onRemove(_id)}>Xoá</Button>
                </BtnAuth>
            },
        },
    ]
    return (
        <div>
            <Divider>Mã giảm giá đã chọn</Divider>
            <TableAnt stickyTop dataSource={dataSource} loading={loading} columns={columns}/>
        </div>
    )
}