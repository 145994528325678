import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearQuerySearch, getExistProp } from "~/utils/helpers";
import {
  getSelectors,
  useFailed, useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useFetch,
} from "~/utils/hook";
import { ACTION } from "../policy/policy.auth";
import { branchSliceAction } from "./redux/reducer";
import { RootState } from "~/redux/store";
const MODULE = "branch";
const MODULE_VI = "Chi nhánh";
const getSelector = (key : any) => (state : any) => state[MODULE][key];

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector,
} = getSelectors(MODULE);
const isLoadingWarehouse = getSelector('isLoadingWarehouse');
const listWarehouse = getSelector('listWarehouse');
const getListWarehouseFailed = getSelector('getListWarehouseFailed');

const updateApiKeySuccessSelector = getSelector('updateApiKeySuccess');
const updateApiKeyFailedSelector = getSelector('updateApiKeyFailed');
const loadingGetPermissionBranchSelector = getSelector('loadingGetPermissionBranch');
const permissionBranchSelector = getSelector('permissionBranch');
const getPermissionBranchFailedSelector = getSelector('getPermissionBranchFailed');

const loadingGetPermissionConstructorBranchSelector = getSelector('loadingGetPermissionConstructorBranch');
const permissionConstructorBranchSelector = getSelector('permissionConstructorBranch');
const getPermissionConstructorBranchFailedSelector = getSelector('getPermissionConstructorBranchFailed');

const accessPermissionBranchSuccessSelector = getSelector('accessPermissionBranchSuccess');
const accessPermissionBranchFailedSelector = getSelector('accessPermissionBranchFailed');

const denyPermissionBranchSuccessSelector = getSelector('denyPermissionBranchSuccess');
const denyPermissionBranchFailedSelector = getSelector('denyPermissionBranchFailed');
export const useBranchPaging = () => useSelector(pagingSelector);

export const useGetBranches = (params: any) => {
  return useFetchByParam({
    action: branchSliceAction.getListRequest,
    loadingSelector: loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: params,
  });
};
export const useGetBranch = (id: any) => {
  return useFetchByParam({
    action: branchSliceAction.getByIdRequest,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id,
  });
};

export const useCreateBranch = (callback?: any) => {
  useSuccess(
    createSuccessSelector,
    `Tạo mới ${MODULE_VI} thành công`,
    callback
  );
  useFailed(createFailedSelector);

  return useSubmit({
    action: branchSliceAction.createRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useUpdateBranch = (callback?: any) => {
  useSuccess(
    updateSuccessSelector,
    `Cập nhật ${MODULE_VI} thành công`,
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    action: branchSliceAction.updateRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useDeleteBranch = (callback?: any) => {
  useSuccess(deleteSuccessSelector, `Xoá ${MODULE_VI} thành công`, callback);
  useFailed(deleteFailedSelector);

  return useSubmit({
    action: branchSliceAction.deleteRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useBranchQueryParams = (tenantId?:any) => {
  const query = useQueryParams();
  const limit = query.get("limit") || 10;
  const page = query.get("page") || 1;
  const keyword = query.get("keyword");
  const createSuccess = useSelector(createSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      tenantId,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword,createSuccess,deleteSuccess,tenantId]);
};

export const useUpdateBranchParams = (
  query: any,
  listOptionSearch?: any[]
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [keyword, setKeyword] = useState(get(query, "keyword"));
  useEffect(() => {
    setKeyword(get(query, "keyword"));
  }, [query]);
  const onParamChange = (param: any) => {
    // Clear Search Query when change Params
    clearQuerySearch(listOptionSearch, query, param);

    if (!param.page) {
      query.page = 1;
    };

    // Convert Query and Params to Search Url Param
    const searchString = new URLSearchParams(
      getExistProp({
        ...query,
        ...param,
      })
    ).toString();

    // Navigate
    navigate(`${pathname}?${searchString}`);
  };

  return [keyword, { setKeyword, onParamChange }];
};


export const useUpdateApiKey = (callback?: any) => {
  useSuccess(
    updateApiKeySuccessSelector,
    `Cập nhật mã liên kết kho thành công`,
    callback
  );
  useFailed(updateApiKeyFailedSelector);
  return useSubmit({
    action: branchSliceAction.updateApiKeyRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useGetListWarehouseInPMS = () => {
  console.log('hook')
  return useFetch({
    action: branchSliceAction.getListWarehouseRequest,
    loadingSelector: isLoadingWarehouse,
    dataSelector: listWarehouse,
    failedSelector: getListWarehouseFailed,
  });
};
export const useGetPermissionConstructorBranch = (params : any) => {
  return useFetchByParam({
    action: branchSliceAction.getPermissionConstructorBranchRequest,
    loadingSelector: loadingGetPermissionConstructorBranchSelector,
    dataSelector: permissionConstructorBranchSelector,
    failedSelector: getPermissionConstructorBranchFailedSelector,
    param: params
  });
};

export const useGetPermissionBranch = (params : any) => {
  return useFetchByParam({
    action: branchSliceAction.getPermissionBranchRequest,
    loadingSelector: loadingGetPermissionBranchSelector,
    dataSelector: permissionBranchSelector,
    failedSelector: getPermissionBranchFailedSelector,
    param: params
  });
};

export const useAccessPermissionBranch = (callback?:any) => {
  useSuccess(accessPermissionBranchSuccessSelector, '', callback);
  useFailed(accessPermissionBranchFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: branchSliceAction.accessPermissionBranchRequest
  });
};

export const useDenyPermissionBranch = (callback?:any) => {
  useSuccess(denyPermissionBranchSuccessSelector, '', callback);
  useFailed(denyPermissionBranchFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: branchSliceAction.denyPermissionBranchRequest
  });
};
export const usePermissionColumns = (RenderPermission : any) => {
  const actionColumns = ACTION.map(({ name, key } : any) => {
    return ({
      title: name,
      key: key,
      width : 100,
      align:'center',
      render: (item : any,rc : any) => RenderPermission(key.toLowerCase(),rc)
    })
  });
  console.log(actionColumns,'actionColumns');
  return [
    {
      title: 'Chức năng',
      dataIndex: 'name',
      key: 'name',
      width : 'auto',
    },
    ...actionColumns
  ];
};
