import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Tag, Typography } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/es/table/InternalTable";
import { useCallback, useMemo, useState } from "react";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import WhiteBox from "~/components/common/WhiteBox";
import { useChangeDocumentTitle } from "~/utils/hook";
import {
  useDeleteTenant,
  useGetTenants,
  useTenantQueryParams,
  useTenantSearch,
} from "~/modules/tenant/tenant.hook";
import ModalAnt from "~/components/Antd/ModalAnt";
import TenantForm from "../components/TenantForm";
import { filterSlug } from "~/utils/helpers";
import { TenantBase } from "../tenant.modal";
import { TenantRoleBase } from "~/modules/tenantRole/tenantRole.modal";
import { Link } from "react-router-dom";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
export default function Tenant(): React.JSX.Element {
  const [id, setId]: any = useState();
  const [isOpenForm, setIsOpenForm]: any = useState(false);

  const [query] = useTenantQueryParams();
  const [kw,setKw] = useState<any>();
  const [data, isLoading , actionUpdateData] = useGetTenants(query);
  const dataSearch = useTenantSearch();  
  const dataSource = useMemo(() => kw ? dataSearch : data,[kw,data,dataSearch]);
  const [isSubmitLoading, onDelete] = useDeleteTenant();

  // Control form
  const onOpenForm = useCallback((idSelect?: any) => {
    if (idSelect) {
      setId(idSelect);
    }
    setIsOpenForm(true);
  }, []);
  const onCloseForm = useCallback(() => {
    setIsOpenForm(false);
    setId(null);
  }, []);
  // Control form Product

  // Columns Table
  const columns: ColumnsType = useMemo(
    () => [
      {
        title: "Tên đối tác",
        dataIndex: "name",
        key: "name",
        render: (name, rc) => (
          <Link to={'/tenant-detail/'+rc?._id+"/info"}>
            {name}
          </Link>
        ),
      },
      {
        title: "Số điện thoại",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "Mã định danh (subDomain)",
        dataIndex: "tenantCode",
        key: "tenantCode",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Mô tả",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Loại đối tác",
        dataIndex: "tenantRoleId",
        key: "tenantRoleId",
        render : (tenantRoleId) => tenantRoleId?.map((item : TenantRoleBase) => <Tag color={'processing'}>{item?.name}</Tag>)
      },
      {
        title: "Thao tác",
        dataIndex: "_id",
        key: "_id",
        width : 100,
        align :'center',
        render: (_id) => (
          <WithPermission permission={POLICIES.DELETE_TENANT}>
            <Popconfirm title="Xác nhận xoá" onConfirm={() => onDelete(_id)}>
            <Typography.Link type="danger">Xoá</Typography.Link>
          </Popconfirm>
          </WithPermission>
        ),
      },
    ],
    []
  );
  useChangeDocumentTitle("Danh sách đối tác phần mềm");
  const onSearch = (value : string) => {
    const dataTrue = data?.filter((item : TenantBase) => filterSlug(value,item?.name));
    actionUpdateData(dataTrue);
    setKw(value);
  }
  return (
    <div>
      <Breadcrumb title="Danh sách đối tác phần mềm" />
      <Row className="mb-3" justify={"space-between"}>
        <Col span={8}>
          <Search
            allowClear
            onChange={(e) => onSearch(e.target.value)}
            enterButton="Tìm kiếm"
            placeholder="Nhập để tìm kiếm"
          />
        </Col>
        <WithPermission permission={POLICIES.WRITE_TENANT}>
        <Col>
          <Button
            onClick={() => onOpenForm()}
            icon={<PlusCircleOutlined />}
            type="primary"
          >
            Thêm mới
          </Button>
        </Col>
        </WithPermission>
      </Row>
      <WhiteBox>
        <TableAnt
          dataSource={dataSource}
          loading={isLoading}
          columns={columns}
          rowKey={(rc) => rc?._id}
          stickyTop
          size="small"
          pagination={{
            showTotal: (total) => `Tổng cộng: ${total} `,
            showSizeChanger: true,
          }}
        />
      </WhiteBox>
      <ModalAnt
        title={id ? "Cập nhật" : "Tạo mới" + " đối tác phần mềm"}
        open={isOpenForm}
        onCancel={onCloseForm}
        footer={null}
        destroyOnClose
      >
        <TenantForm id={id} onCancel={onCloseForm} />
      </ModalAnt>
    </div>
  );
}
