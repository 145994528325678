import WpPolicyScreen from "./screens/WpPolicy";
import wpPolicyApi from "./wpPolicy.api";
import * as wpPolicyHook from "./wpPolicy.hook";
import * as wpPolicyService from "./wpPolicy.service";
import * as wpPolicyModels from "./wpPolicy.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : WpPolicyScreen,
    },
    api : wpPolicyApi,
    hook : wpPolicyHook,
    service : wpPolicyService,
    model : wpPolicyModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;