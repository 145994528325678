import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../section.api'; 
import { sectionActions } from './reducer';

function* getListSection({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(sectionActions.getListSuccess(data));
    yield put(sectionActions.clearAction());
  } catch (error:any) {
    yield put(sectionActions.getListFailed(error));
  }
}

function* getByIdSection({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(sectionActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(sectionActions.getByIdFailed(error));
  }
}

function* createSection({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(sectionActions.createSuccess(data));
    yield delay(200);
    yield put(sectionActions.resetAction());
  } catch (error:any) {
    yield put(sectionActions.createFailed(error));
  }
}

function* updateSection({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(sectionActions.updateSuccess(data));
    yield delay(200);
    yield put(sectionActions.resetAction());
  } catch (error:any) {
    yield put(sectionActions.updateFailed(error));
  }
}
function* deleteSection({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(sectionActions.deleteSuccess(data));
    yield delay(200);
    yield put(sectionActions.resetAction());
  } catch (error:any) {
    yield put(sectionActions.deleteFailed(error));
  }
}

function* getByIdProductSection({payload:id} : any) : any {
  try {
    const data = yield call(api.getProductSection,id);
    yield put(sectionActions.getProductSectionSuccess(data));
  } catch (error:any) {
    yield put(sectionActions.getProductSectionFailed(error));
  }
}

function* createProductSection({payload} : any) : any {
  try {
    const data = yield call(api.createProductSection,payload);
    yield put(sectionActions.createProductSectionSuccess(data));
    yield delay(200);
    yield put(sectionActions.resetAction());
  } catch (error:any) {
    yield put(sectionActions.createProductSectionFailed(error));
  }
}
function* updateProductSection({payload} : any) : any {
  try {
    const data = yield call(api.updateProductSection,payload);
    yield put(sectionActions.updateProductSectionSuccess(data));
    yield delay(200);
    yield put(sectionActions.resetAction());
  } catch (error:any) {
    yield put(sectionActions.updateProductSectionFailed(error));
  }
}
function* deleteProductSection({payload} : any) : any {
  try {
    const data = yield call(api.deleteProductSection,payload);
    yield put(sectionActions.deleteProductSectionSuccess(data));
    yield delay(200);
    yield put(sectionActions.resetAction());
  } catch (error:any) {
    yield put(sectionActions.deleteProductSectionFailed(error));
  }
}



export default function* sectionSaga() {
  yield takeLatest(sectionActions.getListRequest, getListSection);
  yield takeLatest(sectionActions.getByIdRequest, getByIdSection);
  yield takeLatest(sectionActions.createRequest, createSection);
  yield takeLatest(sectionActions.updateRequest, updateSection);
  yield takeLatest(sectionActions.deleteRequest, deleteSection);
  yield takeLatest(sectionActions.getProductSectionRequest, getByIdProductSection);
  yield takeLatest(sectionActions.createProductSectionRequest, createProductSection);
  yield takeLatest(sectionActions.updateProductSectionRequest, updateProductSection);
  yield takeLatest(sectionActions.deleteProductSectionRequest, deleteProductSection);
}
