import { Button, Flex, Image, Popover, Switch } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import SwitchStatus from "~/components/common/SwitchStatus";
import { GENDER_VI } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import { concatAddress } from "~/utils/helpers";
import {
    useDeleteVisitor,
    useGetVisitors,
    useUpdateVisitor,
    useUpdateVisitorParams, useVisitorPaging,
    useVisitorQueryParams
} from "../visitor.hook";
import defaultImg from '~/assets/images/image.jpeg'
type propsType = {};
export default function Visitor(props: propsType): React.JSX.Element {
//   const [open, setOpen] = useState(false);
  const [query] = useVisitorQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateVisitorParams(query);
  const [dataSource, isLoading] = useGetVisitors(query);
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_VISITOR);
  const paging = useVisitorPaging();
  const [isSubmitLoading, onUpdate] = useUpdateVisitor();
//   const [isSubmitLoading, onDelete] = useDeleteVisitor();
//   const [id, setId] = useState<any>();
//   const onOpen = useCallback((i?: any) => {
//     if (i) setId(i);
//     setOpen(true);
//   }, []);
//   const onClose = useCallback(() => {
//     setOpen(false);
//     setId(null);
//   }, []);
  const columns: ColumnsType = [
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
      align : 'center',
      width : 100,
      render : (code,rc) => <Link className="link_" to={`/visitor-detail/${rc?._id}`}>
      {code}
    </Link>
    },
    {
        title: "Tên",
        dataIndex: "fullName",
        key: "fullName",
    },
    {
      title: "Ảnh đại diện",
      dataIndex: "avatar",
      key: "avatar",
      width : 100,
      align : 'center',
      render : (avatar) => <Image src={avatar || defaultImg} style={{height : 80}}/>
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width : 120,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      render : (address) => concatAddress(address)
    },
    {
      title: "Sổ Địa chỉ",
      dataIndex: "addressStories",
      key: "addressStories",
      render : (addressStories) => <Popover
      trigger={['click']}
      content={addressStories?.length ? <ul>
        {addressStories?.map((item : any,i:any) => <li key={i}>
          {concatAddress(item)}
        </li>)}
      </ul> : <span>Rỗng</span>}
      >
        <Button type="dashed">Xem sổ địa chỉ</Button>
      </Popover>
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      width : 80,
      align : 'center',
      render : (gender) => get(GENDER_VI,gender,'')
    },
    {
      title: "Ngày sinh",
      dataIndex: "dob",
      key: "dob",
      width : 120,
      align : 'center',
      render : (dob) => dayjs(dob).format("DD-MM-YYYY")
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width : 120,
      align : 'center',
      render : (status,rc) => <SwitchStatus disabled={!canUpdate} status={status} onUpdate={onUpdate} _id={rc?._id} />
    },
    // {
    //   title: "Thao tác",
    //   key: "action",
    //   dataIndex: "_id",
    //   width: 100,
    //   align : 'center',
    //   fixed : 'right',
    //   render: (_id, rc) => (
    //     <Flex justify={'center'} align={"center"} gap={5}>
    //       <WithPermission permission={POLICIES.UPDATE_VISITOR}>
    //       <Typography.Link onClick={() => onOpen(_id)}>Sửa</Typography.Link>
    //         </WithPermission>|
    //       <WithPermission permission={POLICIES.DELETE_VISITOR}>
    //       <Popconfirm
    //         okButtonProps={{ loading: isSubmitLoading }}
    //         title="Xác nhận xoá"
    //         onConfirm={() => onDelete(_id)}
    //       >
    //         <Typography.Link type="danger">Xoá</Typography.Link>
    //       </Popconfirm>
    //       </WithPermission>
    //     </Flex>
    //   ),
    // },
  
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách khách hàng vãng lai"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <SearchAnt onParamChange={onParamChange} value={keyword || query?.keyword} onChange={(e) => setKeyword(e.target.value)}/>
        {/* <WithPermission permission={POLICIES.WRITE_VISITOR}>
        <Button onClick={() => onOpen()} type="primary" icon={<PlusOutlined />}>
          Thêm mới
        </Button>
        </WithPermission> */}
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 1500 }}
        stickyTop
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showSizeChanger: true,
          showTotal: (total) => `Tổng cộng: ${total} `,
        }}
        loading={isLoading}
      />
      {/* <ModalAnt
        title={id ? "Cập nhật khách hàng vãng lai" : "Tạo mới khách hàng vãng lai"}
        width={800}
        onCancel={onClose}
        open={open}
        destroyOnClose
        footer={null}
      >
        <VisitorForm id={id} onCancel={onClose} />
      </ModalAnt> */}
    </div>
  );
}
