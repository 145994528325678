import { Avatar, Col, Flex, Row, Typography } from "antd";
import { get } from "lodash";
import React from "react";
import { useGetVisitor_onlyGet } from "../visitor.hook";
import defaultImg from '~/assets/images/image.jpeg'
import { concatAddress } from "~/utils/helpers";
import { GENDER_VI } from "~/constants/defaultValue";
import dayjs from "dayjs";

type propsType = {};
const Item = ({ label, value }: { label: string; value: any }) => (
  <div>
    <span>{label}: </span>
    <Typography.Text strong>{value}</Typography.Text>
  </div>
);
export default function VisitorView(props: propsType): React.JSX.Element {
  const [data] = useGetVisitor_onlyGet();
  return (
    <div>
      <Row justify={"space-between"}>
        <Col span={16}>
          <Flex gap={10} vertical>
            <Item label="Họ và tên" value={get(data, "fullName", "")} />
            <Item label="Mã" value={get(data, "code", "")} />
            <Item label="Số điện thoại" value={get(data, "phoneNumber", "")} />
            <Item label="Email" value={get(data, "email", "")} />
            <Item label="Địa chỉ" value={concatAddress(get(data, "address", ""))} />
            <Item label="Giới tính" value={get(GENDER_VI,get(data, "gender", ""),'')} />
            <Item label="Ngày sinh" value={dayjs(get(data,'dob')).format("DD-MM-YYYY")} />
          </Flex>
        </Col>
        <Col span={8}>
          <Avatar
            src={get(data, "avatar", defaultImg)}
            style={{ width: "100%", height: "100%", aspectRatio: 1 }}
          />
          
        </Col>
      </Row>
    </div>
  );
}
