import { persistReducer } from "redux-persist";

import { combineReducers } from "redux";
import localStorage from "redux-persist/es/storage";
import authModule from "~/modules/auth";
import supplierModule from "~/modules/supplier";
import branchModule from "~/modules/branch";
import productGroupModule from "~/modules/productGroup";
import pharmacyModule from "~/modules/pharmacy";
import geoModule from "~/modules/geo";
import statusModule from "~/modules/statusConfig";
import configDiscount from "~/modules/configDiscount";
import employeeModule from "~/modules/employee";
import userModule from "~/modules/user";
import userGroupModule from "~/modules/userGroup";
import policy from "~/modules/policy";
import vouchers from "~/modules/vouchers";
import reportSupplier from "~/modules/report/reportSupplier"; //
import manufacturerModule from "~/modules/manufacturer";
import productUnitModule from "~/modules/productUnit";
import rankingModule from "~/modules/ranking";
import medicineModule from "~/modules/medicine";
import productModule from "~/modules/product";
import workBoardModule from "~/modules/workBoard";
import billModule from "~/modules/sale/bill";
import quotationModule from "~/modules/sale/quotation";
import botNotificationModule from "~/modules/botNotification";
import workSprintModule from "~/modules/workSprint";
import workListModule from "~/modules/workList";
import workTaskModule from "~/modules/workTask";
import paymentVoucher from "~/modules/paymentVoucher";
import receiptVoucher from "~/modules/receiptVoucher";
import lkModule from "~/modules/sale/lk";
import productsAll from "~/modules/productsAll";
import freelanceContractPharmacy from "~/modules/freelanceContractPharmacy";
import costManagement from "~/modules/costManagement";
import orderSupplier from "~/modules/sale/orderSupplier";
import salesGroup from "~/modules/salesGroup";
import baseSalary from "~/modules/reportSalary/baseSalary/index";
import benefitConfiguration from "~/modules/reportSalary/benefitConfiguration/index";
import reportEmployee from "~/modules/report/reportEmployee/index";
import saleChannel from "~/modules/saleChannel/index";
import configurationCronTime from "~/modules/configurationCronTime";
import employeeGroup from "~/modules/employeeGroup/index";
import typePharmacy from "~/modules/typePharmacy";
import groupPharmacy from "~/modules/groupPharmacy";
import notification from '~/modules/notification';
import collaborator from '~/modules/collaborator';
import collaboratorGroup from '~/modules/collaboratorGroup';
import reportSalaryPartner from '~/modules/reportSalaryPartner/redux/reducer';
import logistic from '~/modules/logistic';
import reportProductSupplier from '~/modules/reportProductSupplier';
import reportOverview from '~/modules/reportOverview';
import requestGroup from '~/modules/requestGroup';
import reportIndividualCollaborator from '~/modules/reportIndividualCollaborator';
import reportIndividualEmployeeSeller from '~/modules/reportIndividualEmployeeSeller';
import reportGroupCollaborator from '~/modules/reportGroupCollaborator';
import reportGroupEmployeeSeller from '~/modules/reportGroupEmployeeSeller';
import warehouse from '~/modules/warehouse';
import customerSegmentation from '~/modules/customerSegmentation';
import coupon from '~/modules/coupon';
import tenant from '~/modules/tenant';
import tenantRole from '~/modules/tenantRole';
import reportSubFee from '~/modules/report/reportSubFee';
import reportShip from '~/modules/report/reportShip';
import section from '~/modules/section';
import categoryParent from '~/modules/categoryParent';
import clientSubscribe from '~/modules/clientSubscribe';
import potentialSeller from '~/modules/potentialSeller';
import visitor from '~/modules/visitor';
import newsCategory from '~/modules/newsCategory';
import news from '~/modules/news';
import wpPolicy from '~/modules/wpPolicy';

import cronSalary from "~/modules/cronSalary";
const authPersistConfig = {
  key: "auth",
  storage: localStorage,
  blacklist: [
    "loginFailed",
    "isLoading",
    "isGetProfileLoading",
    "getProfileFailed",
    "updateProfileSuccess",
    "updateProfileFailed",
    "isUpdateProfileLoading",
  ],
};
const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authModule.redux.reducer),
    supplier: supplierModule.redux.reducer,
    branch: branchModule.redux.reducer,
    geo: geoModule.redux.reducer,
    statusConfig: statusModule.redux.reducer,
    pharmacy: pharmacyModule.redux.reducer,
    employee: employeeModule.redux.reducer,
    user: userModule.redux.reducer,
    userGroup: userGroupModule.redux.reducer,
    policy: policy.redux.reducer,
    vouchers: vouchers.redux.reducer,
    productGroup:productGroupModule.redux.reducer,
    manufacturer:manufacturerModule.redux.reducer,
    productUnit:productUnitModule.redux.reducer,
    ranking:rankingModule.redux.reducer,
    medicine:medicineModule.redux.reducer,
    product:productModule.redux.reducer,
    workBoard:workBoardModule.redux.reducer,
    configDiscount: configDiscount.redux.reducer,
    bill: billModule.redux.reducer,
    quotation: quotationModule.redux.reducer,
    botNotification: botNotificationModule.redux.reducer,
    workSprint:workSprintModule.redux.reducer,
    workList:workListModule.redux.reducer,
    workTask:workTaskModule.redux.reducer,
    paymentVoucher: paymentVoucher.redux.reducer,
    receiptVoucher: receiptVoucher.redux.reducer,
    lk: lkModule.redux.reducer,
    productsAll: productsAll.redux.reducer,
    orderSupplier: orderSupplier.redux.reducer,
    freelanceContractPharmacy: freelanceContractPharmacy.redux.reducer,
    costManagement: costManagement.redux.reducer,
    salesGroup: salesGroup.redux.reducer,
    baseSalary: baseSalary.redux.reducer,
    benefitConfiguration: benefitConfiguration.redux.reducer,
    reportEmployee: reportEmployee.redux.reducer,
    employeeGroup: employeeGroup.redux.reducer,
    cronSalary: cronSalary.redux.reducer,
    reportSupplier: reportSupplier.redux.reducer,
    saleChannel: saleChannel.redux.reducer,
    configurationCronTime: configurationCronTime.redux.reducer,
    typePharmacy: typePharmacy.redux.reducer,
    groupPharmacy: groupPharmacy.redux.reducer,
    notification: notification.redux.reducer,
    collaborator: collaborator.redux.reducer,
    collaboratorGroup: collaboratorGroup.redux.reducer,
    reportSalaryPartner,
    logistic: logistic.redux.reducer,
    reportProductSupplier: reportProductSupplier.redux.reducer,
    reportOverview: reportOverview.redux.reducer,
    requestGroup: requestGroup.redux.reducer,
    reportIndividualCollaborator: reportIndividualCollaborator.redux.reducer,
    reportIndividualEmployeeSeller: reportIndividualEmployeeSeller.redux.reducer,
    reportGroupCollaborator: reportGroupCollaborator.redux.reducer,
    reportGroupEmployeeSeller: reportGroupEmployeeSeller.redux.reducer,
    warehouse: warehouse.redux.reducer,
    customerSegmentation: customerSegmentation.redux.reducer,
    coupon: coupon.redux.reducer,
    tenant: tenant.redux.reducer,
    tenantRole: tenantRole.redux.reducer,
    reportSubFee: reportSubFee.redux.reducer,
    reportShip: reportShip.redux.reducer,
    section: section.redux.reducer,
    categoryParent: categoryParent.redux.reducer,
    clientSubscribe: clientSubscribe.redux.reducer,
    potentialSeller: potentialSeller.redux.reducer,
    visitor: visitor.redux.reducer,
    newsCategory: newsCategory.redux.reducer,
    news: news.redux.reducer,
    wpPolicy: wpPolicy.redux.reducer,
});
export default rootReducer;
