import { Button, Flex, Form, Input } from 'antd'
import FormItem from 'antd/es/form/FormItem/index'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import LangFormItem from '~/components/common/LangFormItem'
import LanguageSwitchLang from '~/components/common/Language/LanguageSwitchLang'
import { LANGUAGE } from '~/constants/defaultValue'
import { Language } from '~/typings/modals'
import { useCreateManufacturer, useGetManufacturerById, useGetManufacturerById_onlyGet, useResetAction } from '../manufacturer.hook'

interface Props {
  id?: any
  callBack?: () => void
  setId?:any
  setDestroy?:any
  updateManufacturer?: (data: any) => void,
  readOnly?:boolean
};
interface FieldType {
  code: string
  key: string
  name: string
  description: string
  isAction: String
};
const hookGetData = {
  readOnly : useGetManufacturerById_onlyGet,
  notReadOnly : useGetManufacturerById
}
const ManufacturerForm: React.FC<Props> = ({ id,setId, callBack, updateManufacturer,setDestroy,readOnly }) => {
  const [language,setLanguage] = useState<Language>(LANGUAGE.VI);
  const [manufacturer, isLoading] : any = readOnly ? hookGetData.readOnly() : hookGetData.notReadOnly(id)
  const [, createManufacturer] = useCreateManufacturer(() => {
    callBack && callBack();
    setDestroy && setDestroy(true)
  });
  const [form] = Form.useForm<FieldType>();
  useResetAction();
  useEffect(() => {
    if (id && manufacturer) {
      const { name, description } = manufacturer
      form.setFieldsValue({
        name,
        description,
      })
    }else{ form.resetFields()};
  }, [id, manufacturer,form])
  const onFinish = (values: FieldType) => {
    const data: FieldType = {
      ...values,
    };
    if (id) {
      updateManufacturer && updateManufacturer({ ...data, id });
      setId(null)
    } else {
      createManufacturer({ ...data });
      form.resetFields()
    }
  };
  return (
    <>
      <Form
        name="basic"
        labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
        wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        labelAlign="left"
        style={{ maxWidth: 800 }}
        form={form}
        onFinish={onFinish}
      >
        <LanguageSwitchLang language={language} setLanguage={setLanguage} />
        <LangFormItem label="Tên" fieldName={"name"} language={language}>
          <Input readOnly={readOnly} />
        </LangFormItem>
        <FormItem label="Mô tả" name={"description"}>
          <TextArea readOnly={readOnly} rows={4} />
        </FormItem>
        {!readOnly && (
          <Flex justify={"center"}>
            <Button type="primary" htmlType="submit">
              {id ? "Cập nhật" : "Thêm mới"}
            </Button>
          </Flex>
        )}
      </Form>
    </>
  );
}
export default ManufacturerForm