import { compact, get,omit } from "lodash";
import { InstanceModuleRedux } from "~/redux/instanceModuleRedux";
import { initStateSlice } from "~/redux/models";
import { createSlice } from "@reduxjs/toolkit";
import { getPaging } from "~/utils/helpers";
import { ACTIONS, CORE_ACTION_LOWER } from "~/modules/policy/policy.auth";
interface cloneInitState extends initStateSlice {
  // Add cloneInitState Type Here
  convertFailed?: any,
  convertSuccess?: any,
  isGetMyEmployeeLoading?: any,
  getMyEmployeeFailed?: any,
  myEmployee?: any[]
 
  addProductFailed?: any,
  addProductSuccess?: any,
  removeProductFailed?: any,
  removeProductSuccess?: any,
  updateProductFailed?: any,
  updateProductSuccess?: any,
  updateAddressFailed?: any,
  updateAddressSuccess?: any,
  permissionConstructorEmployee ?: any,
  loadingGetPermissionConstructorEmployee? : boolean,
  getPermissionConstructorEmployeeFailed ?: any,

  permissionEmployee ?: any,
  permissionEmployeePre ?: any,
  loadingGetPermissionEmployee? : boolean,
  getPermissionEmployeeFailed ?: any,

  accessPermissionEmployeeSuccess?: any,
  accessPermissionEmployeeFailed?: any,

  denyPermissionEmployeeSuccess?: any,
  denyPermissionEmployeeFailed?: any,
 }
class EmployeeClassExtentd extends InstanceModuleRedux {
  clone;
  constructor() {
    super('employee');
    this.clone = {
      ...this.initReducer,
      getListSuccess: (state: initStateSlice, { payload }: { payload?: any }) => {
        state.isLoading = false;
        state.list = get(payload, 'docs', []);
        state.paging = getPaging(payload);
      },
      convertRequest: (state: cloneInitState) => {
        state.isSubmitLoading = true;
        state.convertFailed = null;
      },

      convertSuccess: (
        state: cloneInitState,
        { payload }: { payload: any }
      ) => {
        state.isSubmitLoading = false;
        state.convertSuccess = payload;
      },
      convertFailed: (state: cloneInitState, { payload }: { payload: any }) => {
        state.isSubmitLoading = false;
        state.convertFailed = payload;
      },

      getMyEmployeeRequest: (state: cloneInitState) => {
        state.isGetMyEmployeeLoading = true;
        state.getMyEmployeeFailed = null;
      },
      getMyEmployeeSuccess: (state: cloneInitState, { payload }: { payload?:any }) => {
        state.isGetMyEmployeeLoading = false;
        state.myEmployee = payload;
      },
      getMyEmployeeFailed: (state: cloneInitState, { payload }: { payload:any }) => {
        state.isGetMyEmployeeLoading = false;
        state.getMyEmployeeFailed = payload;
      },
    addProductRequest: (state: cloneInitState) => {
      state.isSubmitLoading = true;
      state.addProductFailed = null;
    },
    addProductSuccess: (state: cloneInitState, { payload }: { payload?:any }) => {
        state.byId = {
          ...state.byId,
          products : payload
        }
        state.isSubmitLoading = false;
        state.addProductSuccess = payload;
      },
      addProductFailed: (state: cloneInitState, { payload }: { payload: any }) => {
        state.isSubmitLoading = false;
        state.addProductFailed = payload;
      },

      removeProductRequest: (state: cloneInitState) => {
        state.isSubmitLoading = true;
        state.removeProductFailed = null;
      },
      removeProductSuccess: (
        state: cloneInitState,
        { payload }: { payload: any }
      ) => {
        state.isSubmitLoading = false;
        state.byId = {
          ...state.byId,
          products : payload
        }
        state.removeProductSuccess = payload;
      },
      removeProductFailed: (state: cloneInitState, { payload }: { payload: any }) => {
        state.isSubmitLoading = false;
        state.removeProductFailed = payload;
      },

      updateProductRequest: (state: cloneInitState) => {
        state.isSubmitLoading = true;
        state.updateProductFailed = null;
      },
      updateProductSuccess: (
        state: cloneInitState,
        { payload }: { payload: any }
      ) => {
        state.isSubmitLoading = false;
        state.byId = {
          ...state.byId,
          products : payload
        }
        state.updateProductSuccess = payload;
      },
      updateProductFailed: (state: cloneInitState, { payload }: { payload: any }) => {
        state.isSubmitLoading = false;
        state.updateProductFailed = payload;
      },

      // Permission
      getPermissionConstructorEmployeeRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionConstructorEmployee = true;
        state.getPermissionConstructorEmployeeFailed = null;
      },
      getPermissionConstructorEmployeeSuccess: (state:cloneInitState , { payload }: any) => {
        state.permissionConstructorEmployee = payload;
        state.loadingGetPermissionConstructorEmployee = false;
      },
      getPermissionConstructorEmployeeFailed: (state:cloneInitState , { payload }: any) => {
        state.getPermissionConstructorEmployeeFailed = payload;
        state.loadingGetPermissionConstructorEmployee = false;
      },
      getPermissionEmployeeRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionEmployee = true;
        state.getPermissionEmployeeFailed = null;
      },
      getPermissionEmployeeSuccess: (state:cloneInitState , { payload }: any) => {
        state.permissionEmployee = payload;
        state.loadingGetPermissionEmployee = false;
      },
      getPermissionEmployeeFailed: (state:cloneInitState , { payload }: any) => {
        state.getPermissionEmployeeFailed = payload;
        state.loadingGetPermissionEmployee = false;
      },
      accessPermissionEmployeeRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionEmployee = true;
        state.accessPermissionEmployeeFailed = null;
        const clonePermissionEmployee = {...state.permissionEmployee};
        // Action Change State Request
      const {action,resource} = payload;
      let newResource = [];
      if(action === CORE_ACTION_LOWER.admin){
        newResource = compact([...get(clonePermissionEmployee,resource,[]),...ACTIONS]);
      }else{
        newResource = [...get(clonePermissionEmployee,resource,[]),action];
      };
      state.permissionEmployee = {
        ...clonePermissionEmployee,
        [resource] : newResource
      }
      },
      accessPermissionEmployeeSuccess: (state:cloneInitState , { payload }: any) => {
          // Action Change Pre State === State
        state.permissionEmployeePre = {...state.permissionEmployee};
        state.loadingGetPermissionEmployee = false;
      },
      accessPermissionEmployeeFailed: (state:cloneInitState , { payload }: any) => {
        state.accessPermissionEmployeeFailed = payload;
        // Roll back After Failed
        state.permissionEmployee = {...state.permissionEmployeePre}
        state.loadingGetPermissionEmployee = false;
      },
      denyPermissionEmployeeRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionEmployee = true;
        state.denyPermissionEmployeeFailed = null;
        const clonePermissionEmployee = {...state.permissionEmployee};
        // Action Change State Request
      const {action : actionDeny,resource : resourceDeny} = payload;
      let newResourceDeny = [];
      if(actionDeny !== CORE_ACTION_LOWER.admin){
        newResourceDeny = get(clonePermissionEmployee,resourceDeny,[])?.filter((act : any) => act !== actionDeny)
      }
      state.permissionEmployee = {
        ...clonePermissionEmployee,
        [resourceDeny] : newResourceDeny
      }
      return;
      },
      denyPermissionEmployeeSuccess: (state:cloneInitState , { payload }: any) => {
        state.permissionEmployeePre = {...state.permissionEmployee};
        state.loadingGetPermissionEmployee = false;
      },
      denyPermissionEmployeeFailed: (state:cloneInitState , { payload }: any) => {
        state.denyPermissionEmployeeFailed = payload;
        state.permissionEmployee = {...state.permissionEmployeePre}
        state.loadingGetPermissionEmployee = false;
      },
    }
  }
  createSlice() {
    return createSlice({
      name: this.module,
      initialState: this.initialState,
      reducers:  this.clone,
    });
  }
  
}

const newSlice = new EmployeeClassExtentd();
const data = newSlice.createSlice();


export const employeeSliceAction   = data.actions;
export default data.reducer;