import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { InstanceModuleRedux } from "~/redux/instanceModuleRedux";
import { initStateSlice } from "~/redux/models";
import { SECTION_KEY_VI } from "../constants";
import { Section } from "../section.modal";
interface cloneInitState extends initStateSlice {
 // Add cloneInitState Type Here
 productSection?: any; 
 productSectionGetFailed?: any; 
 productSectionLoading?: any; 
 productSectionSubmitLoading?: any;
 productSectionCreateSuccess?: any;
 productSectionCreateFailed?: any;
 productSectionUpdateSuccess?: any;
 productSectionUpdateFailed?: any;
 productSectionDeleteSuccess?: any;
 productSectionDeleteFailed?: any;

}
class SectionClassExtend extends InstanceModuleRedux {
  cloneReducer;
  cloneInitState : cloneInitState;
  constructor() {
    super('section');
    this.cloneReducer = {
      ...this.initReducer,
      getListSuccess: (state:initStateSlice , { payload }: any) => {
        state.isLoading = false;
        state.list = payload?.map((item : Section) => ({...item,sectionName : get(SECTION_KEY_VI,item?.sectionKey,'')}));
      },
      updateSuccess: (state:initStateSlice, { payload }:{payload:any}) => {
        state.isSubmitLoading = false;
        const data = payload;
        state.byId = data;
        state.list = state.list?.map((item:any) => get(item,'_id') === get(data,'_id') ? data : item);
        state.listSearch = state.listSearch?.map((item:any) => get(item,'_id') === get(data,'_id') ? data : item);
        state.updateSuccess = data;
      },
      getProductSectionRequest: (state:cloneInitState , { payload }: any) => {
        state.productSectionLoading = true;
        state.productSection = [];
      },
      getProductSectionSuccess: (state:cloneInitState , { payload }: any) => {
        state.productSectionLoading = false;
        state.productSection = payload;
      },
      getProductSectionFailed: (state:cloneInitState , { payload }: any) => {
        state.productSectionLoading = false;
        state.productSectionGetFailed = payload;
      },
      createProductSectionRequest: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = true;
        state.productSectionCreateSuccess = null;
        state.productSectionCreateFailed = null;
      },
      createProductSectionSuccess: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = false;
        state.productSectionCreateSuccess = payload;
        state.productSection = [...state.productSection,payload];
      },
      createProductSectionFailed: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = false;
        state.productSectionCreateFailed = payload;
      },
      updateProductSectionRequest: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = true;
        state.productSectionUpdateSuccess = null;
        state.productSectionUpdateFailed = null;
      },
      updateProductSectionSuccess: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = false;
        state.productSectionUpdateSuccess = payload;
        state.productSection = [...state.productSection]?.map((item : any) => item?._id === payload?._id ? payload : item)
      },
      updateProductSectionFailed: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = false;
        state.productSectionUpdateFailed = payload;
      },
      deleteProductSectionRequest: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = true;
        state.productSectionDeleteSuccess = null;
        state.productSectionDeleteFailed = null;
      },
      deleteProductSectionSuccess: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = false;
        state.productSectionDeleteSuccess = payload;
        state.productSection = [...state.productSection]?.filter((item: any) => item?._id !== payload?._id);
      },
      deleteProductSectionFailed: (state:cloneInitState , { payload }: any) => {
        state.productSectionSubmitLoading = false;
        state.productSectionDeleteFailed = payload;
      },
      // Want Add more reducer Here...
    }
    this.cloneInitState = {
      ...this.initialState,
      productSection: [], 
      productSectionGetFailed: null, 
      productSectionLoading: false, 
      productSectionSubmitLoading: false,
      productSectionCreateSuccess: null,
      productSectionCreateFailed: null,
      productSectionUpdateSuccess: null,
      productSectionUpdateFailed: null,
      productSectionDeleteSuccess: null,
      productSectionDeleteFailed: null,
      // Want Add more State Here...
    }
  }
  createSlice() {
    return createSlice({
      name: this.module,
      initialState: this.cloneInitState,
      reducers:  this.cloneReducer,
    });
  }
  
}

const newSlice = new SectionClassExtend();
const data = newSlice.createSlice();


export const sectionActions = data.actions;
export default data.reducer;
