import { Button } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table/InternalTable';
import { debounce, get, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import TableAnt from '~/components/Antd/TableAnt';
import ImageProduct from '~/modules/sale/bill/components/ImageProduct';
import apis from '../section.api';
import { useCreateProductSection } from '../section.hook';
import { ProductInSection } from '../section.modal';
type propsType = {
    sectionId? :any,
    productSection? :ProductInSection[],
}
export default function SectionProductSearch({sectionId,productSection}:propsType) : React.JSX.Element {
    const [dataSearch,setDataSearch] = useState([]);
    const [loading,setLoading] = useState(false);
    const [isSubmitLoading,create] = useCreateProductSection();
    const onCreate = (productId : any) => {
        const payload = {
            productId,
            sectionId,
        };

        create(payload);
    }
    const columns : ColumnsType = [
        {
            title : "",
            dataIndex : 'images',
            key : 'images',
            width : 80,
            render : (images,rc) => <ImageProduct style={{height : 80}} images={images}/> 
        },
        {
            title : "Tên",
            dataIndex : 'name',
            key : 'name',
            render : (name,rc) => get(rc,'codeBySupplier') + " - " + name
        },
        {
            title : "Thao tác",
            dataIndex : '_id',
            key : '_id',
            width : 80,
            align : 'center',
            render : (_id,rc) => <Button loading={isSubmitLoading} onClick={() => onCreate(_id)} type='primary' size='small'>Chọn</Button>
        },

    ];

    const onSearch = async (keyword : any) => {
        try {
            setLoading(true);
            const payload = {
                keyword
            };
            const res = await apis.searchProductSection(payload);
            setDataSearch(res);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const debounceSearch = debounce(onSearch,300);
    
    useEffect(() => {
        onSearch('')
    },[]);
    
    // Convert Data Source Remove Duplicate
    const dataSource = useMemo(() => {
        // Convert to Object
        const dataSectionToObject : any = {};
        productSection?.forEach((item : any) => {
            dataSectionToObject[item?.productId?._id] = true
        });

        // Filter Has Key in Obj
        return dataSearch?.filter((item : any) => !dataSectionToObject[item?._id]);
    },[productSection,dataSearch]);

    return (
        <div style={{padding : 20}}>
            <Search style={{position : 'sticky',top : 0,zIndex : 1}} placeholder='Tìm sản phẩm...' onChange={(e) => debounceSearch(e.target.value)}/>
            <TableAnt pagination={false} columns={columns} dataSource={dataSource} loading={loading}/>
        </div>
    )
}