import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { InstanceModuleRedux } from "~/redux/instanceModuleRedux";
import { initStateSlice } from "~/redux/models";
import { mapLanguage } from "~/utils/helpers";
interface cloneInitState extends initStateSlice {
 // Add cloneInitState Type Here
}
class NewsClassExtend extends InstanceModuleRedux {
  cloneReducer;
  cloneInitState : cloneInitState;
  constructor() {
    super('news');
    this.cloneReducer = {
      ...this.initReducer,
      updateSuccess: (state:initStateSlice, { payload }:{payload:any}) => {
        state.isSubmitLoading = false;
        const data = get(payload,'data');
        state.byId = data;
        state.list = state.list?.map((item:any) => get(item,'_id') === get(data,'_id') ? mapLanguage(data) : {...item,highlight : false}); // Reset highlight
        state.listSearch = state.listSearch?.map((item:any) => get(item,'_id') === get(data,'_id') ? mapLanguage(data) : {...item,highlight : false}); // Reset highlight
        state.updateSuccess = data;
      },
      // Want Add more reducer Here...
    }
    this.cloneInitState = {
      ...this.initialState,
      // Want Add more State Here...
    }
  }
  createSlice() {
    return createSlice({
      name: this.module,
      initialState: this.cloneInitState,
      reducers:  this.cloneReducer,
    });
  }
  
}

const newSlice = new NewsClassExtend();
const data = newSlice.createSlice();


export const newsActions = data.actions;
export default data.reducer;
