
import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearQuerySearch, getExistProp } from "~/utils/helpers";
import {
    getSelectors,
    useFailed, useFetchByParam,
    useQueryParams,
    useSubmit,
    useSuccess
} from "~/utils/hook";
import { tenantActions } from "./redux/reducer";
const MODULE = "tenant";
const MODULE_VI = "đối tác phần mềm";
const getSelector = (key : any) => (state : any) => state[MODULE][key];

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector,
  listSearchSelector,
} = getSelectors(MODULE);

export const useTenantPaging = () => useSelector(pagingSelector);
export const useTenantSearch = () => useSelector(listSearchSelector);

// Tenant

const loadingGetResourceTenantSelector = getSelector('loadingGetResourceTenant');
const resourceTenantSelector = getSelector('resourceTenant');
const getResourceTenantFailedSelector = getSelector('getResourceTenantFailed');

const loadingGetResourceConstructorTenantSelector = getSelector('loadingGetResourceConstructorTenant');
const resourceConstructorTenantSelector = getSelector('resourceConstructorTenant');
const getResourceConstructorTenantFailedSelector = getSelector('getResourceConstructorTenantFailed');

const accessResourceTenantSuccessSelector = getSelector('accessResourceTenantSuccess');
const accessResourceTenantFailedSelector = getSelector('accessResourceTenantFailed');

const denyResourceTenantSuccessSelector = getSelector('denyResourceTenantSuccess');
const denyResourceTenantFailedSelector = getSelector('denyResourceTenantFailed');
// CustomerChannel

const loadingGetResourceCustomerChannelSelector = getSelector('loadingGetResourceCustomerChannel');
const resourceCustomerChannelSelector = getSelector('resourceCustomerChannel');
const getResourceCustomerChannelFailedSelector = getSelector('getResourceCustomerChannelFailed');

const loadingGetResourceConstructorCustomerChannelSelector = getSelector('loadingGetResourceConstructorCustomerChannel');
const resourceConstructorCustomerChannelSelector = getSelector('resourceConstructorCustomerChannel');
const getResourceConstructorCustomerChannelFailedSelector = getSelector('getResourceConstructorCustomerChannelFailed');

const accessResourceCustomerChannelSuccessSelector = getSelector('accessResourceCustomerChannelSuccess');
const accessResourceCustomerChannelFailedSelector = getSelector('accessResourceCustomerChannelFailed');

const denyResourceCustomerChannelSuccessSelector = getSelector('denyResourceCustomerChannelSuccess');
const denyResourceCustomerChannelFailedSelector = getSelector('denyResourceCustomerChannelFailed');

export const useGetTenants = (param:any) => {
  return useFetchByParam({
    action: tenantActions.getListRequest,
    actionUpdate: tenantActions.onSearch,
    loadingSelector: loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param
  });
};
export const useGetTenant = (id: any) => {
  return useFetchByParam({
    action: tenantActions.getByIdRequest,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id,
  });
};

export const useCreateTenant = (callback?: any) => {
  useSuccess(
    createSuccessSelector,
    `Tạo mới ${MODULE_VI} thành công`,
    callback
  );
  useFailed(createFailedSelector);

  return useSubmit({
    action: tenantActions.createRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useUpdateTenant = (callback?: any) => {
  useSuccess(
    updateSuccessSelector,
    `Cập nhật ${MODULE_VI} thành công`,
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    action: tenantActions.updateRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useDeleteTenant = (callback?: any) => {
  useSuccess(deleteSuccessSelector, `Xoá ${MODULE_VI} thành công`, callback);
  useFailed(deleteFailedSelector);

  return useSubmit({
    action: tenantActions.deleteRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useTenantQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get("limit") || 10;
  const page = query.get("page") || 1;
  const keyword = query.get("keyword");
  const createSuccess = useSelector(createSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, createSuccess, deleteSuccess, updateSuccess]);
};

export const useUpdateTenantParams = (
  query: any,
  listOptionSearch?: any[]
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [keyword, setKeyword] = useState(get(query, "keyword"));
  useEffect(() => {
    setKeyword(get(query, "keyword"));
  }, [query]);
  const onParamChange = (param: any) => {
    // Clear Search Query when change Params
    clearQuerySearch(listOptionSearch, query, param);

    if (!param.page) {
      query.page = 1;
    };

    // Convert Query and Params to Search Url Param
    const searchString = new URLSearchParams(
      getExistProp({
        ...query,
        ...param,
      })
    ).toString();

    // Navigate
    navigate(`${pathname}?${searchString}`);
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useGetResourceConstructorTenant = (params : any) => {
  return useFetchByParam({
    action: tenantActions.getResourceConstructorTenantRequest,
    loadingSelector: loadingGetResourceConstructorTenantSelector,
    dataSelector: resourceConstructorTenantSelector,
    failedSelector: getResourceConstructorTenantFailedSelector,
    param: params
  });
};

export const useGetResourceTenant = (params : any) => {
  return useFetchByParam({
    action: tenantActions.getResourceTenantRequest,
    loadingSelector: loadingGetResourceTenantSelector,
    dataSelector: resourceTenantSelector,
    failedSelector: getResourceTenantFailedSelector,
    param: params
  });
};

export const useAccessResourceTenant = (callback?:any) => {
  useSuccess(accessResourceTenantSuccessSelector, '', callback);
  useFailed(accessResourceTenantFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: tenantActions.accessResourceTenantRequest
  });
};

export const useDenyResourceTenant = (callback?:any) => {
  useSuccess(denyResourceTenantSuccessSelector, '', callback);
  useFailed(denyResourceTenantFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: tenantActions.denyResourceTenantRequest
  });
};


export const useGetResourceConstructorCustomerChannel = (params : any) => {
  return useFetchByParam({
    action: tenantActions.getResourceConstructorCustomerChannelRequest,
    loadingSelector: loadingGetResourceConstructorCustomerChannelSelector,
    dataSelector: resourceConstructorCustomerChannelSelector,
    failedSelector: getResourceConstructorCustomerChannelFailedSelector,
    param: params
  });
};

export const useGetResourceCustomerChannel = (params : any) => {
  return useFetchByParam({
    action: tenantActions.getResourceCustomerChannelRequest,
    loadingSelector: loadingGetResourceCustomerChannelSelector,
    dataSelector: resourceCustomerChannelSelector,
    failedSelector: getResourceCustomerChannelFailedSelector,
    param: params
  });
};

export const useAccessResourceCustomerChannel = (callback?:any) => {
  useSuccess(accessResourceCustomerChannelSuccessSelector, '', callback);
  useFailed(accessResourceCustomerChannelFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: tenantActions.accessResourceCustomerChannelRequest
  });
};

export const useDenyResourceCustomerChannel = (callback?:any) => {
  useSuccess(denyResourceCustomerChannelSuccessSelector, '', callback);
  useFailed(denyResourceCustomerChannelFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: tenantActions.denyResourceCustomerChannelRequest
  });
};