import { Button, Form, Input, Row, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddressFormSection from '~/components/common/AddressFormSection';
import BaseBorderBox from '~/components/common/BaseBorderBox';
import WithPermission from '~/components/common/WithPermission';
import POLICIES from '~/modules/policy/policy.auth';
import { useCreateBranch, useGetBranch, useUpdateBranch } from '../branch.hook';
type propsType = {
  id?: any,
  onCancel? : () => void
};
export default function BranchForm({ id , onCancel }: propsType): React.JSX.Element {
  const {id : tenantId} = useParams(); // Từ Chi tiết Tenant Qua sẽ có tenantId là Param
  
  const [isSubmitLoading,onCreate] = useCreateBranch(onCancel);
  const [,onUpdate] = useUpdateBranch(onCancel);
  const [form] = Form.useForm();
  const [branch, loading] = useGetBranch(id);
  const onFinish = (values: any) => {
    try {
      if (id) {
        onUpdate({
            _id : id,
            ...values
        })
      } else {
        onCreate({
          ...values,
          ...tenantId && {tenantId : Number(tenantId)}
        });
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if(branch && !!id){
        form.setFieldsValue({...branch});
    }
  },[branch])
  const renderLoading = (component: React.ReactNode) => {
    return loading ? <Skeleton.Image active /> : component
  };
  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      labelAlign='left'
    >
      <BaseBorderBox title={'Thông tin'}>
        <Form.Item
          name={'name'}
          label={'Tên chi nhánh'}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên chi nhánh',
            }
          ]}
        >
          {renderLoading(
            <Input placeholder="Tên chi nhánh"/>
          )}
        </Form.Item>
      </BaseBorderBox>
      <BaseBorderBox title={'Địa chỉ'} >
        <AddressFormSection form={form}/>
      </BaseBorderBox>
      <Row justify="end" className='mt-4'>
        <WithPermission permission={id ? POLICIES.UPDATE_BRANCH : POLICIES.WRITE_BRANCH}>
          <Button type="primary" htmlType="submit" loading={isSubmitLoading} style={{ marginRight: 8 }}>{ id ? 'Cập nhật' : 'Tạo mới'}</Button>
        </WithPermission>
      </Row>
    </Form>
  )
}