import { Typography } from 'antd';
import { get } from 'lodash';
import React from 'react';
import HtmlView from '~/components/common/HtmlView';
import { ObjOrString } from '~/utils/helpers';
import { useGetWpPolicy_onlyGet } from '../wpPolicy.hook';
type propsType = {

};
const Item = ({ label, value }: { label: string; value: any }) => (
    <div>
      <span>{label}: </span>
      <Typography.Text strong>{value}</Typography.Text>
    </div>
  );
export default function WpPolicyView(props:propsType) : React.JSX.Element {
    const [data] = useGetWpPolicy_onlyGet();
    return (
        <div>
            <Item label="Tên chính sách" value={ObjOrString(get(data,'title'))} />
            <Item label="Thứ tự hiển thị" value={ObjOrString(get(data,'index'))} />
            <Item label="Nội dung" value={<HtmlView html={ObjOrString(get(data,'content'))}/>} />
        </div>
    )
}