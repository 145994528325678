import { Button, Col, Divider, Form, Row } from "antd";
import { get, head } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FormFieldCreateBill, PayloadCreateBill } from "~/modules/sale/bill/bill.modal";
import QuotationModule from '~/modules/sale/quotation';
import { DataResultType } from "~/pages/Dashboard/Bill/CreateBill";
import useNotificationStore from "~/store/NotificationContext";
import { concatAddress } from "~/utils/helpers";
import { useChangeDocumentTitle } from "~/utils/hook";
import { defaultFee } from "../../constants";
import useCreateBillStore from "../../storeContext/CreateBillContext";
import ProductSelectedTable from "../ProductSelectedTable";
import SelectPharmacy from "../SelectPharmacy";
import TotalBill from "./TotalBill";
import InfoCreateBy from "./InfoCreateBy";
type propsType = {};
export default function SaleScreen(props: propsType): React.JSX.Element {
  const {
    form,
    onValueChange,
    quotationItems,
    bill_totalPrice,
    onRemoveTab,
    bill,
    onOpenModalResult,
    totalAmount,
    mutateReValidate,
    setAddress,
    setFormAndLocalStorage,
    setPharmacyInfo,
    totalDiscountCouponBill,
    totalDiscountCouponShip,
    totalCouponForItem,
    couponSelected,
    onVerifyCoupon,
    mutateChangeQuotationItem,
  } = useCreateBillStore();
 const feeForm = Form.useWatch('fee',form);
 
  const { onNotify } = useNotificationStore();
  const callBackAfterSuccess = (newData: DataResultType) => {
    onRemoveTab();
    onOpenModalResult(newData);
  };
  const [isSubmitLoading, onCreateQuotation] =
    QuotationModule.hook.useCreateQuotation(callBackAfterSuccess);
  const [, onUpdateQuotation] =
    QuotationModule.hook.useUpdateQuotation(callBackAfterSuccess);
  const [, onConvertQuotation] =
    QuotationModule.hook.useConvertQuotation(callBackAfterSuccess);
  const onFinish = async(values: FormFieldCreateBill) => {
    try {
      if (!quotationItems?.length) {
        return onNotify?.warning("Vui lòng chọn thuốc!");
      }

      if (bill_totalPrice < 0) {
        return onNotify?.warning("Số tiền không hợp lệ");
      }
      
      const isInValidCoupon : any = await onVerifyCoupon();
      if(isInValidCoupon){
        return onNotify?.warning("Có một số mã giảm giá không hợp lệ, Hệ thống đã tự động bỏ mã, Vui lòng kiểm tra và thử lại"); 
      }
      
      const submitData: PayloadCreateBill =
        QuotationModule.service.convertDataQuotation({
          quotationItems: quotationItems,
          data: values,
          bill_totalPrice,
          _id: get(bill, "dataUpdateQuotation.id"),
          totalAmount,
          dataTransportUnit: get(bill, 'dataTransportUnit'),
          warehouseId: get(bill, 'warehouseId'),
          ...(get(bill, 'noteBillSplit') &&{noteBillSplit: get(bill, 'noteBillSplit')}),
          coupons: couponSelected,
          totalCouponForBill: totalDiscountCouponBill,
          totalCouponForShip: totalDiscountCouponShip,
          totalCouponForItem,
        });
        
      switch (get(bill, "typeTab")) {
        case "createQuotation":
          onCreateQuotation(submitData);
          break;
        case "updateQuotation":
          onUpdateQuotation(submitData);
          break;
        case "convertQuotation":
          onConvertQuotation(submitData);
          break;

        default:
          break;
      }
    } catch (error: any) {
      onNotify?.error(error?.response?.data?.message || "Có lỗi gì đó xảy ra");
    }
  };
  const textSubmit = useMemo(() => {
    switch (get(bill, "typeTab")) {
      case "createQuotation":
        return "Tạo đơn hàng tạm (F1)";
      case "updateQuotation":
        return "Cập nhật đơn hàng (F1)";
      case "convertQuotation":
        return "Chuyển đổi đơn hàng (F1)";

      default:
        return "Tạo đơn hàng tạm (F1)";
        break;
    }
  }, [bill]);
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      // Check if the pressed key is F1
      if (event.key === "F1") {
        form.submit();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const titleText = useMemo(() => {
    switch (get(bill, "typeTab")) {
      case "createQuotation":
        return "Tạo đơn hàng tạm";
      case "updateQuotation":
        return "Cập nhật đơn hàng";
      case "convertQuotation":
        return "Chuyển đổi đơn hàng";

      default:
        return "Tạo đơn hàng tạm";
    }
  }, [bill]);
  useChangeDocumentTitle(titleText);
  return (
    <Form
      className="form-create-bill"
      form={form}
      onFinish={onFinish}
      onValuesChange={onValueChange}
      initialValues={{
        pair: 0,
        fee: defaultFee,
      }}
    >
      <Row gutter={8}>
        <Col span={16}>
          <ProductSelectedTable />
        </Col>
        <Col span={8} className="form-create-bill--payment">
          <div>
              <InfoCreateBy />
              <SelectPharmacy onChange={(value,option) => {
                const fee = get(option,'data.fee',[]);
                if(fee?.length){
                  feeForm[0] = head(fee);
                }else{
                  feeForm[0] = {
                    typeFee : 'SUB_FEE',
                    typeValue : 'VALUE',
                    value : 0
                  }
                }
                const deliveryAddress = concatAddress(get(option,'data.addressDelivery',get(option,'data.address','')));
                const address = get(option,'data.addressStories',[]);
                setFormAndLocalStorage({
                    fee : feeForm,
                    pharmacyId : value,
                    deliveryAddress,
                  });
  
                setAddress(address);
                mutateReValidate();
                mutateChangeQuotationItem();
                setPharmacyInfo(option);
                }}
                id={get(bill, 'pharmacyId')} form={form} allowClear={false} showButtonAdd={true}/>
            <Divider/>
            <TotalBill />
          </div>
          <div className="form-create-bill--payment__actions">
            <Row style={{height : '100%'}} gutter={8} justify={"center"} align="middle" wrap={false}>
              {/* <Col flex={1}>
                <Button
                block
                  className="form-create-bill--payment__actions__btnDebt"
                  onClick={onOpenDebt}
                >
                  Hình thức thanh toán
                </Button>
              </Col> */}
              <Col style={{height : '100%',display : 'flex' , alignItems : 'end'}} span={14}>
                <Button
                  block
                  disabled={!quotationItems?.length}
                  className="form-create-bill--payment__actions__btnPayment"
                  type="primary"
                  loading={isSubmitLoading}
                  onClick={() => form.submit()}
                >
                  {textSubmit}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
