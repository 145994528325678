import { BarsOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Flex, Modal } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import useNotificationStore from "~/store/NotificationContext";
import apis from "../clientSubscribe.api";
import {
  useClientSubscribePaging,
  useClientSubscribeQueryParams,
  useGetClientSubscribes,
  useUpdateClientSubscribeParams
} from "../clientSubscribe.hook";
import ClientSubCoupons from "../components/ClientSubCoupons";
type propsType = {};
export default function ClientSubscribe(props: propsType): React.JSX.Element {
  const [open,setOpen] = useState(false);
  const [id,setId] = useState<any>();
  const {onNotify} = useNotificationStore();
  const [query] = useClientSubscribeQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateClientSubscribeParams(query);
  const [dataSource, isLoading] = useGetClientSubscribes(query);
  const paging = useClientSubscribePaging();
  const onOpenCoupons = useCallback((i:any) => {
    setOpen(true);
    setId(i);
  },[]);
  const onCloseCoupons = useCallback(() => {
    setOpen(false);
    setId(null);
  },[]);
  const onSendMail = async(_id : any) => {
    try {
      await apis.sendMail(_id);
      onNotify?.success("Đã gửi mail");
    } catch (error : any) {
      onNotify?.success(error?.response?.data?.message || "Gửi mail thất bại vui lòng thử lại sau");
    }
  }
  const columns: ColumnsType = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "createdAt",
      key: "createdAt",
      width : 200,
      align : 'center',
      render : (createdAt) => dayjs(createdAt).format("DD/MM/YYYY HH:mm:ss")
    },
    {
      title: "Mã Giảm giá",
      dataIndex: "coupons",
      key: "coupons",
      align : 'center',
      render : (coupons,rc) => <Button icon={<BarsOutlined />} type="primary" ghost onClick={() => onOpenCoupons(rc?._id)}>
        Quản lý mã giảm giá
      </Button>
    },
    {
      title: "Gửi thông tin giảm giá",
      dataIndex: "_id",
      key: "_id",
      align : 'center',
      render : (_id,rc) => <Button icon={<MailOutlined />}  onClick={() => onSendMail(rc?._id)}>
      Gửi
      </Button>
    },
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách khách hàng nhận khuyến mãi"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <SearchAnt onParamChange={onParamChange} />
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        stickyTop
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showSizeChanger: true,
          showTotal: (total) => `Tổng cộng: ${total} `,
        }}
        loading={isLoading}
      />
      <Modal
      open={open}
      destroyOnClose
      onCancel={onCloseCoupons}
      centered
      footer={null}
      width={'auto'}
      >
        <ClientSubCoupons id={id} onCancel={onCloseCoupons}/>
      </Modal>
    </div>
  );
}
