import { PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Flex, Image, Popconfirm, Popover, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import WithPermission from "~/components/common/WithPermission";
import { STATUS, STATUS_NAMES } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import { ObjOrString } from "~/utils/helpers";
import SectionForm from "../components/SectionForm";
import { SECTION_KEY_IMAGE, SECTION_KEY_VI } from "../constants";
import {
  useDeleteSection,
  useGetSections, useSectionQueryParams, useUpdateSectionParams
} from "../section.hook";
type propsType = {};
export default function Section(props: propsType): React.JSX.Element {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [query] = useSectionQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateSectionParams(query);
  const [dataSource, isLoading] = useGetSections(query);
  const [isSubmitLoading, onDelete] = useDeleteSection();
  const [id, setId] = useState<any>();
  const onOpen = useCallback((i?: any) => {
    if (i) setId(i);
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const columns: ColumnsType = [
    {
      title: "Tên section",
      dataIndex: "title",
      key: "title",
      render : (title,rc) => <Typography.Link onClick={() => navigate(`/section-detail/${get(rc,'_id')}`)}>{ObjOrString(title)}</Typography.Link>
    },
    {
      title: "Mẫu template",
      dataIndex: "sectionKey",
      key: "sectionKey",
      width : 150,
      align : 'center',
      render : (sectionKey : any) => 
      <Popover
      content={
        <Image
          style={{ width: 400, height: 400, objectFit: "contain" }}
          src={get(SECTION_KEY_IMAGE, sectionKey,'')}
        />
      }
    >
      <div
        style={{
          padding: 4,
        }}
      >
        <Typography.Text strong>{get(SECTION_KEY_VI, sectionKey,'')}</Typography.Text>
      </div>
    </Popover>
      
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width : 150,
      align : 'center',
      render : (status,rc) => <Tag icon={<Badge style={{marginRight : 10}} status={status === STATUS.ACTIVE ? "success" : "default"}/>} color={status === STATUS.ACTIVE ? "success" : "default"}>{get(STATUS_NAMES,status,'')}</Tag>
    },
    {
      title: "Thao tác",
      key: "action",
      dataIndex: "_id",
      width: 170,
      align : 'center',
      render: (_id, rc) => (
        <Flex justify={'center'} align={"center"} gap={5}>
          <WithPermission permission={POLICIES.UPDATE_SECTIONPRODUCT}>
            <Typography.Link onClick={() => onOpen(_id)}>Sửa</Typography.Link>
          </WithPermission>|
          <WithPermission permission={POLICIES.DELETE_SECTIONPRODUCT}>
            <Popconfirm
                okButtonProps={{ loading: isSubmitLoading }}
                title="Xác nhận xoá"
                onConfirm={() => onDelete(_id)}
            >
                <Typography.Link type="danger">Xoá</Typography.Link>
            </Popconfirm>
          </WithPermission>
        </Flex>
      ),
    },
  
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách section"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <SearchAnt onParamChange={onParamChange} />
        <WithPermission permission={POLICIES.WRITE_SECTIONPRODUCT}>
        <Button onClick={() => onOpen()} type="primary" icon={<PlusOutlined />}>
          Thêm mới
        </Button>
        </WithPermission>
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        stickyTop
        loading={isLoading}
      />
      <ModalAnt
        title={id ? "Cập nhật " : "Tạo mới " + "section"}
        onCancel={onClose}
        open={open}
        destroyOnClose
        footer={null}
      >
        <SectionForm id={id} onCancel={onClose} />
      </ModalAnt>
    </div>
  );
}
