/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import CollaboratorModule from '~/modules/collaborator'
const CollaboratorPage = () => {
  return (
      <CollaboratorModule.page.index />
  )
}

export default CollaboratorPage
