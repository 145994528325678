import React from "react";
type propsType = {
  html: string;
};
export default function HtmlView({ html }: propsType): React.JSX.Element {
    
  return (
    <div
      className="ck ck-reset ck-editor ck-rounded-corners"
      role="application"
      dir="ltr"
      lang="en"
    >
      <div className="ck ck-editor__main" role="presentation">
        <div
          className="ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline"
          role="textbox"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
      </div>
    </div>
  );
}
