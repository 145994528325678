import React from "react";
import SaleScreenInDevice from "../components/createBillScreen/SaleScreenInDevice";

type propsType = {};
export default function CreateBillInDevice(props: propsType): React.JSX.Element {

  return (
    <SaleScreenInDevice />
  );
}
