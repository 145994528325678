import { Button, Flex, Form, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { requireRules, requireRulesCustom } from "~/constants/defaultValue";
import { useFailedAnt, useLanguage } from "~/utils/hook";
import { PolicyBase } from "../wpPolicy.modal";
import Editors from "~/utils/Editors";
import { useCreateWpPolicy, useGetWpPolicy, useUpdateWpPolicy } from "../wpPolicy.hook";
import Loading from "~/components/common/Loading/index";

type propsType = {
  id?: any;
  onCancel: () => void;
};
export default function WpPolicyForm({
  onCancel,
  id,
}: propsType): React.JSX.Element {
  const { ErrorComponent, onFinishFailed, setErrors } = useFailedAnt();
  const { LangFormItem, LanguageSwitchLang, language, setLanguage } =
    useLanguage();
    const [data,loading] = useGetWpPolicy(id);
  const [form] = Form.useForm();
  const [isSubmitLoading, onCreate] = useCreateWpPolicy(onCancel);
  const [, onUpdate] = useUpdateWpPolicy(onCancel);
  const onFinish = (values: PolicyBase) => {
    setErrors([]);
    if (id) {
      onUpdate({
        _id: id,
        ...values,
      });
    } else {
      onCreate({
        ...values,
      });
    }
  };
  useEffect(() => {
    if(data && id){
      form.setFieldsValue({
        ...data
      })
    }
  },[id,data])
  return (
    <Form
      onFinish={onFinish}
      form={form}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 4 }}
      labelAlign="left"
    >
      <Loading loading={loading}/>
      <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"} />
      <LanguageSwitchLang language={language} setLanguage={setLanguage} />
      <LangFormItem
        label="Tiêu đề"
        fieldName="title"
        language={language}
        rules={requireRulesCustom("Vui lòng nhập tiêu đề")}
      >
        <TextArea />
      </LangFormItem>
      <Form.Item name={"index"} rules={requireRules} label="Thứ tự hiển thị">
        <InputNumber step={1} min={0} />
      </Form.Item>
      <LangFormItem
        label="Nội dung"
        fieldName="content"
        language={language}
        rules={requireRulesCustom("Vui lòng nhập nội dung")}
      >
        <Editors />
      </LangFormItem>
      <Flex justify={"center"}>
        <Button loading={isSubmitLoading} type="primary" htmlType="submit">
          {id ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Flex>
    </Form>
  );
}
