import { Button, Col, Flex, Form, Input, Radio, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import UploadImage from "~/components/common/Upload/UploadImage";
import { requireRulesCustom } from "~/constants/defaultValue";
import { useFailedAnt, useLanguage } from "~/utils/hook";
import { NEWS_SECURITY, NEWS_SECURITY_VI } from "../constants";
import { useCreateNewsCategory, useGetNewsCategory } from "../newsCategory.hook";
type propsType = {
  id?: string;
  onCancel: () => void;
  onUpdate : (p?:any) => void;
};
export default function NewsCategoryForm({
  id,
  onCancel,
  onUpdate,
}: propsType): React.JSX.Element {
  const [form] = Form.useForm();
  const [data,loading] = useGetNewsCategory(id);
  const { ErrorComponent, onFinishFailed, setErrors } = useFailedAnt();
  const { LangFormItem, LanguageSwitchLang, language, setLanguage } =
    useLanguage();
    const [isSubmitLoading,onCreate] = useCreateNewsCategory(onCancel);
  const onFinish = (values: any) => {
    setErrors([]);
    if(id){
        onUpdate({_id : id,...values})
    }else{
        onCreate({...values})
    }
  };

  useEffect(() => {
    if(data && id){
        form.setFieldsValue({
            ...data
        })
    }
  
    
  }, [id,data])
  
  return (
    <Form
      initialValues={{
        security: NEWS_SECURITY.PUBLISHED,
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 4 }}
      labelAlign="left"
    >
      <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"} />
      <LanguageSwitchLang language={language} setLanguage={setLanguage} />
      <Row justify={'space-between'}>
        <Col span={20}>
          <LangFormItem
            labelCol={{span : 5}}
            label="Tên danh mục"
            fieldName="name"
            language={language}
            rules={requireRulesCustom("Vui lòng nhập tên danh mục")}
          >
            <Input />
          </LangFormItem>
          <LangFormItem
          labelCol={{span : 5}}
            label="Mô tả ngắn"
            fieldName="description"
            language={language}
          >
            <TextArea />
          </LangFormItem>
          <Form.Item labelCol={{span : 5}} name={"security"} label="Hiển thị">
            <Radio.Group>
              <Radio value={NEWS_SECURITY.PUBLISHED}>
                {NEWS_SECURITY_VI.PUBLISHED}
              </Radio>
              <Radio value={NEWS_SECURITY.PRIVATE}>
                {NEWS_SECURITY_VI.PRIVATE}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Flex justify={'end'}>
          <Form.Item name={"photo"}>
            <UploadImage
              onChange={(url: string) => form.setFieldsValue({ photo: url })}
              imgUrl={form.getFieldValue("photo")}
            />
          </Form.Item>
          </Flex>
        </Col>
      </Row>
      <LangFormItem
        label="Meta title"
        fieldName="metaTitle"
        language={language}
      >
        <Input />
      </LangFormItem>
      <LangFormItem
        label="Meta description"
        fieldName="metaDescription"
        language={language}
      >
        <TextArea />
      </LangFormItem>
      <LangFormItem
        label="Meta keywords"
        fieldName="metaKeywords"
        language={language}
      >
        <Input />
      </LangFormItem>
      <Flex justify={"center"}>
        <Button loading={isSubmitLoading} htmlType="submit" type="primary">
          {id ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Flex>
    </Form>
  );
}
