import { Radio, Row, Typography } from "antd";
import React from "react";
import { LANGUAGE } from "~/constants/defaultValue";
import { Language } from "~/typings/modals";
type propsType = {
  language : Language,
  setLanguage : (lang : Language) => void,
};
export default function LanguageSwitchLang(
  {language,setLanguage}: propsType
): React.JSX.Element {
  return (
    <Row justify="end" style={{ marginBlock: 20 }} align="middle">
      <Typography.Text style={{marginRight : 8}} strong>Ngôn ngữ</Typography.Text>
      <Radio.Group
        value={language}
        optionType="button"
        buttonStyle="solid"
        onChange={(e) => setLanguage(e.target.value)}
      >
        <Radio.Button  value={LANGUAGE.VI}><span className="fi fi-vn"/> VI</Radio.Button>
        <Radio.Button value={LANGUAGE.EN}><span className="fi fi-us"/> EN</Radio.Button>
      </Radio.Group>
    </Row>
  );
}
