import { Select } from 'antd';
import { SelectProps } from 'antd/lib/index';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { MAX_LIMIT } from '~/constants/defaultValue';
import { ObjOrString } from '~/utils/helpers';
import { useFetchState } from '~/utils/hook';
import apis from '../categoryParent.api';
interface Props extends SelectProps {
    readOnly ? : boolean;
}
export default function CategoryParentSelect({readOnly,...props}:Props) : React.JSX.Element {
    const [data,loading] = useFetchState({api : apis.getAllPublic,conditionRun : !readOnly,useDocs : false});
    const options = useMemo(() => data?.map((item : any) => ({
        label : ObjOrString(get(item,'name')),
        value : get(item,'_id'),
    })),[data])
    return (
        <Select loading={loading} options={options} {...props}/>
    )
}