import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import CategoryParentForm from "./CategoryParentForm";

export default function MainContentTab() {
  const { id }: any = useParams();

  return (
    <Tabs
      className={"layoutDetail--right__mainContent__tab"}
      destroyInactiveTabPane
      items={[
        {
          key: "1",
          label: "Hồ sơ",
          children: <CategoryParentForm id={id} readOnly />,
        },
      ]}
    ></Tabs>
  );
}
