import React from "react";
import SectionProductControl from "./SectionProductControl";
type propsType = {
  sectionId?: any;
};
export default function SectionProduct({
  sectionId,
}: propsType): React.JSX.Element {
 
  return (
    <div>
      <SectionProductControl
        sectionId={sectionId}
      />
     
    </div>
  );
}
