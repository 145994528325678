import { Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table/InternalTable";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import TableAnt from "~/components/Antd/TableAnt";
import DateTimeTable from "~/components/common/DateTimeTable";
import Status from "~/components/common/Status/index";
import {
    STATUS_QUOTATION,
    STATUS_QUOTATION_VI
} from "~/modules/sale/quotation/constants";
import {
    useGetQuotations,
    useQuotationPaging,
    useQuotationQueryParamsState
} from "~/modules/sale/quotation/quotation.hook";
import { PATH_APP } from "~/routes/allPath";
type propsType = {};
export default function QuotationTab(props: propsType): React.JSX.Element {
  const { id } = useParams();
  const defaultQuery = useMemo(() => ({ visitorIds: id }), [id]);
  const [query, onParamChange] = useQuotationQueryParamsState(defaultQuery);
  const [data, loading] = useGetQuotations(query);
  const paging = useQuotationPaging();
  const columns: ColumnsType = [
    {
      title: "Mã đơn hàng tạm",
      dataIndex: "codeSequence",
      key: "codeSequence",
      align: "center",
      width: 200,
      render(code) {
        return (
          <Link
            className="link_"
            target='_blank'
            to={PATH_APP.quotation.root + "?keyword=" + code}
          >
            {code}
          </Link>
        );
      },
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "bill",
      key: "bill",
      align: "center",
      width: 150,
      render(bill, record, index) {
        return (
          <Link
            className="link_"
            to={PATH_APP.bill.root + "?keyword=" + get(record, "bill.codeSequence")}
            target="_blank"
          >
            {get(record, "bill.codeSequence")}
          </Link>
        );
      },
    },
    {
      title: "Ngày tạo đơn",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 130,
      align: "center",
      render(createdAt, record, index) {
        return (
          <div>
            <DateTimeTable data={createdAt} />
            <p>-</p>
            Bởi:{" "}
            <Typography.Text strong>
              {get(record, "createBy.fullName")}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: "Ngày chuyển đổi",
      dataIndex: "historyStatus",
      key: "historyStatus",
      width: 150,
      align: "center",
      render(historyStatus, record, index) {
        return (
          historyStatus?.[STATUS_QUOTATION.CONFIRMED] && (
            <div>
              <Typography.Text strong>
                {dayjs(historyStatus?.[STATUS_QUOTATION.CONFIRMED]).format(
                  "DD/MM/YYYY HH:mm"
                )}
              </Typography.Text>
              <p>-</p>
              Bởi:{" "}
              <Typography.Text strong>
                {get(record, "confirmBy.fullName")}
              </Typography.Text>
            </div>
          )
        );
      },
    },
    {
      title: "Tên khách hàng",
      dataIndex: "pharmacy",
      key: "pharmacy",
      width: 120,
      align: "center",
      // width: "30%",
      render(pharmacy, record, index) {
        const refCollection = get(record, "refCollection", "pharma_profile");
        return (
          <>
            <Tooltip
              className="mx-1"
              title={
                refCollection === "partner"
                  ? "Khách hàng B2C"
                  : "Khách hàng B2B"
              }
            >
              {refCollection === "partner" ? (
                <i className="fa-solid fa-user-tie"></i>
              ) : (
                <i className="fa-solid fa-house-medical"></i>
              )}
            </Tooltip>
            <Typography.Text>{get(pharmacy, "name", "")}</Typography.Text>
          </>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 250,
      align: "center",
      render(status, record, index) {
        return (
          <Status status={status} statusVi={get(STATUS_QUOTATION_VI, status)} />
        );
      },
    },
    {
      title: "Ghi chú",
      key: "note",
      width: 200,
      align: "center",
      render(note, record, index) {
        return note ?? record?.noteBillSplit;
      },
    },
  ];
  return (
    <div>
      <TableAnt
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1500 }}
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showTotal: (total) => `Tổng cộng: ${total}`,
        }}
      />
    </div>
  );
}
