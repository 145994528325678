
  export const STATUS_ORDER_ITEM_VI = {
    ORDERING: "Đang đặt hàng", 
    ORDERED: "Đã đặt hàng", 
    RECEIVED: "Đã lấy hàng",
    PROCESSING: "Đang xử lý",
    PACKAGED: "Đã đóng gói",
    SHIPPING: "Đang giao",
    COMPLETED: "Đã hoàn thành",
    CANCELLED: "Đã huỷ",
  };
  