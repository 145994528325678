import { Badge, Flex, Input, Typography } from "antd";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import Header from "~/components/common/Layout/List/Detail/Header";
import Layout from "~/components/common/Layout/List/Detail/Layout";
import ListInDetailCommon from "~/components/common/Layout/List/Detail/ListInDetailCommon";
import { STATUS_COLOR, STATUS_NAMES } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import { PATH_APP } from "~/routes/allPath";
import {
  useNewsCategoryPaging, useNewsCategoryQueryParams, useDeleteNewsCategory,
  useGetNewsCategory, useGetNewsCategorys, useGetNewsCategory_onlyGet, useUpdateNewsCategory,
  useUpdateNewsCategoryParams
} from "../newsCategory.hook";
import NewsCategoryForm from "../components/NewsCategoryForm";
import MainContentTab from "../components/MainContentTab";
import { ObjOrString } from "~/utils/helpers";
import { NEWS_SECURITY_VI } from "../constants";
export default function NewsCategoryDetail(): React.JSX.Element {
  const { id: newsCategoryId }: any = useParams();
  useGetNewsCategory(newsCategoryId);
  const [id, setId] = useState<any>();
  const [query] = useNewsCategoryQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateNewsCategoryParams(query);
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const onSearch = () => {
        onParamChange({keyword});
        onClose();
    }
  const [newsCategory]: any = useGetNewsCategory_onlyGet();
  
  const [isOpenForm, setIsOpenForm] = useState(false);
  const onCloseForm = useCallback(() => {
    setIsOpenForm(false);
    setId(null);
  }, []);
  const onOpenForm = useCallback((idd?: any) => {
    setIsOpenForm(true);
    idd && setId(idd);
  }, []);
  const [, updateNewsCategory] = useUpdateNewsCategory(onCloseForm);
  const [, deleteNewsCategory] = useDeleteNewsCategory();

  return (
    <>
      <Layout
        HeaderLeft={
          <Header.HeaderLeft
            PERMISSION_WRITE={POLICIES.WRITE_NEWS}
            onChangeStatus={(status) => onParamChange({ status })}
            onAdd={() => onOpenForm()}
            SearchProp={{
                openSearch : showDrawer,
                open,
                onClose,
                onSearch,
                querySearch : ['keyword'],
                SearchComponent : <Input
                placeholder="Nhập để tìm kiếm"
                allowClear
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            }}
          />
        }
        HeaderRight={
          <Header.HeaderRight
            PERMISSION_UPDATE={POLICIES.UPDATE_NEWS}
            PERMISSION_DELETE={POLICIES.DELETE_NEWS}
            path={PATH_APP.newsCategory.root}
            onDeleteClick={() => deleteNewsCategory(newsCategoryId)}
            onEditClick={() => onOpenForm(newsCategoryId)}
            name={
              <Flex gap={10} align="center">
                <h4>
                  {ObjOrString(get(newsCategory, "name", ""))}
                </h4>
                <Typography.Text type="secondary"  style={{ fontSize: 14 , width : 100 }}>
                  {get(NEWS_SECURITY_VI,get(newsCategory, "security", ""),'')}
                </Typography.Text>
              </Flex>
            }
          />
        }
        MainContent={<MainContentTab />}
        List={
          <ListInDetailCommon
            fieldName="name"
            path={"/newsCategory-detail"}
            useGets={useGetNewsCategorys}
            usePaging={useNewsCategoryPaging}
            query={query}
            onParamChange={onParamChange}
            moduleName="newsCategory"
            notPaging
          />
        }
      />
      <ModalAnt
        width={1000}
        open={isOpenForm}
        onCancel={onCloseForm}
        footer={[]}
        destroyOnClose
      >
        <NewsCategoryForm
          onCancel={onCloseForm}
          id={id}
          onUpdate={updateNewsCategory}
        />
      </ModalAnt>
    </>
  );
}
