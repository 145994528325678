import { Select } from 'antd';
import { SelectProps } from 'antd/lib/index';
import React, { useMemo } from 'react';
import { MAX_LIMIT } from '~/constants/defaultValue';
import { useFetchState } from '~/utils/hook';
import apis from '../saleChannel.api';
export default function SaleChannelSelect(props:SelectProps) : React.JSX.Element {
    const query = useMemo(() => ({limit : MAX_LIMIT}),[])
    const [data,loading] = useFetchState({api : apis.getAll,query});
    const options = useMemo(() => data?.map((item : any) => ({
        label : item?.title,
        value : item?._id
    })),[data])
    return (
        <Select options={options} loading={loading} {...props}/>
    )
}