import { Checkbox, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import TableAnt from '~/components/Antd/TableAnt';
import POLICIES, { CORE_ACTION_LOWER } from '~/modules/policy/policy.auth';
import { filterSlug } from '~/utils/helpers';
import {
  useGetBranch,
  usePermissionColumns,
  useAccessPermissionBranch,
  useDenyPermissionBranch,
  useGetPermissionBranch,
  useGetPermissionConstructorBranch,

} from "../branch.hook";
import {  
    useAccessPermissionEmployee,
    useDenyPermissionEmployee,
    useGetPermissionEmployee,
    useGetPermissionConstructorEmployee,
} from '~/modules/employee/employee.hook';
import { useParams } from 'react-router-dom';
import { useMatchPolicy } from '~/modules/policy/policy.hook';

type Props = {
    branchId ? : string,
    type : "Branch" | "Employee"
};

const hook = {
    useGetPermissionConstructor: {
      Branch: useGetPermissionConstructorBranch,
      Employee: useGetPermissionConstructorEmployee,
    },
    useGetPermission: {
      Branch: useGetPermissionBranch,
      Employee: useGetPermissionEmployee,
    },
    useAccessPermission: {
      Branch: useAccessPermissionBranch,
      Employee: useAccessPermissionEmployee,
    },
    useDenyPermission: {
      Branch: useDenyPermissionBranch,
      Employee: useDenyPermissionEmployee,
    },
  };

  export const POLICY = {
    Branch : POLICIES.UPDATE_RESOURCEBRANCH,
    Employee : POLICIES.UPDATE_RESOURCEEMPLOYEE,
  }
export default function PermissionConstructor({ branchId,type } : Props) {
    const canUpdate = useMatchPolicy(POLICY[type])
    const {id : tenantId} = useParams();
    const [branch,loading] = useGetBranch(branchId);
    const [keyword, setKeyword] = useState<any>();
    const query = useMemo(() => {
        switch (type) {
            case "Branch":
                if (branch) {
                    return {
                      tenantId: branch?.tenantId,
                      branchId: branch?._id,
                    };
                  }
                break;
            case "Employee":
                return {
                    tenantId,
                  };
            default:
                break;
        }
     
    }, [branch]);
    const queryAccess = useMemo(() => {
        switch (type) {
            case "Branch":
                if (branch) {
                    return {
                      tenantId: branch?.tenantId,
                      branchId: branch?._id,
                    };
                  }
                break;
            case "Employee":
                return {
                    tenantId,
                  };
            default:
                break;
        }
      
    }, [branch]);

    const [permission, loadingPermission] = hook.useGetPermissionConstructor[type](query);
    const [permissionSearch,setPermissionSearch] = useState();
    const dataSource = useMemo(() => keyword ? permissionSearch : permission,[permission,permissionSearch,keyword]);
    const [permissionAccessed, loadingPermissionAccessed] = hook.useGetPermission[type](queryAccess);
    const [, access] = hook.useAccessPermission[type]();
    const [, deny] = hook.useDenyPermission[type]();
    const renderPermission = (action : keyof typeof CORE_ACTION_LOWER, { key: resource } : any) => {
        const isActive = get(permissionAccessed, [resource], [])?.includes(action);
        const disabled = get(permissionAccessed, [resource], [])?.includes(CORE_ACTION_LOWER.admin) && action !== CORE_ACTION_LOWER.admin;
        const onChange = (e : any) => {
            const { checked } = e.target;
            let submitData = {};
            switch (type) {
                case 'Employee':
                    submitData = {
                        resource,
                        action,
                        tenantId,
                    } 
                    break;
                case 'Branch':
                    submitData = {
                        branchId : branch?._id,
                        resource,
                        action,
                        tenantId : branch?.tenantId,
                    } 
                    break;
            
                default:
                    break;
            }
            
            if (checked) {
                access(submitData)
            } else {
                deny(submitData)
            }
        }
        return (
            <Tooltip title={!canUpdate && "Bạn không có quyền cập nhật"}>
                <Checkbox
                checked={isActive}
                onChange={onChange}
                disabled={!canUpdate || disabled}
            />
            </Tooltip>
        );
    };
    const columns : any = usePermissionColumns(renderPermission);

    const onSearch = (value : string) => setPermissionSearch(permission?.filter((item : any) => filterSlug(value,item?.name)));
    return (
        <div>
            <Search
                style={{ width: 300 }}
                value={keyword}
                onChange={(e) => {
                    setKeyword(e.target.value);
                    onSearch(e.target.value)
                }}
                enterButton
                placeholder='Tìm chức năng'
                allowClear
            />
            <TableAnt
                size='small'
                columns={columns}
                dataSource={dataSource}
                loading={loadingPermission || loadingPermissionAccessed || loading}
                rowKey={rc => rc._id}
            />
        </div>
    )
}
