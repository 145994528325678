import { get, omit } from "lodash";
import requester from "~/api/requester";

const apis = {
    getAll: (query?: any) => requester.get(`/api/v1/tenant`, query),
    getById: (id?: any) => requester.get(`/api/v1/tenant/${id}`),
    create: (data?: any) => requester.post(`/api/v1/tenant-create`, data),
    update: (data?: any) => requester.put(`/api/v1/tenant-update/${get(data,'_id')}`, omit(data,'_id')),
    delete: (id?: any) => requester.delete(`/api/v1/tenant/${id}`),
    getResourceConstructorTenant: (tenantId : any) => requester.get(`/api/v1/resource-tenant-constractor`,{tenantId}),
    getResourceTenant: (tenantId : any) => requester.get(`/api/v1/resource-tenant`,{tenantId}),
    accessResourceTenant: (payload : any) => requester.put(`/api/v1/resource-tenant`,payload),
    denyResourceTenant: (payload : any) => requester.delete(`/api/v1/resource-tenant`,payload),
    getResourceConstructorCustomerChannel: (payload : any) => requester.get(`/api/v1/resource-customer-channel-constractor`,payload),
    getResourceCustomerChannel: (payload : any) => requester.get(`/api/v1/resource-customer-channel`,payload),
    accessResourceCustomerChannel: (payload : any) => requester.put(`/api/v1/resource-customer-channel`,payload),
    denyResourceCustomerChannel: (payload : any) => requester.delete(`/api/v1/resource-customer-channel`,payload),
}
export default apis;
