import { Avatar, Col, Flex, Image, Row } from "antd";
import { get } from "lodash";
import React from "react";
import HtmlView from "~/components/common/HtmlView";
import ViewItem from "~/components/common/ViewItem";
import { NEWS_SECURITY_VI } from "~/modules/newsCategory/constants";
import { ObjOrString } from "~/utils/helpers";
import { useGetNews_onlyGet } from "../news.hook";
import imageDefault from '~/assets/images/image.jpeg';

type propsType = {};

export default function NewsView(props: propsType): React.JSX.Element {
  const [data] = useGetNews_onlyGet();
  
  return (
    <Row justify={"space-between"}>
      <Col span={18}>
        <Flex vertical gap={10}>
          <ViewItem label="Trạng thái" value={get(NEWS_SECURITY_VI,get(data, "status"),'')} />
          <ViewItem label="Nổi bật" value={get(data, "isFeatured") ? "Nổi bật" : "Không nổi bật"} />
          <ViewItem label="Tiêu đề" value={ObjOrString(get(data, "title"))} copyable/>
          <ViewItem label="Đường dẫn" value={ObjOrString(get(data, "slug"))} copyable/>
          <ViewItem label="Mô tả ngắn" value={ObjOrString(get(data, "description"))} />
          <ViewItem label="Meta title" value={ObjOrString(get(data, "metaTitle"))} />
          <ViewItem
            label="Meta description"
            value={ObjOrString(get(data, "metaDescription"))}
          />
          <ViewItem label="Meta keywords" value={ObjOrString(get(data, "metaKeywords"))} />
          <ViewItem label="Nội dung" children={<HtmlView html={ObjOrString(get(data,'content',''))}/>} />
        </Flex>
      </Col>
      <Col span={6}>
        <Image
          src={get(data, "coverPhoto",imageDefault)}
          style={{ width: "100%", height: 200, aspectRatio: 1 }}
        />
      </Col>
    </Row>
  );
}
