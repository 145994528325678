import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm, Typography } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/lib/table/InternalTable";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import { filterSlug, ObjOrString } from "~/utils/helpers";
import WpPolicyForm from "../components/WpPolicyForm";
import {
  useDeleteWpPolicy,
  useGetWpPolicys, useWpPolicyQueryParams
} from "../wpPolicy.hook";
type propsType = {};
export default function WpPolicy(props: propsType): React.JSX.Element {
  const [open, setOpen] = useState(false);
  const [keyword,setKeyword] = useState<string>('');
  const [dataSearch,setDataSearch] = useState();
  
  const [query] = useWpPolicyQueryParams();
  const [dataSource, isLoading] = useGetWpPolicys(query);
  const [isSubmitLoading, onDelete] = useDeleteWpPolicy();
  const [id, setId] = useState<any>();
  const onOpen = useCallback((i?: any) => {
    if (i) setId(i);
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const onSearch = () => {
    const filterData = dataSource?.filter((item : any) => filterSlug(keyword,item?.title));
    setDataSearch(filterData);
  }
  const columns: ColumnsType = [
    {
      title: "Tên chính sách",
      dataIndex: "title",
      key: "title",
      render: (title, rc) => (
        <Link className="link_" to={`/policy-detail/${rc?._id}`}>
          {ObjOrString(title)}
        </Link>
      ),
    },
    {
      title: "Thứ tự hiển thị",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "createdAt",
      render : (createdAt) => dayjs(createdAt).format("DD-MM-YYYY HH:mm:ss")
    },
    {
      title: "Thao tác",
      key: "action",
      dataIndex: "_id",
      width: 170,
      align: "center",
      fixed: "right",
      render: (_id, rc) => (
        <Flex justify={"center"} align={"center"} gap={5}>
          <WithPermission permission={POLICIES.UPDATE_POLICY}>
            <Typography.Link onClick={() => onOpen(_id)}>Sửa</Typography.Link>
          </WithPermission>
          |
          <WithPermission permission={POLICIES.DELETE_POLICY}>
            <Popconfirm
              okButtonProps={{ loading: isSubmitLoading }}
              title="Xác nhận xoá"
              onConfirm={() => onDelete(_id)}
            >
              <Typography.Link type="danger">Xoá</Typography.Link>
            </Popconfirm>
          </WithPermission>
        </Flex>
      ),
    },
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách chính sách"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <Search style={{width : 300}} placeholder="Tìm chính sách" value={keyword} onChange={(e) => {
          setKeyword(e.target.value);
          onSearch();
        }}/>
        <WithPermission permission={POLICIES.WRITE_POLICY}>
          <Button
            onClick={() => onOpen()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Thêm mới
          </Button>
        </WithPermission>
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={keyword ? dataSearch :dataSource}
        stickyTop
        loading={isLoading}
      />
      <ModalAnt
        title={id ? "Cập nhật chính sách" : "Tạo mới chính sách"}
        width={800}
        onCancel={onClose}
        open={open}
        destroyOnClose
        footer={null}
      >
        <WpPolicyForm
          id={id}
          onCancel={onClose}
        />
      </ModalAnt>
    </div>
  );
}
