import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Flex, Popconfirm, Typography } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import { ObjOrString } from "~/utils/helpers";
import NewsCategoryForm from "../components/NewsCategoryForm";
import { NEWS_SECURITY } from "../constants";
import {
  useDeleteNewsCategory,
  useGetNewsCategorys,
  useNewsCategoryPaging,
  useNewsCategoryQueryParams,
  useUpdateNewsCategory,
  useUpdateNewsCategoryParams,
} from "../newsCategory.hook";
type propsType = {};
export default function NewsCategory(props: propsType): React.JSX.Element {
  const [open, setOpen] = useState(false);
  const [query] = useNewsCategoryQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateNewsCategoryParams(query);
  const [dataSource, isLoading] = useGetNewsCategorys(query);
  const paging = useNewsCategoryPaging();
  const [isSubmitLoading, onDelete] = useDeleteNewsCategory();
  const [id, setId] = useState<any>();
  const onOpen = useCallback((i?: any) => {
      if (i) setId(i);
      setOpen(true);
    }, []);
    const onClose = useCallback(() => {
        setOpen(false);
        setId(null);
    }, []);
    const [, onUpdate] = useUpdateNewsCategory(onClose);
  const columns: ColumnsType = [
    {
      title: "Tên danh mục",
      dataIndex: "name",
      key: "name",
      render : (name,rc) => <Link className="link_" to={`/newsCategory-detail/${rc?._id}`}>{ObjOrString(name)}</Link>
    },
    {
      title: "đường dẫn",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "createdAt",
      align : 'center',
      render : (value) => dayjs(value).format("DD-MM-YYYY")
    },
    {
      title: "Hiển thị",
      dataIndex: "security",
      key: "security",
      align : 'center',
      render : (security,rc) => <Checkbox checked={security === NEWS_SECURITY.PUBLISHED} onChange={() => onUpdate({_id : rc?._id,security : security === NEWS_SECURITY.PRIVATE ? NEWS_SECURITY.PUBLISHED : NEWS_SECURITY.PRIVATE})}/>
    },
    {
        title: "Thao tác",
        key: "action",
        dataIndex: "_id",
        width: 170,
        align: "center",
        fixed: "right",
        render: (_id, rc) => (
          <Flex justify={"center"} align={"center"} gap={5}>
            <WithPermission permission={POLICIES.UPDATE_NEWS}>
              <Typography.Link onClick={() => onOpen(_id)}>Sửa</Typography.Link>
            </WithPermission>
            |
            <WithPermission permission={POLICIES.DELETE_NEWS}>
              <Popconfirm
                okButtonProps={{ loading: isSubmitLoading }}
                title="Xác nhận xoá"
                onConfirm={() => onDelete(_id)}
              >
                <Typography.Link type="danger">Xoá</Typography.Link>
              </Popconfirm>
            </WithPermission>
          </Flex>
        ),
      },
  
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách danh mục tin tức"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <SearchAnt onParamChange={onParamChange} />
        <WithPermission permission={POLICIES.WRITE_NEWS}>
        <Button onClick={() => onOpen()} type="primary" icon={<PlusOutlined />}>
          Thêm mới
        </Button>
        </WithPermission>
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        stickyTop
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showSizeChanger: true,
          showTotal: (total) => `Tổng cộng: ${total} `,
        }}
        loading={isLoading}
      />
      <ModalAnt
        title={id ? "Cập nhật danh mục tin tức" : "Tạo mới danh mục tin tức"}
        width={1000}
        onCancel={onClose}
        open={open}
        destroyOnClose
        footer={null}
      >
        <NewsCategoryForm onUpdate={onUpdate} id={id} onCancel={onClose} />
      </ModalAnt>
    </div>
  );
}
