import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import Breadcrumb from "~/components/common/Breadcrumb";
import WhiteBox from "~/components/common/WhiteBox";
import WithPermissionWithNotification from "~/components/common/WithPermissionWithNotification";
import POLICIES from "~/modules/policy/policy.auth";
import BranchInfo from "../components/BranchInfo";
import PermissionConstructor from "../components/PermissionConstructor";

export default function BranchDetail() {
  const [currentTab, setCurrentTab] = useState("info");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  
  const onChange = (key: any) => {
    setCurrentTab(key);
    navigate(`/branch-detail/${id}/${key}`);
  };

  useEffect(() => {
    switch (true) {
        case pathname.includes('info'):
            setCurrentTab('info')
            break;
        case pathname.includes('permissionConstructor'):
            setCurrentTab('permissionConstructor')
            break;
        
    
        default:
            break;
    }
  },[pathname]);

  return (
    <WhiteBox>
        <Breadcrumb
          title="Thông tin chi nhánh"
        />
      <Tabs
        activeKey={currentTab}
        onChange={(key) => onChange(key)}
        defaultActiveKey={pathname}
      >
        <TabPane tab="Thông tin" key="info">
          <BranchInfo id={id}/>
        </TabPane>
        <TabPane tab="Chức năng" key="permissionConstructor">
        <WithPermissionWithNotification permission={POLICIES.READ_RESOURCEBRANCH}>
        <PermissionConstructor branchId={id} type="Branch"/>
        </WithPermissionWithNotification>
        </TabPane>
      </Tabs>
    </WhiteBox>
  );
}
