import { Avatar, Image, Popover, Typography } from "antd";
import { get } from "lodash";
import highlight from "~/assets/images/section/template_highlight.png";
import normal from "~/assets/images/section/template_normal.png";
import deal from "~/assets/images/section/template_deal.png";
import banner from "~/assets/images/section/template_banner.png";

export const SECTION_KEY = {
  SECTION_HIGHLIGHT: "SECTION_HIGHLIGHT",
  SECTION_NOMAL: "SECTION_NOMAL",
  SECTION_DEAL: "SECTION_DEAL",
  SECTION_BANNER: "SECTION_BANNER",
} as const;

export const SECTION_KEY_VI = {
  SECTION_HIGHLIGHT: "Nổi bật",
  SECTION_NOMAL: "Thường",
  SECTION_DEAL: "Săn deal",
  SECTION_BANNER: "Với banner",
} as const;

export const SECTION_KEY_IMAGE = {
  SECTION_HIGHLIGHT: highlight,
  SECTION_NOMAL: normal,
  SECTION_DEAL: deal,
  SECTION_BANNER: banner,
} as const;

export const SECTION_KEY_OPTIONS = Object.keys(SECTION_KEY_VI).map((i) => ({
  label: get(SECTION_KEY_VI, i, ""),
  value: i,
}));

const CustomLabel = ({ src, label }: any) => (
  <Popover
    content={
      <Image
        style={{ width: 400, height: 400, objectFit: "contain" }}
        src={src}
      />
    }
  >
    <div
      style={{
        padding: 4,
      }}
    >
      <Typography.Text strong>{label}</Typography.Text>
    </div>
  </Popover>
);

export const SECTION_KEY_OPTIONS_FOR_SEGMENTED = Object.keys(
  SECTION_KEY_VI
).map((i) => ({
  label: CustomLabel({
    label: get(SECTION_KEY_VI, i, ""),
    src: get(SECTION_KEY_IMAGE, i, ""),
  }),
  value: i,
}));
