import React from 'react';
import MainContentTabCommon from '~/components/common/Layout/List/Detail/MainContentTabCommon';
import WpPolicyView from './WpPolicyView';
type propsType = {

}
export default function MainContentTab(props:propsType) : React.JSX.Element {
    return (
        <MainContentTabCommon items={[
            {
                key : '1',
                label : 'Thông tin',
                children : <WpPolicyView />
            },
        ]}/>
    )
}