import { Button, Form, Input, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import LangFormItem from "~/components/common/LangFormItem";
import LanguageSwitchLang from "~/components/common/Language/LanguageSwitchLang";
import Loading from "~/components/common/Loading/index";
import WithPermission from "~/components/common/WithPermission";
import { LANGUAGE, requireRules } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import { useFailedAnt } from "~/utils/hook";
import {
  useCreateCategoryParent,
  useGetCategoryParent,
} from "../categoryParent.hook";
type propsType = {
  id?: any;
  onCancel?: () => void;
  handleUpdate?: (p?: any) => void;
  readOnly?: boolean;
};
export default function CategoryParentForm({
  id,
  onCancel,
  handleUpdate,
  readOnly,
}: propsType): React.JSX.Element {
  const { onFinishFailed, ErrorComponent, setErrors } = useFailedAnt();

  const [form] = Form.useForm();
  const [language, setLanguage] = useState<any>(LANGUAGE.VI);
  const [data, loading] = useGetCategoryParent(id);
  const [submitLoading, onCreate] = useCreateCategoryParent(onCancel);
  const onFinish = (values: any) => {
    setErrors([]); // clear errors
    if (id) {
      handleUpdate &&
        handleUpdate({
          _id: id,
          ...values,
        });
    } else {
      onCreate({
        ...values,
      });
    }
  };
  useEffect(() => {
    if(id && data){
      form.setFieldsValue({
        ...data,
      });
    };
    
  }, [data,id]);
  return (
    <Form onFinishFailed={onFinishFailed} form={form} onFinish={onFinish}>
      <Loading loading={loading} />
      <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"} />
      <LanguageSwitchLang language={language} setLanguage={setLanguage}/>
      <LangFormItem
        rules={requireRules}
        fieldName="name"
        label="Tên danh mục lớn"
        language={language}
      >
        <Input readOnly={readOnly}/>
      </LangFormItem>
      {!readOnly && <WithPermission
        permission={
          id ? POLICIES.UPDATE_CATEGORYPARENT : POLICIES.WRITE_CATEGORYPARENT
        }
      >
        <Button loading={submitLoading} htmlType="submit" type="primary">
          {id ? "Cập nhật" : "Tạo mới"}
        </Button>
      </WithPermission>}
    </Form>
  );
}
