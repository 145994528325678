import { Form } from 'antd'
import { FormItemProps } from 'antd/lib/index'
import { get, isArray } from 'lodash'
import React, { useMemo } from 'react'
import { LANGUAGE } from '~/constants/defaultValue'
interface Props extends FormItemProps{
    fieldName : string | any[],
    language : "vi" | "en"
}
export default function LangFormItem({ fieldName, language, ...props } : Props) {
    const fieldName_ = useMemo(() => isArray(fieldName) ? [...fieldName] : [fieldName],[fieldName])
    return (
        <>
            <Form.Item initialValue={''} hidden={language !== LANGUAGE.VI} name={[...fieldName_,LANGUAGE.VI]} {...props} label={`${get(props,'label','')} (vi)`}>
                {props.children}
            </Form.Item>
            <Form.Item initialValue={''}  hidden={language !== LANGUAGE.EN} name={[...fieldName_,LANGUAGE.EN]} {...props} label={`${get(props,'label','')} (en)`}>
                {props.children}
            </Form.Item>
        </>
    )
}
