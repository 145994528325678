import { Image } from 'antd';
import { compact, get } from 'lodash';
import React, { useMemo } from 'react';
import imageDefault from '~/assets/images/image.jpeg';

type propsType = {
    images : string[],
    style? : React.CSSProperties
}
export default function ImageProduct({images,style={}}:propsType) : React.JSX.Element {
    const image = useMemo(() => get(compact(images),'[0]'),[images]);

    return (
        <div style={{...style}}>
        <Image src={image ?? imageDefault} alt='Ảnh mặt hàng'/>
      </div>
    )
}