import { Button, Checkbox, Dropdown, Flex, Popconfirm, Popover, Typography } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import ModalAnt from "~/components/Antd/ModalAnt";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import BtnAdd from "~/components/common/Layout/List/Header/BtnAdd";
import DropdownAction from "~/components/common/Layout/List/Header/DropdownAction";
import Status from "~/components/common/Status/index";
import WithPermission from "~/components/common/WithPermission";
import ExportExcelButton from "~/modules/export/component/index";
import useCheckBoxExport from "~/modules/export/export.hook";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import { PATH_APP } from "~/routes/allPath";
import PotentialSellerForm from "../components/PotentialSellerForm";
import { POTENTIAL_SELLER_STATUS, POTENTIAL_SELLER_STATUS_VI, POTENTIAL_SELLER_REFCOLLECTION, POTENTIAL_SELLER_REFCOLLECTION_VI } from "../constants";
import {
  usePotentialSellerPaging,
  usePotentialSellerQueryParams,
  useGetPotentialSellers,
  useUpdatePotentialSellerParams,
  useConvertPotentialSeller,
  useDeletePotentialSeller,
} from "../potentialSeller.hook";
type propsType = {};
export default function PotentialSeller(props: propsType): React.JSX.Element {
  const canDownload = useMatchPolicy(POLICIES.DOWNLOAD_POTENTIALSELLER);
  const [arrCheckBox, onChangeCheckBox] = useCheckBoxExport();

  const [open, setOpen] = useState(false);
  const [id, setId] = useState<any>();

  const [query] = usePotentialSellerQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdatePotentialSellerParams(query);
  const [dataSource, isLoading] = useGetPotentialSellers(query);
  const paging = usePotentialSellerPaging();
  const [isSubmitLoading,onConvert] = useConvertPotentialSeller();
  const [,onDelete] = useDeletePotentialSeller();
  const onOpen = useCallback((i?: any) => {
    if (i) setId(i);
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const columns: ColumnsType = [
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
      width : 100,
      align : "center",
      fixed : 'left',
    },
    {
      title: "Mã chuyển đổi",
      dataIndex: "refId",
      key: "refId",
      width : 120,
      align : "center",
      render : (refId,rc) => {
        const code = get(rc,'refId.code',get(rc,'refId.partnerNumber',''));
        let url = '';
        if(rc?.refCollection === POTENTIAL_SELLER_REFCOLLECTION.pharma_profile) url = PATH_APP.pharmacy.root;
        if(rc?.refCollection === POTENTIAL_SELLER_REFCOLLECTION.partner) url = '/collaborator';
        if(rc?.refCollection === POTENTIAL_SELLER_REFCOLLECTION.visitor) url = PATH_APP.visitor.root;
        return refId && <Typography.Link onClick={() => window.open(`${url}?keyword=${code}`)}>{get(POTENTIAL_SELLER_REFCOLLECTION_VI,rc?.refCollection)} {">"} {code}</Typography.Link>
      }
    },
    {
      title: "Tên",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width : 120,
      align : "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Số giấy phép kinh doanh",
      dataIndex: "businessLicenseNumber",
      key: "businessLicenseNumber",
    },
    {
      title: "Số giấy phép hoạt động",
      dataIndex: "operatingLicenseNumber",
      key: "operatingLicenseNumber",
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "createdAt",
      key: "createdAt",
      width : 200,
      align : 'center',
      render : (createdAt) => dayjs(createdAt).format("DD/MM/YYYY HH:mm:ss")
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width : 220,
      align : 'center',
      render : (status) => <Status status={status} statusVi={get(POTENTIAL_SELLER_STATUS_VI,status,'')}/>
    },
    {
      title: "Chuyển đổi",
      dataIndex: "_id",
      key: "_id",
      width : 200,
      align : 'center',
      render : (_id,rc) => <Dropdown
      disabled={rc?.status !== POTENTIAL_SELLER_STATUS.NEW}
      trigger={['click']}
      menu={{
        items: [
          {
            label : "B2B",
            key : "B2B",
            onClick : () => onConvert({id : _id,refCollection : POTENTIAL_SELLER_REFCOLLECTION.pharma_profile})
          },
          {
            label : "B2C",
            key : "B2C",
            onClick : () => onConvert({id : _id,refCollection : POTENTIAL_SELLER_REFCOLLECTION.partner})
          },
          {
            label : "Visitor",
            key : "Visitor",
            onClick : () => onConvert({id : _id,refCollection : POTENTIAL_SELLER_REFCOLLECTION.visitor})
          },
        ],
      }}
    >
      <Button loading={isSubmitLoading} type="primary">Chuyển đổi sang</Button>
    </Dropdown>
    },
    {
      title: "Thao tác",
      dataIndex: "_id",
      key: "_id",
      width : 100,
      align : 'center',
      render : (_id,rc) =>   <Flex justify={'center'} align={"center"} gap={10}>
      <WithPermission permission={POLICIES.UPDATE_POTENTIALSELLER}>
      <Typography.Link disabled={rc?.status !== POTENTIAL_SELLER_STATUS.NEW} onClick={() => onOpen(_id)}>Sửa</Typography.Link>
      </WithPermission>
      <Popconfirm
        okButtonProps={{ loading: isSubmitLoading }}
        title="Xác nhận xoá"
        onConfirm={() => onDelete(_id)}
      >
        <Typography.Link type="danger">Xoá</Typography.Link>
      </Popconfirm>
    </Flex>
    },
  ];

  if(canDownload){
    columns.push({
      title: "Lựa chọn",
      key: "_id",
      width: 80,
      align: "center" as any,
      render: (item: any, record: any) => {
        const id = record._id;
        return (
          <Checkbox
            checked={arrCheckBox.includes(id)}
            onChange={(e) => onChangeCheckBox(e.target.checked, id)}
          />
        );
      },
    },)
  }
  return (
    <div>
      <Breadcrumb title={"Trở thành nhà bán hàng"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <SearchAnt onParamChange={onParamChange} value={keyword || query?.keyword} onChange={(e) => setKeyword(e.target.value)}/>
        <Flex gap={5}>
        <WithPermission permission={POLICIES.WRITE_POTENTIALSELLER}>
        <BtnAdd onClick={() => onOpen()}/>
        </WithPermission>
        <DropdownAction
                  items={[
                    <WithPermission
                      permission={POLICIES.DOWNLOAD_POTENTIALSELLER}
                    >
                      <ExportExcelButton
                        fileName="Danh sách trở thành nhà bán hàng"
                        api="potential-seller"
                        query={query}
                        ids={arrCheckBox}
                        useLayout="v2"
                      />
                    </WithPermission>,
                  ]}
                />
        </Flex>
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        scroll={{x : 2000}}
        stickyTop
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showSizeChanger: true,
          showTotal: (total) => `Tổng cộng: ${total} `,
        }}
        loading={isLoading}
      />
      <ModalAnt
        title={id ? "Cập nhật ứng viên nhà bán hàng" : "Tạo mới ứng viên nhà bán hàng"}
        width={800}
        onCancel={onClose}
        open={open}
        destroyOnClose
        footer={null}
      >
        <PotentialSellerForm id={id} onCancel={onClose} />
      </ModalAnt>
    </div>
  );
}
