export const TYPE_BENEFIT = {
  KPIS_TDV: "KPIS_TDV",
  // KPIS_TEAMLEADER: "KPIS_TEAMLEADER",
  KPIS_ASM: "KPIS_ASM",
  KPIS_CONFIG_AREA: "KPIS_CONFIG_AREA",

  BENEFIT_BASE: "BENEFIT_BASE",
  BENEFIT_TDV: "BENEFIT_TDV",
  BENEFIT_TEAMLEADER: "BENEFIT_TEAMLEADER",
  BENEFIT_ASM: "BENEFIT_ASM",

  BENEFIT_TDV_OVER__QUANTER: "BENEFIT_TDV_OVER__QUANTER",
  BENEFIT_TEAMLEADER_OVER__QUANTER: "BENEFIT_TEAMLEADER_OVER__QUANTER",
  BENEFIT_ASM_OVER__QUANTER: "BENEFIT_ASM_OVER__QUANTER",

  BENEFIT_TDV_OVER__YEAR: "BENEFIT_TDV_OVER__YEAR",
  BENEFIT_TEAMLEADER_OVER__YEAR: "BENEFIT_TEAMLEADER_OVER__YEAR",
  BENEFIT_ASM_OVER__YEAR: "BENEFIT_ASM_OVER__YEAR",

  BENEFIT_TDV_OVER: "BENEFIT_TDV_OVER",
  BENEFIT_TEAMLEADER_OVER: "BENEFIT_TEAMLEADER_OVER",
  BENEFIT_ASM_OVER: "BENEFIT_ASM_OVER",

  BENEFIT_WORKING: "BENEFIT_WORKING",
} as const;

export const GROUP_TYPE_BENEFIT_EMPLOYEE_LEVEL = {
  TDV: [
    TYPE_BENEFIT.BENEFIT_BASE,
    TYPE_BENEFIT.BENEFIT_TDV,
    TYPE_BENEFIT.KPIS_TDV,
    TYPE_BENEFIT.BENEFIT_TDV_OVER,
    TYPE_BENEFIT.BENEFIT_TDV_OVER__QUANTER,
    TYPE_BENEFIT.BENEFIT_TDV_OVER__YEAR,
  ],
  TEAMLEADER: [
    TYPE_BENEFIT.BENEFIT_TEAMLEADER,
    // TYPE_BENEFIT.KPIS_TEAMLEADER,
    // TYPE_BENEFIT.BENEFIT_TEAMLEADER_OVER,
    // TYPE_BENEFIT.BENEFIT_TEAMLEADER_OVER__QUANTER,
    // TYPE_BENEFIT.BENEFIT_TEAMLEADER_OVER__YEAR,
  ],
  ASM: [
    TYPE_BENEFIT.BENEFIT_ASM,
    TYPE_BENEFIT.KPIS_ASM,
    TYPE_BENEFIT.BENEFIT_ASM_OVER,
    TYPE_BENEFIT.BENEFIT_ASM_OVER__QUANTER,
    TYPE_BENEFIT.BENEFIT_ASM_OVER__YEAR,
  ],
  BENEFIT_WORKING:[
    TYPE_BENEFIT.BENEFIT_WORKING
  ],
  KPIS_CONFIG_AREA:[
    TYPE_BENEFIT.KPIS_CONFIG_AREA
  ],
};
export const GROUP_TYPE_BENEFIT_EMPLOYEE_LEVEL_VI = {
    TDV : "Trình dược viên",
    TEAMLEADER : "TEAM LEADER",
    ASM : "ASM",
    BENEFIT_WORKING : "Giờ làm việc hiệu quả",
    KPIS_CONFIG_AREA : "Cấu hình điểm bán hàng",
}
export const TYPE_BENEFIT_VI = {
  BENEFIT_TDV: "Hoa hồng TDV",
  BENEFIT_BASE: "Hoa hồng dưới chuẩn",
  BENEFIT_TEAMLEADER: "Thưởng theo quản lý đội nhóm",
  BENEFIT_ASM: "Hoa hồng bán hàng theo tháng",

  BENEFIT_TDV_OVER__QUANTER: "Thưởng Quý",
  BENEFIT_TEAMLEADER_OVER__QUANTER: "Thưởng quý",
  BENEFIT_ASM_OVER__QUANTER: "Thưởng Quý",

  BENEFIT_TDV_OVER__YEAR: "Thưởng năm",
  BENEFIT_TEAMLEADER_OVER__YEAR: "Thưởng năm",
  BENEFIT_ASM_OVER__YEAR: "Thưởng năm",

  BENEFIT_TDV_OVER: "Thưởng vượt trên doanh số khoán theo tháng",
  BENEFIT_TEAMLEADER_OVER: "Thưởng vượt trên doanh số khoán theo tháng ",
  BENEFIT_ASM_OVER: "Thưởng vượt trên doanh số khoán theo tháng",

  KPIS_TDV: "Thưởng KPI",
  // KPIS_TEAMLEADER: "Thưởng KPI",
  KPIS_ASM: "Thưởng KPI",
  KPIS_CONFIG_AREA: "Cấu hình điểm bán",

  BENEFIT_WORKING: "Giờ làm việc hiệu quả",
} as const;

export const GROUP_TYPE_BENEFIT_BASE: any[] = [
  TYPE_BENEFIT.BENEFIT_BASE,
  TYPE_BENEFIT.BENEFIT_TDV,
];
export const GROUP_TYPE_BENEFIT: any[] = [
  TYPE_BENEFIT.BENEFIT_TEAMLEADER,
  TYPE_BENEFIT.BENEFIT_ASM,
];
export const GROUP_TYPE_BENEFIT_KPIS: any[] = [
  TYPE_BENEFIT.KPIS_TDV,
  // TYPE_BENEFIT.KPIS_TEAMLEADER,
  TYPE_BENEFIT.KPIS_ASM,
];

export const GROUP_TYPE_BENEFIT_OVER_QUARTER: any[] = [
  TYPE_BENEFIT.BENEFIT_TDV_OVER__QUANTER,
  TYPE_BENEFIT.BENEFIT_TEAMLEADER_OVER__QUANTER,
  TYPE_BENEFIT.BENEFIT_ASM_OVER__QUANTER,
];
export const GROUP_TYPE_BENEFIT_OVER_YEAR: any[] = [
  TYPE_BENEFIT.BENEFIT_TDV_OVER__YEAR,
  TYPE_BENEFIT.BENEFIT_TEAMLEADER_OVER__YEAR,
  TYPE_BENEFIT.BENEFIT_ASM_OVER__YEAR,
];
export const GROUP_TYPE_BENEFIT_OVER_MONTH: any[] = [
  TYPE_BENEFIT.BENEFIT_TDV_OVER,
  TYPE_BENEFIT.BENEFIT_TEAMLEADER_OVER,
  TYPE_BENEFIT.BENEFIT_ASM_OVER,
];

export const GROUP_TYPE_BENEFIT_OVER: any[] = [
  ...GROUP_TYPE_BENEFIT_OVER_QUARTER,
  ...GROUP_TYPE_BENEFIT_OVER_YEAR,
  ...GROUP_TYPE_BENEFIT_OVER_MONTH,
];

export const TYPE_KPI = {
  COVER_POS: "COVER_POS",
  EXCLUSIVE_PRODUCT: "EXCLUSIVE_PRODUCT",
  IDEA: "IDEA",
} as const;

export const TYPE_KPI_VI = {
  COVER_POS: "Độ phủ thị trường",
  EXCLUSIVE_PRODUCT: "SP độc quyền",
  IDEA: "Ý kiến đóng góp",
};
