import { FilterTwoTone, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Checkbox, Flex, Popconfirm, Select, Space, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import dayjs from "dayjs";
import { intersection, map } from "lodash";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import { getExistProp, ObjOrString } from "~/utils/helpers";
import NewsSearchDraw from "../components/NewsSearchDraw";
import {
  useDeleteNews,
  useGetNewss, useNewsPaging,
  useNewsQueryParams, useUpdateNews, useUpdateNewsParams
} from "../news.hook";
type propsType = {};
const options=[
  {
    value : 'PUBLISHED',
    label : <span style={{color: 'green'}}>Công khai</span>,
  },
  {
    value : 'PRIVATE',
    label : <span style={{color: 'blue'}}>Nội bộ</span>,
  },
  {
    value : 'EDITING',
    label : <span style={{color: 'red'}}>Đang sửa</span>,
  }
]
export default function News(): React.JSX.Element {
  const [query] = useNewsQueryParams();
  const [openSearch,setOpenSearch] = useState(false);
  const [keyword, { setKeyword, onParamChange }] = useUpdateNewsParams(query);
  const [dataSource, isLoading] = useGetNewss(query);
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_NEWS);

  const paging = useNewsPaging();
  const [isSubmitLoading, onDelete] = useDeleteNews();


  const onOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);
  const onCloseSearch = useCallback(() => {
    setOpenSearch(false);
  }, []);

  const [, onUpdate] = useUpdateNews();



  const columns: ColumnsType = [
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
      render : (title,rc) => <Link className="link_" to={`/news-detail/${rc?._id}`}>{ObjOrString(title)}</Link>
    },

    {
      title: 'Danh mục',
      dataIndex: 'category',
      key: 'category',
      render: (category) => {
        return (
          <Space direction="vertical">
            {map(category, (e) => (
              <Tag color="#2b9dff">{ObjOrString(e?.name)}</Tag>
            ))}
          </Space>
        );
      }
    },

    {
      title: 'Đường dẫn',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'Tác giả',
      key: 'author',
      dataIndex: ['author','fullName']
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      align : 'center',
      render: (date) => dayjs(date).format('DD-MM-YYYY')
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      render: (status, record) => (
        <Select
          disabled={!canUpdate}
          bordered={false}
          defaultValue={status}
          onChange={(value) => {
            onUpdate({
              _id : record?._id,
              status : value
            })
          }}
          options={options}
        />
      )
    },

    {
      title: 'Highlight',
      dataIndex: 'highlight',
      key: 'highlight',
      align: 'center',
      width: 100,
      render: (highlight, record) => {
      const checkField = ['PRIVATE', 'EDITING'].includes(record.status);
      return (
        <TooltipRowNews checkField={checkField}>
          <Checkbox
            disabled={checkField || !canUpdate}
            onChange={(e) =>
              onUpdate({
                _id : record?._id,
                highlight : e.target.checked
              })
            }
            checked={highlight}
          />
        </TooltipRowNews>
      );
      }
    },
    {
      title: 'Nổi bật',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      align: 'center',
      width: 100,
      render: (isFeatured,record) => {
        const checkField = ['PRIVATE', 'EDITING'].includes(record.status);
        return (
          <TooltipRowNews checkField={checkField}>
            <Checkbox
              disabled={checkField || !canUpdate}
              onChange={(e) =>
                onUpdate({
                  _id : record?._id,
                  isFeatured : e.target.checked
                })
              }
              checked={isFeatured}
            />
          </TooltipRowNews>
        );
      }
    },
    {
      title: 'Thao tác',
      key: '_id',
      dataIndex : '_id',
      width: 100,
      align : 'center',
      fixed : 'right',
      render: (_id,record) => {
        return   <Flex justify={"center"} align={"center"} gap={5}>
        <WithPermission permission={POLICIES.UPDATE_NEWS}>
          <Typography.Link href={`/news-update/${_id}`}>Sửa</Typography.Link>
        </WithPermission>
        |
        <WithPermission permission={POLICIES.DELETE_NEWS}>
          <Popconfirm
            okButtonProps={{ loading: isSubmitLoading }}
            title="Xác nhận xoá"
            onConfirm={() => onDelete(_id)}
          >
            <Typography.Link type="danger">Xoá</Typography.Link>
          </Popconfirm>
        </WithPermission>
      </Flex>
      }
    }
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách tin tức"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <Flex gap={10}>
          <SearchAnt onParamChange={onParamChange} />
          <Badge
            dot={
              !!intersection(Object.keys(getExistProp(query)), [
                "keyword",
                "status",
                "category",
                "filterDate",
                "author",
                "isFeatured",
              ])?.length
            }
          >
            <Button
              type="primary"
              ghost
              icon={<FilterTwoTone />}
              onClick={onOpenSearch}
            />
          </Badge>
        </Flex>
        <WithPermission permission={POLICIES.WRITE_NEWS}>
          <a href="/news-create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
          >
            Thêm mới
          </Button>
          </a>
        </WithPermission>
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 1500 }}
        stickyTop
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showSizeChanger: true,
          showTotal: (total) => `Tổng cộng: ${total} `,
        }}
        loading={isLoading}
      />
      <NewsSearchDraw
        query={query}
        onCloseSearch={onCloseSearch}
        onSearch={onParamChange}
        openSearch={openSearch}
      />
    </div>
  );
}

function TooltipRowNews ({children,checkField} : any) {
  return (
    <Tooltip
      color="#bebebe"
      overlayInnerStyle={{ maxWidth: 200, textAlign: 'center' }}
      style={{ maxWidth: '180px' }}
      title={
        checkField
          ? 'Không thể thiết lập với bài viết không công khai và đang sửa'
          : ''
      }
    >
      {children}
    </Tooltip>
  );
};
