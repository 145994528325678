import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Flex, Popconfirm, Popover, Row, Tag, Typography } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import { formatter } from "~/utils/helpers";
import { DiscountOtherType } from "../bill.modal";
import DiscountOtherForm from "./DiscountOtherForm";
import useCreateBillStore from "../storeContext/CreateBillContext";
import { CouponInSelect } from "~/modules/coupon/coupon.modal";
import { CalculateDiscountFactory } from "../bill.service";
import PopoverDiscountOtherForm from "./PopoverDiscountOtherForm";
const CalculateDiscountMethod = new CalculateDiscountFactory();
type propsType = {
  onAdd: (p: any) => void;
  onUpdate: (p: any, index: number) => void;
  onRemove: (index: number) => void;
  dataSource: DiscountOtherType[];
  totalDiscountOther: number;
  productId : string;
  variantId : string;
  productGroupId? : string;
  couponsInItem : CouponInSelect[]
  price: number;
};
export default function DiscountOther({
  onAdd,
  onUpdate,
  onRemove,
  dataSource,
  totalDiscountOther,
  productId,
  variantId,
  productGroupId,
  couponsInItem,
  price,
}: propsType): React.JSX.Element {
  const [open,setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const [openUpdate,setOpenUpdate] = useState(false);
  const hideUpdate = () => {
    setOpenUpdate(false);
  };
  const handleOpenChangeUpdate = (newOpen: boolean) => {
    setOpenUpdate(newOpen);
  };
  const {onOpenCouponBillItem,previewCouponBillItem,previewCouponBillItemLoading} = useCreateBillStore();
  return (
    <div>
      <div className="d-flex flex-column" style={{width : 'max-content'}}>
      <Popover
        destroyTooltipOnHide
        open={open}
        trigger={"click"}
        onOpenChange={handleOpenChange}
        content={<DiscountOtherForm hide={hide} onAdd={onAdd} onUpdate={onUpdate} />}
      >
        <Button size="small" className="mb-1" icon={<PlusOutlined />} type="primary" ghost>
          Thêm Chiết khấu
        </Button>
      </Popover>
      <Badge size="small" count={previewCouponBillItemLoading ? "..." : get(previewCouponBillItem,[productId,'count'],0)}>
      <Button onClick={() => onOpenCouponBillItem(productId,variantId,productGroupId)} size="small" className="mb-1" icon={<PlusOutlined />} type="primary" ghost>
          Thêm mã giảm giá
        </Button>
      </Badge>
      </div>
      {dataSource?.map((item: DiscountOtherType, index: number) => (
        <Row align={"middle"} key={index}>
          <Col span={12}>
            <Typography.Text strong>
              {" "}
              <Tag color={"cyan"}>Chiết khấu thêm</Tag>
            </Typography.Text>
            <Typography.Text strong>{get(item, "name", "")}</Typography.Text>
          </Col>
          <Col span={6}>
            {/* <Typography.Text strong>{formatter(get(item,'value',0))}</Typography.Text> */}
          </Col>
          <Col span={3}>
            <Typography.Text strong>
              {formatter(
                CalculateDiscountMethod.totalDiscountOther(price,item?.value,item?.typeDiscount,1)
              )}
              {item?.typeDiscount === "PERCENT" && ` (${item?.value}%)`}
            </Typography.Text>
          </Col>
          <Col flex={1}>
            <Flex>
              <PopoverDiscountOtherForm onAdd={onAdd} onUpdate={onUpdate} initData={item} index={index}/>

              <Popconfirm title="Xác nhận gỡ" onConfirm={() => onRemove(index)}>
              <Button type="text">
                <DeleteOutlined />
              </Button>
              </Popconfirm>
            </Flex>
          </Col>
        </Row>
      ))}
      {couponsInItem?.filter(item => item?.couponAtVariantId === variantId)?.map((item,index) =>   <Row align={"middle"} key={index}>
          <Col span={12}>
            <Typography.Text strong>
              {" "}
              <Tag color={"volcano"}>Mã giảm giá</Tag>
            </Typography.Text>
            <Typography.Text strong>{get(item, "name", "")}</Typography.Text>
          </Col>
          <Col span={6}>
            {/* <Typography.Text strong>{formatter(get(item,'value',0))}</Typography.Text> */}
          </Col>
          <Col span={3}>
            <Typography.Text>
              -{formatter(get(item,'totalCoupon',0))}
              {item?.discount?.type === "PERCENT" && `(${item?.discount?.value}%)`}
            </Typography.Text>
          </Col>
          <Col flex={1}>
            <Flex>
            </Flex>
          </Col>
        </Row>)}
    </div>
  );
}
