import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm, Switch, Typography } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import React, { useCallback, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import WithPermission from "~/components/common/WithPermission";
import { STATUS } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import { ObjOrString } from "~/utils/helpers";
import {
  useCategoryParentPaging,
  useCategoryParentQueryParams,
  useDeleteCategoryParent,
  useGetCategoryParents,
  useUpdateCategoryParent,
  useUpdateCategoryParentParams,
} from "../categoryParent.hook";
import CategoryParentForm from "../components/CategoryParentForm";
type propsType = {};
export default function CategoryParent(props: propsType): React.JSX.Element {
  const [open, setOpen] = useState(false);
  const [query] = useCategoryParentQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateCategoryParentParams(query);
  const [dataSource, isLoading] = useGetCategoryParents(query);
  const paging = useCategoryParentPaging();
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_CATEGORYPARENT)
  const [isSubmitLoading, onDelete] = useDeleteCategoryParent();
  const [id, setId] = useState<any>();
  const onOpen = useCallback((i?: any) => {
    if (i) setId(i);
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const [, onUpdate] = useUpdateCategoryParent(onClose);
  const columns: ColumnsType = [
    {
      title: "Tên danh mục lớn",
      dataIndex: "name",
      key: "name",
      render: (name, rc) => (
        <Link className="link_" to={`/categoryParent-detail/${rc?._id}`}>
          {ObjOrString(name)}
        </Link>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status, rc) => (
        <Switch
          disabled={!canUpdate}
          checked={status === STATUS.ACTIVE}
          onChange={(checked) =>
            onUpdate({
              _id: rc?._id,
              status: checked ? STATUS.ACTIVE : STATUS.INACTIVE,
            })
          }
        />
      ),
    },
    {
      title: "Thao tác",
      key: "action",
      dataIndex: "_id",
      width: 170,
      align: "center",
      fixed: "right",
      render: (_id, rc) => (
        <Flex justify={"center"} align={"center"} gap={5}>
          <WithPermission permission={POLICIES.UPDATE_CATEGORYPARENT}>
            <Typography.Link onClick={() => onOpen(_id)}>Sửa</Typography.Link>
          </WithPermission>
          |
          <WithPermission permission={POLICIES.DELETE_CATEGORYPARENT}>
            <Popconfirm
              okButtonProps={{ loading: isSubmitLoading }}
              title="Xác nhận xoá"
              onConfirm={() => onDelete(_id)}
            >
              <Typography.Link type="danger">Xoá</Typography.Link>
            </Popconfirm>
          </WithPermission>
        </Flex>
      ),
    },
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách danh mục lớn"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <SearchAnt onParamChange={onParamChange} />
        <WithPermission permission={POLICIES.WRITE_CATEGORYPARENT}>
          <Button
            onClick={() => onOpen()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Thêm mới
          </Button>
        </WithPermission>
      </Flex>
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        stickyTop
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showSizeChanger: true,
          showTotal: (total) => `Tổng cộng: ${total} `,
        }}
        loading={isLoading}
      />
      <ModalAnt
        title={id ? "Cập nhật danh mục lớn" : "Tạo mới danh mục lớn"}
        width={800}
        onCancel={onClose}
        open={open}
        destroyOnClose
        footer={null}
      >
        <CategoryParentForm
          handleUpdate={onUpdate}
          id={id}
          onCancel={onClose}
        />
      </ModalAnt>
    </div>
  );
}
