import NewsScreen from "./screens/News";
import newsApi from "./news.api";
import * as newsHook from "./news.hook";
import * as newsService from "./news.service";
import * as newsModels from "./news.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : NewsScreen,
    },
    api : newsApi,
    hook : newsHook,
    service : newsService,
    model : newsModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;