import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import React from "react";
import { ObjOrString } from "~/utils/helpers";
interface propsType extends TextProps { label?: string; value?: any , children? : any };
export default function ViewItem({
  label,
  value,
  children,
  ...props
}: propsType): React.JSX.Element {
  return (
    <div>
      <span>{label}: </span>
      {value && <Typography.Text strong {...props}>{ObjOrString(value)}</Typography.Text>}
      {children && children}
    </div>
  );
}
