import { createSlice } from "@reduxjs/toolkit";
import { get, omit } from "lodash";
// import { cumulativeDiscountType } from "~/modules/cumulativeDiscount/cumulativeDiscount.modal";
import { InstanceModuleRedux } from "~/redux/instanceModuleRedux";
import { initStateSlice } from "~/redux/models";
import { getValueOfPercent } from "~/utils/helpers";
import { FeeType } from "../bill.modal";
// import { CalculateBill, CalculateDiscountFactory } from "../bill.service";
import { STATUS_BILL } from "../constants";
// const CalculateBillMethod = new CalculateBill();
interface cloneInitState extends initStateSlice {
 // Add cloneInitState Type Here
  isGetDebtLoading? : boolean,
  getDebtFailed? : any,
  debt? : any,
  updateBillItemFailed? : any,
  updateBillItemSuccess?: any,
  
  updateLogisticSuccess? : any,
  updateLogisticFailed?: any,

  updateStatusBillFailed? : any,
  updateStatusBillSuccess?: any,

  listProductSuggest?:any,
  isProductSuggestLoading?: boolean,
  getProductSuggestFailed?: any,
  pagingProductSuggest?: any;
  
  splitBillFailed? : any,
  splitBillSuccess?: any,
}
class BillClassExtend extends InstanceModuleRedux {
  cloneReducer;
  cloneInitState : cloneInitState;
  constructor() {
    super('bill');
    this.cloneReducer = {
      ...this.initReducer,
      // Want Add more reducer Here...
    // Get Debt
    getDebtRequest: (state:cloneInitState) => {
      state.isGetDebtLoading = true;
      state.getDebtFailed = null;
    },
    getDebtSuccess: (state:cloneInitState, { payload }:{payload?:any}) => {
      state.isGetDebtLoading = false;
      state.debt = payload;
    },
    getDebtFailed: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isGetDebtLoading = false;
      state.getDebtFailed = payload;
    },
    getByIdSuccess: (state:cloneInitState, { payload }:{payload?:any}) => {
      // const CalculateDiscountMethod = new CalculateDiscountFactory();
      state.isGetByIdLoading = false;
      let totalDiscountBill = 0;
      let totalAmountBill = 0;
      const {totalCouponForBill = 0,totalCouponForItem = 0 } = payload;
      const billItems = get(payload,'billItems',[])?.map((billItem : any) => {
        const quantity:number = Number((get(billItem, "quantity", 1) / get(billItem, "variant.exchangeValue", 1)).toFixed(1));
        const price : number = get(billItem, 'variant.price',1);
        const totalAmount = Math.floor(Number(quantity * price));
        const totalDiscount : number = get(billItem,'totalDiscountSummary',0)
        totalDiscountBill += totalDiscount;
        totalAmountBill += totalAmount;
        return {
          ...billItem,
          totalAmount,
          totalDiscount,
        }
      });
      const remainAmount = get(payload,'remaining',0);
      const totalFee = (get(payload,'fee',[]))?.reduce((sum : number,cur : FeeType) => sum + (cur?.typeValue === 'PERCENT' ? getValueOfPercent(get(payload,'totalAmount',0),cur?.value) : cur?.value),0);
      const feeDetail = (get(payload,'fee',[]))?.reduce((sum : {SUB_FEE : number,LOGISTIC : number},cur : FeeType) =>  {
        sum[cur.typeFee] = (sum[cur.typeFee] || 0) + (cur?.typeValue === 'PERCENT' ? getValueOfPercent(get(payload,'totalAmount',0),cur?.value) : cur?.value);
        return sum;
      },{
        SUB_FEE : 0,
        LOGISTIC : 0,
      });
      state.byId = {
        ...payload,
        billItems,
        remainAmount,
        totalDiscountBill,
        totalAmountBill,
        totalAfterDiscountBill : totalAmountBill - totalDiscountBill - totalCouponForBill - totalCouponForItem,
        totalFee,
        feeDetail,
      }
      },
    getListProductSuggestRequest: (state:cloneInitState) => {
      state.isProductSuggestLoading = true;
      },
    getListProductSuggestSuccess: (state:cloneInitState, { payload }:{payload?:any}) => {
      state.isProductSuggestLoading = false;
      state.listProductSuggest = payload;
      state.pagingProductSuggest = {
        current : payload?.page,
        pageSize : payload?.limit,
        total: payload?.totalDocs,
        ...omit(payload,'docs','page','limit','totalDocs')
      }
      },
    getListProductSuggestFailed: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isProductSuggestLoading = false;
      state.getProductSuggestFailed = payload;
    },
    updateSuccess: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoading = false;
      state.byId = payload?.data;
      state.list = state.list?.map((item:any) => get(item,'_id') === get(payload,'data._id') ? payload?.data : item);
      state.updateSuccess = payload;
      },

    // update billItem
    updateBillItemRequest: (state:cloneInitState) => {
      state.isSubmitLoading = true;
      state.updateBillItemFailed = null;
    },
    updateBillItemSuccess: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoading = false;
      const billItemId = Object.keys(payload)?.[0];
      const payloadUpdate = get(payload,billItemId,{});
      const billItems = get(state.byId,'billItems',[])?.map((billItem : any) => {
        if(billItemId === get(billItem,'_id')){
          return {
            ...billItem,
            ...payloadUpdate
          }
        }
        return billItem;
      
      });
      state.byId = {
        ...state.byId,
        billItems
      }
      state.updateBillItemSuccess = payload;
    },
    updateBillItemFailed: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoading = false;
      state.updateBillItemFailed = payload;
      },
      updateApplyLogisticRequest: (state:cloneInitState) => {
        state.isSubmitLoading = true;
        state.updateLogisticFailed = null;
      },
      updateApplyLogisticSuccess: (state: cloneInitState, { payload }: { payload: any }) => {
        state.isSubmitLoading = false;
        state.updateLogisticSuccess = payload;
        const fee = payload?.data?.fee?.map((item: any) => item?.typeFee === 'LOGISTIC' ? { ...item, value: payload?.data?.dataTransportUnit?.totalFee } : item)
        if (payload?.dataTransportUnit?.payer === 'CUSTOMER') {
          state.byId = {
            ...state.byId,
            fee,
            feeDetail: {
              ...state?.byId?.feeDetail,
              LOGISTIC: payload?.data?.dataTransportUnit?.totalFee
            },
            totalPrice: fee?.totalFee
          };
        } else {
          state.byId = {
            ...state.byId,
            fee,
            feeDetail: {
              ...state?.byId?.feeDetail,
              LOGISTIC: payload?.data?.dataTransportUnit?.totalFee
            },
          };
        }
      },
      updateApplyLogisticFailed: (state:cloneInitState, { payload }:{payload:any}) => {
        state.isSubmitLoading = false;
        state.updateLogisticFailed = payload;
      },

      updateStatusAfterCheckWarehouseRequest: (state: cloneInitState, { payload }: { payload?: any }) => {
        state.byId = {
          ...state.byId,
          ...payload,
          partner: state.byId?.partner,
          // status: payload?.status,
          // warehouseId: payload?.warehouseId,
          billItems: state.byId?.billItems?.map((item: any) => {
            const findItem = get(payload, 'data', []).find((billItem: any) => billItem?.variantId === item?.variantId && billItem?.productId === item?.productId);
            return {
              ...item,
              warehouseProductId: findItem?.warehouseProductId,
              warehouseVariantId: findItem?.warehouseVariantId,
              batchId: findItem?.batchId,
            }
          }),
        };
      
        // state.list = state.list?.map((item: any) => get(item, '_id') === get(payload, '_id') ? {
        //   ...item,
        //   status: payload?.status
        // } : item);
      },
    
    resetAction: (state:cloneInitState) => ({
      ...state,
      ...omit(this.cloneInitState, ["list", "paging"]),
    }),
    resetActionInSplit: (state:cloneInitState) => ({
      ...state,
      ...omit(this.cloneInitState, ["list", "paging", "byId"]),
    }),
    resetActionLogistic: (state:cloneInitState) => ({
      ...state,
      ...omit(this.cloneInitState, ["list",'byId']),
      }),
      updateStatusBillRequest: (state:cloneInitState) => {
        state.isSubmitLoading = true;
        state.updateStatusBillFailed = null;
      },
      updateStatusBillSuccess: (state:cloneInitState, { payload }:{payload:any}) => {
        state.isSubmitLoading = false;
        state.updateStatusBillSuccess = payload;
        state.list = state.list?.map((item: any) => get(item, '_id') === get(payload, 'data._id') ? ({
          ...item,
          status: payload?.data?.status,
          partner: item?.partner
        }) : item);
      },
        updateStatusBillFailed: (state:cloneInitState, { payload }:{payload:any}) => {
        state.isSubmitLoading = false;
        state.updateStatusBillFailed = payload;
        },
      updateBillAfterCheckWarehouseRequest: (state: cloneInitState, { payload }: { payload?: any }) => {
          state.byId = {
            ...state.byId,
            isCheck: true,
            billItems: state.byId?.billItems?.map((item: any) => {
              const findBillItem = get(payload, 'data', [])?.find((billItem: any) => billItem?.codeBySupplier === item?.codeBySupplier);
              return {
                ...item,
                statusCheckWarehouse: findBillItem?.status ?? false
              }
            })
          };
      },
      splitBillRequest: (state:cloneInitState) => {
        state.isSubmitLoading = true;
        state.splitBillFailed = null;
      },
      splitBillSuccess: (state:cloneInitState, { payload }:{payload?:any}) => {
        state.isSubmitLoading = false;
        state.splitBillSuccess = payload;
      },
      splitBillFailed: (state:cloneInitState, { payload }:{payload:any}) => {
        state.isSubmitLoading = false;
        state.splitBillFailed = payload;
      },
    };

    this.cloneInitState = {
      ...this.initialState,
      isGetDebtLoading : false,
      getDebtFailed : null,
      debt : null,
      updateBillItemFailed : null,
      updateBillItemSuccess : null,
      
      updateStatusBillFailed: null, 
      updateStatusBillSuccess: null,

      listProductSuggest: [],
      isProductSuggestLoading: false,
      getProductSuggestFailed: null,

      updateLogisticSuccess: null,
      updateLogisticFailed: null,

      splitBillFailed: null,
      splitBillSuccess: null
      // Want Add more State Here...
    }
  }
  createSlice() {
    return createSlice({
      name: this.module,
      initialState: this.cloneInitState,
      reducers:  this.cloneReducer,
    });
  }
  
}

const newSlice = new BillClassExtend();
const data = newSlice.createSlice();


export const billSliceAction = data.actions;
export default data.reducer;
