import { EditOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import React, { useState } from 'react';
import { DiscountOtherType } from '../bill.modal';
import DiscountOtherForm from './DiscountOtherForm';
type propsType = {
    onAdd : (p:any) => void,
    onUpdate : (p:any,index:number) => void,
    initData? : DiscountOtherType,
    index?:number,
}
export default function PopoverDiscountOtherForm({onAdd,onUpdate,index,initData}:propsType) : React.JSX.Element {
    const [open,setOpen] = useState(false);
    const hide = () => {
      setOpen(false);
    };
    const handleOpenChange = (newOpen: boolean) => {
      setOpen(newOpen);
    };
    return (
        <Popover
        destroyTooltipOnHide
        open={open}
        trigger={"click"}
        onOpenChange={handleOpenChange}
        content={
          <DiscountOtherForm
            onAdd={onAdd}
            onUpdate={onUpdate}
            index={index}
            initData={initData}
            hide={hide}
          />
        }
      >
        <Button type="text">
          <EditOutlined />
        </Button>
      </Popover>
    )
}