import { Flex, Input } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "~/components/common/Layout/List/Detail/Header";
import Layout from "~/components/common/Layout/List/Detail/Layout";
import ListInDetailCommon from "~/components/common/Layout/List/Detail/ListInDetailCommon";
import { NEWS_SECURITY_OPTIONS } from "~/modules/newsCategory/constants";
import POLICIES from "~/modules/policy/policy.auth";
import { PATH_APP } from "~/routes/allPath";
import { ObjOrString } from "~/utils/helpers";
import MainContentTab from "../components/MainContentTab";
import {
  useDeleteNews,
  useGetNews, useGetNewss, useGetNews_onlyGet, useNewsPaging, useNewsQueryParams, useUpdateNewsParams
} from "../news.hook";
export default function NewsDetail(): React.JSX.Element {
  const { id: newsId }: any = useParams();
  const navigate = useNavigate();
  useGetNews(newsId);
  const [query] = useNewsQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateNewsParams(query);
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const onSearch = () => {
        onParamChange({keyword});
        onClose();
    }
  const [news]: any = useGetNews_onlyGet();

  const [, deleteNews] = useDeleteNews();

  return (
    <>
      <Layout
        HeaderLeft={
          <Header.HeaderLeft
            PERMISSION_WRITE={POLICIES.WRITE_NEWS}
            onChangeStatus={(status) => onParamChange({ status })}
            onAdd={() => navigate('/news-create')}
            STATUS={[{label : "Tất cả",value : 'all',isAll : true},...NEWS_SECURITY_OPTIONS]}
            SearchProp={{
                openSearch : showDrawer,
                open,
                onClose,
                onSearch,
                querySearch : ['keyword'],
                SearchComponent : <Input
                placeholder="Nhập để tìm kiếm"
                allowClear
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            }}
          />
        }
        HeaderRight={
          <Header.HeaderRight
            PERMISSION_UPDATE={POLICIES.UPDATE_NEWS}
            PERMISSION_DELETE={POLICIES.DELETE_NEWS}
            path={PATH_APP.news.root}
            onDeleteClick={() => deleteNews(newsId)}
            onEditClick={() => navigate(`/news-update/${newsId}`)}
            name={
              <Flex gap={10} align="center">
                <h4>
                  {ObjOrString(get(news, "title", ""))}
                </h4>
              </Flex>
            }
          />
        }
        MainContent={<MainContentTab />}
        List={
          <ListInDetailCommon
            fieldName="title"
            path={"/news-detail"}
            useGets={useGetNewss}
            usePaging={useNewsPaging}
            query={query}
            onParamChange={onParamChange}
            moduleName="news"
          />
        }
      />
    </>
  );
}
