import { Button, Flex, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import RenderLoading from "~/components/common/RenderLoading";
import WithPermission from "~/components/common/WithPermission";
import { requireRules } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import TenantRoleSelect from "~/modules/tenantRole/components/TenantRoleSelect";
import { useCreateTenant, useGetTenant, useUpdateTenant } from "../tenant.hook";
import { TenantBase } from "../tenant.modal";
type propsType = {
    id? : any,
    onCancel? : () => void,
};
export default function TenantForm({id,onCancel = () => {}}: propsType): React.JSX.Element {
  const [form] = Form.useForm();
  const [tenant, isLoading] = useGetTenant(id);
  
  const [isSubmitLoading,onCreate] = useCreateTenant(onCancel);
  const [,onUpdate] = useUpdateTenant(onCancel);
  const onFinish = (values: TenantBase) => {
    const {name,phoneNumber,tenantCode,email} = values;
    const submitData = {
      ...values,
      name : name?.trim(),
      phoneNumber : phoneNumber?.trim(),
      tenantCode : tenantCode?.trim(),
      email : email?.trim(),
    }
    if(id){
      onUpdate({
        _id : id,
        ...submitData
      })
    }else{
      onCreate(submitData)
    }
  };
  useEffect(() => {
    if (id && tenant) {
      form.setFieldsValue({...tenant});
    }
  }, [id, tenant]);

  
  return (
    <Form form={form} onFinish={onFinish} labelCol={{span : 6}} labelAlign='left'>
      <Form.Item rules={requireRules} name={"name"} label="Tên đối tác">
        {RenderLoading(isLoading,<Input autoComplete="new-password"/>)}
      </Form.Item>
      <Form.Item rules={requireRules} tooltip="subDomain" name={"tenantCode"} label="Mã định danh">
        {RenderLoading(isLoading,<Input autoComplete="new-password"/>)}
      </Form.Item>
      <Form.Item rules={[
        ...requireRules,
        {
          type : 'email'
        }
      ]} name={"email"} label="Email">
        {RenderLoading(isLoading,<Input autoComplete="new-password"/>)}
      </Form.Item>
      <Form.Item rules={requireRules} name={"phoneNumber"} label="Số điện thoại">
        <Input />
      </Form.Item>
      <Form.Item name={"tenantRoleId"} label="Loại đối tác">
        <TenantRoleSelect mode="multiple"/>
      </Form.Item>
      <Form.Item name={"description"} label="Mô tả">
        <TextArea rows={3} />
      </Form.Item>
      <Flex justify={'center'}>
      <WithPermission permission={id ? POLICIES.UPDATE_TENANT : POLICIES.WRITE_TENANT}>
      <Button loading={isSubmitLoading} type="primary" htmlType="submit">
        {id ? "Cập nhật" : "Tạo mới"}
      </Button>
      </WithPermission>
      </Flex>
    </Form>
  );
}
