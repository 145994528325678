import { ThemeConfig } from "antd";

export const theme : ThemeConfig = {
    components : {
      Menu : {
        darkItemBg : '#1C2842',
        darkItemColor : '#8c98bb',
        darkSubMenuItemBg : '#1C2842',
        iconSize : 16,
        fontSize : 16,
      },
    },
  }
