import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Input } from 'antd';
import { useGetlistProductConfigById, useUpdateProductConfig, useCreateProductConfig, useResetAction, useGetlistProductConfigById_onlyGet } from '../productGroup.hook';
import CategoryParentSelect from '~/modules/categoryParent/components/CategoryParentSelect';
import { Language } from '~/typings/modals';
import { LANGUAGE } from '~/constants/defaultValue';
import LanguageSwitchLang from '~/components/common/Language/LanguageSwitchLang';
import LangFormItem from '~/components/common/LangFormItem';
import UploadImage from '~/components/common/Upload/UploadImage';
interface Props {
  id?: any;
  callBack?: () => void;
  setId?: any;
  updateProductConfig?: (data?: any) => void;
  setDestroy? : (data?: any) => void,
  readOnly?:boolean;
};
interface FieldType {
  code: string
  image: string
  id: string
  name: string
  note: string
  categoryParentId: string
  isAction: String
};
const { TextArea } = Input;
const hookGetData = {
  readOnly : useGetlistProductConfigById_onlyGet,
  notReadOnly : useGetlistProductConfigById
}

const ProductConfigForm: React.FC<Partial<Props>> = ({ id,setId, callBack, updateProductConfig,setDestroy,readOnly }) => {
  const [language,setLanguage] = useState<Language>(LANGUAGE.VI);
  const [, createProductConfig] = useCreateProductConfig(() => {
    callBack && callBack();
    setDestroy && setDestroy(true)
  });
  const [productConfigById, isLoading] : any = readOnly ? hookGetData.readOnly() : hookGetData.notReadOnly(id);
  const [form] = Form.useForm();
  useResetAction();
  useEffect(() => {
    if (id && productConfigById) {
      const { code, name, note }: FieldType = productConfigById;
      form.setFieldsValue({
        ...productConfigById
      });
    } else {
      form.resetFields();
    }
  }, [id, productConfigById, form]);
  const onFinish = (values: FieldType) => {
    const data: FieldType = {
      ...values,
    };
    if (id) {
      updateProductConfig && updateProductConfig({ ...data, id });
      form.resetFields()
      setId(null);
    } else {
      createProductConfig({ ...data });
      form.resetFields()
    }
  };
  return (
    <>
      <Form
        name="basic"
        labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
        wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        labelAlign="left"
        style={{ maxWidth: 800 }}
        form={form}
        onFinish={onFinish}
      >
        <LanguageSwitchLang language={language} setLanguage={setLanguage}/>
        <Form.Item<FieldType> label="Mã nhóm danh mục" name="code">
          <Input disabled />
        </Form.Item>
        <LangFormItem
          fieldName={'name'}
          label="Tên danh mục"
          language={language}
        >
          <Input readOnly={readOnly}/>
        </LangFormItem>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item<FieldType> label="Hình ảnh" name="image">
              <UploadImage
                imgUrl={getFieldValue("image")}
                onChange={(url: string) => setFieldsValue({ image: url })}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item<FieldType> label="Thuộc danh mục lớn" name="categoryParentId">
          <CategoryParentSelect readOnly={readOnly}/>
        </Form.Item>
        <Form.Item<FieldType> label="Ghi chú" name="note">
          <TextArea readOnly={readOnly} rows={4} />
        </Form.Item>
        {!readOnly && (
          <Flex justify={"center"}>
            <Button type="primary" htmlType="submit">
              {id ? "Cập nhật" : "Thêm mới"}
            </Button>
          </Flex>
        )}
      </Form>
    </>
  );
};

export default ProductConfigForm;
