import { get } from "lodash";
import { useState } from "react";
import useNotificationStore from "~/store/NotificationContext";
import { useFetchState } from "~/utils/hook";
import apis from "./ui.api";
import { UI_key } from "./ui.modal";

export const useGetUiByKey = ({key,reFetch} : {key: UI_key,reFetch : boolean}) => {
    const [data,loading] = useFetchState({api : apis.getByKey,query : key,useDocs : false,reFetch});
    return [data,loading];
}

export const useUpdateUiByKey = (callback : () => void) : [boolean,(p?:any) => void] => {
    const {onNotify} = useNotificationStore();
    const [loading,setLoading] = useState(false);
    const handleUpdate = async(payload : any) => {
        try {
            setLoading(true);
            await apis.update(payload)
            setLoading(false);
            callback();
            onNotify?.success("Cập nhật thành công")
        } catch (error) {
            setLoading(true);
            onNotify?.success(get(error,'response.data.message',"Cập nhật thất bại"))
        }
    };
    return [loading,handleUpdate];
}