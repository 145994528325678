import TenantRoleScreen from "./screens/TenantRole";
import tenantRoleApi from "./tenantRole.api";
import * as tenantRoleHook from "./tenantRole.hook";
import * as tenantRoleService from "./tenantRole.service";
import * as tenantRoleModels from "./tenantRole.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : TenantRoleScreen,
    },
    api : tenantRoleApi,
    hook : tenantRoleHook,
    service : tenantRoleService,
    model : tenantRoleModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;