import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Typography } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/es/table/InternalTable";
import { useCallback, useMemo, useState } from "react";
import ModalAnt from "~/components/Antd/ModalAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import WhiteBox from "~/components/common/WhiteBox";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import {
  useDeleteTenantRole,
  useGetTenantRoles, useTenantRoleQueryParams,
  useTenantRoleSearch
} from "~/modules/tenantRole/tenantRole.hook";
import { filterSlug } from "~/utils/helpers";
import { useChangeDocumentTitle } from "~/utils/hook";
import TenantRoleForm from "../components/TenantRoleForm";
import { TenantRoleBase } from "../tenantRole.modal";
export default function TenantRole(): React.JSX.Element {
  useChangeDocumentTitle("Danh sách nhóm đối tác phần mềm");
  const [id, setId]: any = useState();
  const [isOpenForm, setIsOpenForm]: any = useState(false);
  
  
  const [query] = useTenantRoleQueryParams();
  const [kw,setKw] = useState<any>();
  const [data, isLoading , actionUpdateData] = useGetTenantRoles(query);
  const dataSearch = useTenantRoleSearch();  
  const dataSource = useMemo(() => kw ? dataSearch : data,[kw,data,dataSearch]);
  
  const [isSubmitLoading, onDelete] = useDeleteTenantRole();
  

  
  // Control form
  const onOpenForm = useCallback((idSelect?: any) => {
    if (idSelect) {
      setId(idSelect);
    }
    setIsOpenForm(true);
  }, []);

  const onCloseForm = useCallback(() => {
    setIsOpenForm(false);
    setId(null);
  }, []);
  
  // Columns Table
  const columns: ColumnsType  = useMemo(
    () => [
        {
            title : 'Tên nhóm',
            dataIndex : 'name',
            name : 'name',
            render: (name, rc) => (
              <Typography.Link onClick={() => onOpenForm(rc?._id)}>
                {name}
              </Typography.Link>
            ),
        },
        {
            title : 'Thao tác',
            dataIndex : '_id',
            name : '_id',
            width : 100,
            align : 'center',
            render : (_id) => <WithPermission permission={POLICIES.DELETE_TENANTROLE}>
              <Popconfirm  title="Xác nhận xoá" onConfirm={() => onDelete(_id)}>
                <Typography.Text type="danger">Xoá</Typography.Text>
            </Popconfirm>
            </WithPermission>
        },
    ],
    []
  );
  const onSearch = (value : string) => {
    const dataTrue = data?.filter((item : TenantRoleBase) => filterSlug(value,item?.name));
    actionUpdateData(dataTrue);
    setKw(value);
  }
  return (
    <div>
      <Breadcrumb title="Danh sách nhóm đối tác phần mềm" />
      <Row className="mb-3" justify={"space-between"}>
        <Col span={8}>
          <Search
            allowClear
            enterButton="Tìm kiếm"
            placeholder="Nhập để tìm kiếm"
            onChange={(e) => onSearch(e.target.value)}
          />
        </Col>
        <WithPermission permission={POLICIES.WRITE_TENANTROLE}>
        <Col>
            <Button
              onClick={() => onOpenForm()}
              icon={<PlusCircleOutlined />}
              type="primary"
            >
              Thêm mới
            </Button>
        </Col>
        </WithPermission>
      </Row>
      <WhiteBox>
        <TableAnt
          dataSource={dataSource}
          loading={isLoading}
          columns={columns}
          rowKey={(rc) => rc?._id}
          stickyTop
          size="small"
          pagination={{
            showTotal: (total) => `Tổng cộng: ${total} `,
            showSizeChanger : true
          }}
        />
      </WhiteBox>
      <ModalAnt
        title={id ? "Cập nhật" : 'Tạo mới' + " nhóm đối tác phần mềm"}
        width={'auto'}
        open={isOpenForm}
        onCancel={onCloseForm}
        footer={null}
        centered
        destroyOnClose
      >
        <TenantRoleForm id={id} onCancel={onCloseForm}/>
      </ModalAnt>

    </div>
  );
}
