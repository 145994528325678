import { Badge, Flex, Input, Typography } from "antd";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import Header from "~/components/common/Layout/List/Detail/Header";
import Layout from "~/components/common/Layout/List/Detail/Layout";
import ListInDetailCommon from "~/components/common/Layout/List/Detail/ListInDetailCommon";
import { STATUS_COLOR, STATUS_NAMES } from "~/constants/defaultValue";
import { PATH_APP } from "~/routes/allPath";
import {
  useSectionPaging, useSectionQueryParams, useDeleteSection,
  useGetSection, useGetSections, useGetSection_onlyGet, useUpdateSection,
  useUpdateSectionParams
} from "../section.hook";
import SectionForm from "../components/SectionForm";
import MainContentTab from "../components/MainContentTab";
import POLICIES from "~/modules/policy/policy.auth";
import { ObjOrString } from "~/utils/helpers";
const CLONE_STATUS_NAMES: any = STATUS_NAMES;
const CLONE_STATUS_COLOR: any = STATUS_COLOR;
export default function SectionDetail(): React.JSX.Element {
  const { id: sectionId }: any = useParams();
  useGetSection(sectionId);
  const [id, setId] = useState<any>();
  const [query] = useSectionQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateSectionParams(query);
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const onSearch = () => {
        onParamChange({keyword});
        onClose();
    }
  const [section]: any = useGetSection_onlyGet();
  
  const [isOpenForm, setIsOpenForm] = useState(false);
  const onCloseForm = useCallback(() => {
    setIsOpenForm(false);
    setId(null);
  }, []);
  const onOpenForm = useCallback((idd?: any) => {
    setIsOpenForm(true);
    idd && setId(idd);
  }, []);
  const [, deleteSection] = useDeleteSection();

  return (
    <>
      <Layout
        HeaderLeft={
          <Header.HeaderLeft
            PERMISSION_WRITE={POLICIES.WRITE_SECTIONPRODUCT}
            onChangeStatus={(status) => onParamChange({ status })}
            onAdd={() => onOpenForm()}
            SearchProp={{
                openSearch : showDrawer,
                open,
                onClose,
                onSearch,
                querySearch : ['keyword'],
                SearchComponent : <Input
                placeholder="Nhập để tìm kiếm"
                allowClear
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            }}
          />
        }
        HeaderRight={
          <Header.HeaderRight
            PERMISSION_UPDATE={POLICIES.UPDATE_SECTIONPRODUCT}
            PERMISSION_DELETE={POLICIES.DELETE_SECTIONPRODUCT}
            path={PATH_APP.section.root}
            onDeleteClick={() => deleteSection(sectionId)}
            onEditClick={() => onOpenForm(sectionId)}
            name={
              <Flex gap={10} align="center">
                <h4>
                  {ObjOrString(get(section, "title", ""))}
                </h4>
                <Typography.Text type="secondary"  style={{ fontSize: 14 , width : 130 }}>
                  <Badge
                    style={{ marginRight: 2 }}
                    status={CLONE_STATUS_COLOR[get(section, "status", "")]}
                  />
                  {CLONE_STATUS_NAMES[get(section, "status", "")]}
                </Typography.Text>
              </Flex>
            }
          />
        }
        MainContent={<MainContentTab />}
        List={
          <ListInDetailCommon
            moduleName="section"
            fieldName="title"
            fieldSubTitle={'sectionName'}
            path={"/section-detail"}
            useGets={useGetSections}
            usePaging={useSectionPaging}
            notPaging
            query={query}
            onParamChange={onParamChange}
          />
        }
      />
      <ModalAnt
        open={isOpenForm}
        onCancel={onCloseForm}
        footer={[]}
        destroyOnClose
      >
        <SectionForm
          onCancel={onCloseForm}
          id={id}
        />
      </ModalAnt>
    </>
  );
}
