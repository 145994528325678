import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import LangFormItem from '~/components/common/LangFormItem';
import RenderLoading from '~/components/common/RenderLoading';
import UploadImage from '~/components/common/Upload/UploadImage';
import { requireRules, requireRulesCustom } from '~/constants/defaultValue';
type propsType = {
    form : any,
    module : "HOMEPAGE" | "ALL" | "PROMOTION",
    name : "Trang chủ" | "Xem tất cả sản phẩm" | "Ưu đãi",
    side : 'left' | "right",
    loading : boolean,
    language : "vi" | "en"
}
export default function FormListItem({form,module,language,loading,side,name}:propsType) : React.JSX.Element {
    return (
        <Form.List name={[module, side]}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Row gutter={16}>
                      {fields?.map((field, index) => {
                        const banner = form.getFieldsValue();
                        const banner_module = form.getFieldValue([module]);
                        const banner_module_side = form.getFieldValue([module,side]);
                        const imgUrl = form.getFieldValue([
                          module,
                          side,
                          index,
                          "url",
                          language
                        ]);
                        const onChange = (newUrl: string) => {
                          form.setFieldsValue({
                            banner: {
                              ...banner,
                              [module]: {
                                ...banner_module,
                                [side]: [...banner_module_side]?.map((item, id) =>
                                  id === index
                                    ? {
                                        ...item,
                                        url: {
                                          ...item?.url,
                                          [language]: newUrl,
                                        },
                                      }
                                    : item
                                ),
                              },
                            },
                          });
                        };
                        return (
                          <Col style={{ marginBottom: 12 }} span={8}>
                            <LangFormItem
                              rules={requireRulesCustom("Vui lòng chọn 1 hình ảnh trong "+name)}
                              label="Ảnh"
                              fieldName={[
                                index,
                                "url",
                              ]}
                              language={language}
                            >
                              {RenderLoading(
                                loading,
                                <UploadImage
                                  className="avatar-uploader-w-100"
                                  imgUrl={imgUrl}
                                  onChange={onChange}
                                />
                              )}
                            </LangFormItem>
                            <LangFormItem
                              rules={requireRulesCustom("Vui lòng nhập mô tả trong "+name)}
                              label="Mô tả"
                              fieldName={[
                                index,
                                "alt",
                              ]}
                              language={language}
                            >
                              {RenderLoading(loading, <Input />)}
                            </LangFormItem>
                            <Button danger type='primary' onClick={() => remove(index)} block>
                              Gỡ
                            </Button>
                          </Col>
                        );
                      })}
                      {form.getFieldValue([ module, side])
                        ?.length >= 5 ? null : (
                        <Col span={8}>
                          <Button
                            block
                            style={{ height: 200 }}
                            onClick={() => add()}
                            type="dashed"
                            icon={<PlusOutlined />}
                          >
                            Thêm
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </>
                );
              }}
            </Form.List>
    )
}