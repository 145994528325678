import { Select } from "antd";
import { SelectProps } from "antd/lib/index";
import React, { useMemo } from "react";
import { filterOptionSlug, useFetchState } from "~/utils/helpers";
import apis from "../newsCategory.api";

export default function NewsCategorySelect(
  props: SelectProps
): React.JSX.Element {
  const [data, loading] = useFetchState({ api: apis.getAll, useDocs: false });

  const options = useMemo(
    () =>
      data?.map((item: any) => ({
        label: item?.name,
        value: item?._id,
      })),
    [data]
  );
  return (
    <Select
      allowClear
      showSearch
      filterOption={filterOptionSlug}
      loading={loading}
      options={options}
      {...props}
    />
  );
}
