import { put, call, takeLatest } from 'redux-saga/effects';
import api from '../visitor.api'; 
import { visitorActions } from './reducer';

function* getListVisitor({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(visitorActions.getListSuccess(data));
    yield put(visitorActions.clearAction());
  } catch (error:any) {
    yield put(visitorActions.getListFailed(error));
  }
}

function* getByIdVisitor({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(visitorActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(visitorActions.getByIdFailed(error));
  }
}

function* createVisitor({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(visitorActions.createSuccess(data));
  } catch (error:any) {
    yield put(visitorActions.createFailed(error));
  }
}

function* updateVisitor({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(visitorActions.updateSuccess(data));
  } catch (error:any) {
    yield put(visitorActions.updateFailed(error));
  }
}
function* deleteVisitor({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(visitorActions.deleteSuccess(data));
  } catch (error:any) {
    yield put(visitorActions.deleteFailed(error));
  }
}


export default function* visitorSaga() {
  yield takeLatest(visitorActions.getListRequest, getListVisitor);
  yield takeLatest(visitorActions.getByIdRequest, getByIdVisitor);
  yield takeLatest(visitorActions.createRequest, createVisitor);
  yield takeLatest(visitorActions.updateRequest, updateVisitor);
  yield takeLatest(visitorActions.deleteRequest, deleteVisitor);
}
