import { Select } from 'antd';
import { SelectProps } from 'antd/lib/index';
import React, { useMemo } from 'react';
import { filterOptionSlug, useFetchState } from '~/utils/helpers';
import apis from '../tenantRole.api';
import { TenantRoleBase } from '../tenantRole.modal';

export default function TenantRoleSelect(props:SelectProps) : React.JSX.Element {
    const [data,loading] = useFetchState({api : apis.getAll, useDocs : false});
    const options = useMemo(() => data?.map((item : TenantRoleBase) => ({
        label : item?.name,
        value : item?._id
    })),[data]);
    
    return (
        <Select filterOption={filterOptionSlug} loading={loading} options={options} {...props}/>
    )
}