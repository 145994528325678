import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/index';
import React from 'react';
interface propsType extends SwitchProps {
    status : "ACTIVE" | "INACTIVE",
    onUpdate : ({status,_id} : {status : "ACTIVE" | "INACTIVE",_id? : string}) => void,
    _id? : string,
    disabled?: boolean
}
export default function SwitchStatus({status,onUpdate,_id,...props}:propsType) : React.JSX.Element {
    return (
        <Switch
        checked={status === "ACTIVE"}
        onChange={(checked) => onUpdate({_id,status : checked ? "ACTIVE" : "INACTIVE"})}
        {...props}
      />
    )
}