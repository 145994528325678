import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Row } from "antd";
import { differenceBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BtnAuth from "~/components/common/BtnAuthor/BtnAuth";
import POLICIES, { POLICIES_NAME } from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import { useFetchState } from "~/utils/helpers";
import apis from "../clientSubscribe.api";
import { useUpdateClientSubscribe } from "../clientSubscribe.hook";
import { CouponAssignToClientSub } from "../clientSubscribe.modal";
import TableCoupons from "./TableCoupons";
import TableCouponsSelect from "./TableCouponsSelect";
type propsType = {
  id?: any;
  onCancel : () => void
};
export default function ClientSubCoupons({ id,onCancel }: propsType): React.JSX.Element {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_PROMOTION);    
    const [isSubmitLoading,onUpdate] = useUpdateClientSubscribe(onCancel);
    const [couponsInit, loading] = useFetchState({
        api: apis.getCoupons,
        query: id,
        useDocs: false,
    });
    const [couponsReadyToSelect, loadingCouponsReadyToSelect] = useFetchState({
        api: apis.getCouponsReady,
        query: id,
        useDocs: false,
    });
    const [coupons, setCoupons] = useState<CouponAssignToClientSub[]>([]);
    
    useEffect(() => {
        setCoupons(couponsInit);
    }, [couponsInit]);
    
    const uniqCouponsReady : any = useMemo(() => differenceBy(couponsReadyToSelect,coupons,'_id'),[couponsReadyToSelect,coupons]);
    const onSave = useCallback(() => {
        onUpdate({_id : id,coupons})
    },[id,coupons])
  
  return (
    <div>
        <h5>Quản lý mã giảm giá</h5>
      <Row justify={"space-between"}>
        <Col span={11}>
          <TableCoupons
            onRemove={(_id: any) =>
              setCoupons(coupons?.filter((cp) => cp?._id !== _id))
            }
            dataSource={coupons}
            loading={loading}
            canUpdate={canUpdate}
          />
        </Col>
        <Col span={11}>
          <TableCouponsSelect
            onAdd={(newCp: CouponAssignToClientSub) =>
              setCoupons([...coupons, newCp])
            }
            dataSource={uniqCouponsReady}
            loading={loadingCouponsReadyToSelect}
            canUpdate={canUpdate}
          />
        </Col>
      </Row>
      <Divider />
      <Flex gap={10} justify={'center'}>
      <Button  onClick={onCancel}>Huỷ</Button>
      <BtnAuth permissionName={POLICIES_NAME.promotion} isMatch={canUpdate} type="update">
        <Button disabled={!canUpdate} loading={isSubmitLoading} icon={<SaveOutlined />} type="primary"  onClick={onSave}>Lưu</Button>
      </BtnAuth>
      </Flex>
    </div>
  );
}
