import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import {
  useCreateTenantRole,
  useGetTenantRole,
  useUpdateTenantRole,
} from "../tenantRole.hook";
import { TenantRoleBase } from "../tenantRole.modal";
type propsType = {
  id?: any;
  onCancel?: () => void;
};
export default function TenantRoleForm({
  id,
  onCancel,
}: propsType): React.JSX.Element {
  const [form] = Form.useForm();
  const [tenantRole, isLoading] = useGetTenantRole(id);
  const [isSubmitLoading, onCreate] = useCreateTenantRole(onCancel);
  const [, onUpdate] = useUpdateTenantRole(onCancel);
  const onFinish = (values: TenantRoleBase) => {
    if (id) {
      onUpdate({
        ...values,
        _id: id,
      });
    } else {
      onCreate(values);
    }
  };
  useEffect(() => {
    if (id && tenantRole) {
      form.setFieldsValue({ ...tenantRole });
    }
  }, [id, tenantRole]);
  return (
    <Form form={form} onFinish={onFinish} layout="inline">
      <Form.Item name={"name"} label="Tên">
        <Input style={{ width: 300 }} />
      </Form.Item>
      <WithPermission permission={id ? POLICIES.UPDATE_TENANTROLE : POLICIES.WRITE_TENANTROLE}>
      <Button loading={isSubmitLoading} type="primary" htmlType="submit">
        {id ? "Cập nhật" : "Tạo mới"}
      </Button>
      </WithPermission>
    </Form>
  );
}
