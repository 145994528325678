import { Button, Checkbox, Col, Flex, Form, Input, Radio, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { get } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "~/components/common/Loading/index";
import UploadImage from "~/components/common/Upload/UploadImage";
import { requireRulesCustom } from "~/constants/defaultValue";
import NewsCategorySelect from "~/modules/newsCategory/components/NewsCategorySelect";
import {
  NEWS_SECURITY,
  NEWS_SECURITY_VI,
} from "~/modules/newsCategory/constants";
import Editors from "~/utils/Editors";
import { ObjOrString } from "~/utils/helpers";
import { useFailedAnt, useLanguage } from "~/utils/hook";
import { useCreateNews, useGetNews, useUpdateNews } from "../news.hook";
type propsType = {
};
export default function NewsForm({
}: propsType): React.JSX.Element {
  const navigate = useNavigate(); 
  const {id} = useParams();
  const [form] = Form.useForm();
  const [data, loading] = useGetNews(id);
  const { ErrorComponent, onFinishFailed, setErrors } = useFailedAnt();
  const { LangFormItem, LanguageSwitchLang, language, setLanguage } =
    useLanguage();
  const callBackSubmit = useCallback(() => navigate(-1),[])
  const [isSubmitLoading, onCreate] = useCreateNews(callBackSubmit);
  const [, onUpdate] = useUpdateNews(callBackSubmit);
  const onFinish = (values: any) => {
    setErrors([]);
    if (id) {
      onUpdate({ _id: id, ...values });
    } else {
      onCreate({ ...values });
    }
  };

  useEffect(() => {
    if (data && id) {
      form.setFieldsValue({
        ...data,
      });
    }
  }, [id, data]);

  return (
    <Form
      initialValues={{
        security: NEWS_SECURITY.PUBLISHED,
        status: NEWS_SECURITY.PUBLISHED,
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 4 }}
      labelAlign="left"
    >
      <h4>{id ? `Cập nhật ${ObjOrString(get(data,'title'))}` : "Tạo mới tin tức"}</h4>
      <Loading loading={loading}/>
      <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"} />
      <LanguageSwitchLang language={language} setLanguage={setLanguage} />
      <Row justify={"space-between"}>
        <Col span={20}>
          <Form.Item labelCol={{ span: 5 }} label="Trạng thái" name="status">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={NEWS_SECURITY.EDITING}>
                {NEWS_SECURITY_VI.EDITING}
              </Radio.Button>
              <Radio.Button value={NEWS_SECURITY.PUBLISHED}>
                {NEWS_SECURITY_VI.PUBLISHED}
              </Radio.Button>
              <Radio.Button value={NEWS_SECURITY.PRIVATE}>
                {NEWS_SECURITY_VI.PRIVATE}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Nổi bật"
            name="isFeatured"
            valuePropName="checked"
            labelCol={{ span: 5 }}
          >
            <Checkbox />
          </Form.Item>

        </Col>
        <Col span={4}>
          <Flex justify={"end"}>
            <Form.Item shouldUpdate={(p,n) => p?.coverPhoto !== n?.coverPhoto} noStyle>
              {({setFieldsValue,getFieldValue}) => <Form.Item name={"coverPhoto"}>
              <UploadImage
                onChange={(url: string) =>
                  setFieldsValue({ coverPhoto: url })
                }
                imgUrl={getFieldValue("coverPhoto")}
              />
            </Form.Item>}
            </Form.Item>
          </Flex>
        </Col>
      </Row>

      <LangFormItem
            label="Tiêu đề"
            fieldName="title"
            language={language}
            rules={requireRulesCustom("Vui lòng nhập tiêu đề")}
          >
            <TextArea />
          </LangFormItem>

          <Form.Item
            label="Đường dẫn"
            name="slug"
          >
            <Input readOnly/>
          </Form.Item>

          <LangFormItem
            label="Mô tả ngắn"
            fieldName="description"
            language={language}
          >
            <TextArea />
          </LangFormItem>

      <Form.Item
        label="Danh mục"
        name="category"
        rules={[
          {
            required: true,
            message: "Xin vui lòng chọn danh mục tin tức!",
          },
        ]}
      >
        <NewsCategorySelect mode="multiple"/>
      </Form.Item>

      <LangFormItem
        label="Nội dung"
        fieldName="content"
        language={language}
      >
        <Editors />
      </LangFormItem>

      <LangFormItem
        label="Meta title"
        fieldName="metaTitle"
        language={language}
      >
        <Input />
      </LangFormItem>
      <LangFormItem
        label="Meta description"
        fieldName="metaDescription"
        language={language}
      >
        <TextArea />
      </LangFormItem>
      <LangFormItem
        label="Meta keywords"
        fieldName="metaKeywords"
        language={language}
      >
        <Input />
      </LangFormItem>
      <Flex justify={"center"}>
        <Button loading={isSubmitLoading} htmlType="submit" type="primary">
          {id ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Flex>
    </Form>
  );
}
