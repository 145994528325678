import PotentialSellerScreen from "./screens/PotentialSeller";
import potentialSellerApi from "./potentialSeller.api";
import * as potentialSellerHook from "./potentialSeller.hook";
import * as potentialSellerService from "./potentialSeller.service";
import * as potentialSellerModels from "./potentialSeller.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : PotentialSellerScreen,
    },
    api : potentialSellerApi,
    hook : potentialSellerHook,
    service : potentialSellerService,
    model : potentialSellerModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;