import { Button, Flex, Form, Input } from "antd";
import React, { useEffect } from "react";
import Loading from "~/components/common/Loading/index";
import { requireRules } from "~/constants/defaultValue";
import SaleChannelSelect from "~/modules/saleChannel/components/SaleChannelSelect";
import { validatePhoneNumberAntd } from "~/utils/validate";
import {
  useCreatePotentialSeller,
  useGetPotentialSeller,
  useUpdatePotentialSeller,
} from "../potentialSeller.hook";
type propsType = {
  onCancel: (p?: any) => void;
  id?: any;
};
export default function PotentialSellerForm({
  onCancel,
  id,
}: propsType): React.JSX.Element {
  const [form] = Form.useForm();
  const [data,loading] = useGetPotentialSeller(id);
  const [isSubmitLoading, onCreate] = useCreatePotentialSeller(onCancel);
  const [, onUpdate] = useUpdatePotentialSeller(onCancel);
  const onFinish = (values: any) => {
    if (id) {
      onUpdate({
        _id: id,
        ...values,
      });
    } else {
      onCreate({
        ...values,
      });
    }
  };
  useEffect(() => {
    if(id && data){
        form.setFieldsValue({
            ...data
        })
    }
  },[data,id])
  return (
    <Form
      labelCol={{ span: 6 }}
      labelAlign="left"
      form={form}
      onFinish={onFinish}
    >
        <Loading loading={loading}/>
      <Form.Item rules={requireRules} label="Họ và tên" name={"fullName"}>
        <Input />
      </Form.Item>
      <Form.Item
        rules={[...requireRules, ...validatePhoneNumberAntd]}
        label="Số điện thoại"
        name={"phoneNumber"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          ...requireRules,
          {
            type: "email",
            message: "Email bạn nhập không đúng định dạng!",
          },
        ]}
        label="Email"
        name={"email"}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Số giấy phép kinh doanh" name={"businessLicenseNumber"}>
        <Input />
      </Form.Item>
      <Form.Item label="Số giấy phép hoạt động" name={"operatingLicenseNumber"}>
        <Input />
      </Form.Item>
      <Form.Item rules={requireRules} label="Kênh bán hàng" name={"salesChannelId"}>
        <SaleChannelSelect />
      </Form.Item>
      <Flex justify={"center"}>
        <Button loading={isSubmitLoading} htmlType="submit" type="primary">
          {id ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Flex>
    </Form>
  );
}
