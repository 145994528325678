import { createSlice } from "@reduxjs/toolkit";
import { compact, get } from "lodash";
import { ACTIONS, CORE_ACTION_LOWER } from "~/modules/policy/policy.auth";
import { InstanceModuleRedux } from "~/redux/instanceModuleRedux";
import { initStateSlice } from "~/redux/models";
interface cloneInitState extends initStateSlice {
  // Add cloneInitState Type Here
   permissionConstructorBranch : any,
   loadingGetPermissionConstructorBranch : boolean,
   getPermissionConstructorBranchFailed : any,
 
   permissionBranch : any,
   permissionBranchPre : any,
   loadingGetPermissionBranch : boolean,
   getPermissionBranchFailed : any,
 
   accessPermissionBranchSuccess: any,
   accessPermissionBranchFailed: any,
 
   denyPermissionBranchSuccess: any,
   denyPermissionBranchFailed: any,
 
   updateApiKeySuccess?: any;
   updateApiKeyFailed?: any;
 
   listWarehouse?: any[];
   getListWarehouseFailed?: any;
   isLoadingWarehouse?: boolean;
 }
class BranchClassExtend extends InstanceModuleRedux {
  cloneReducer  ;
  cloneInitState : any;
  constructor() {
    super('branch');
    this.cloneReducer = {
      ...this.initReducer,
      getPermissionConstructorBranchRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionConstructorBranch = true;
        state.getPermissionConstructorBranchFailed = null;
      },
      getPermissionConstructorBranchSuccess: (state:cloneInitState , { payload }: any) => {
        state.permissionConstructorBranch = payload;
        state.loadingGetPermissionConstructorBranch = false;
      },
      getPermissionConstructorBranchFailed: (state:cloneInitState , { payload }: any) => {
        state.getPermissionConstructorBranchFailed = payload;
        state.loadingGetPermissionConstructorBranch = false;
      },
      getPermissionBranchRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionBranch = true;
        state.getPermissionBranchFailed = null;
      },
      getPermissionBranchSuccess: (state:cloneInitState , { payload }: any) => {
        state.permissionBranch = payload;
        state.loadingGetPermissionBranch = false;
      },
      getPermissionBranchFailed: (state:cloneInitState , { payload }: any) => {
        state.getPermissionBranchFailed = payload;
        state.loadingGetPermissionBranch = false;
      },
      accessPermissionBranchRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionBranch = true;
        state.accessPermissionBranchFailed = null;
        const clonePermissionBranch = {...state.permissionBranch};
        // Action Change State Request
      const {action,resource} = payload;
      let newResource = [];
      if(action === CORE_ACTION_LOWER.admin){
        newResource = compact([...get(clonePermissionBranch,resource,[]),...ACTIONS]);
      }else{
        newResource = [...get(clonePermissionBranch,resource,[]),action];
      };
      state.permissionBranch = {
        ...clonePermissionBranch,
        [resource] : newResource
      }
      },
      accessPermissionBranchSuccess: (state:cloneInitState , { payload }: any) => {
          // Action Change Pre State === State
        state.permissionBranchPre = {...state.permissionBranch};
        state.loadingGetPermissionBranch = false;
      },
      accessPermissionBranchFailed: (state:cloneInitState , { payload }: any) => {
        state.accessPermissionBranchFailed = payload;
        // Roll back After Failed
        state.permissionBranch = {...state.permissionBranchPre}
        state.loadingGetPermissionBranch = false;
      },
      denyPermissionBranchRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetPermissionBranch = true;
        state.denyPermissionBranchFailed = null;
        const clonePermissionBranch = {...state.permissionBranch};
        // Action Change State Request
      const {action : actionDeny,resource : resourceDeny} = payload;
      let newResourceDeny = [];
      if(actionDeny !== CORE_ACTION_LOWER.admin){
        newResourceDeny = get(clonePermissionBranch,resourceDeny,[])?.filter((act : any) => act !== actionDeny)
      }
      state.permissionBranch = {
        ...clonePermissionBranch,
        [resourceDeny] : newResourceDeny
      }
      return;
      },
      denyPermissionBranchSuccess: (state:cloneInitState , { payload }: any) => {
        state.permissionBranchPre = {...state.permissionBranch};
        state.loadingGetPermissionBranch = false;
      },
      denyPermissionBranchFailed: (state:cloneInitState , { payload }: any) => {
        state.denyPermissionBranchFailed = payload;
        state.permissionBranch = {...state.permissionBranchPre}
        state.loadingGetPermissionBranch = false;
      },
      updateApiKeyRequest: (state: cloneInitState, { payload }: any) => {
        state.isSubmitLoading = true;
       },
      updateApiKeySuccess: (state: cloneInitState, { payload }: any) => {
        state.isSubmitLoading = false;
        state.updateApiKeySuccess = payload;
        state.list = state.list?.map((item: any) => get(item, '_id') === get(payload, 'data._id') ? payload?.data : item);
      },
      updateApiKeyFailed: (state: cloneInitState, { payload }: any) => {
        state.isSubmitLoading = false;
        state.updateApiKeyFailed = payload;
      },

      getListWarehouseRequest: (state: cloneInitState) => {
        state.isLoadingWarehouse = true;
      },

      getListWarehouseSuccess: (state: cloneInitState, { payload }: any) => {
        state.isLoadingWarehouse = false;
        state.listWarehouse = payload;
      },
      getListWarehouseFailed: (state: cloneInitState, { payload }: any) => {
        state.isLoadingWarehouse = false;
        state.getListWarehouseFailed = payload;
      },

    }
    this.cloneInitState = {
      ...this.initialState,
      permissionConstructorBranch: null,
      loadingGetPermissionConstructorBranch: false,
      getPermissionConstructorBranchFailed: null,
      permissionBranch: null,
      permissionBranchPre: null, // To Roll Back
      loadingGetPermissionBranch: false,
      getPermissionBranchFailed: null,
      accessPermissionBranchSuccess: null,
      accessPermissionBranchFailed: null,
      denyPermissionBranchSuccess: null,
      denyPermissionBranchFailed: null,
      updateApiKeyFailed: undefined,
      updateApiKeySuccess: undefined,
      listWarehouse: [],
      isLoadingWarehouse: false,
      getListWarehouseFailed: undefined,
      // Want Add more State Here...
    };
  }
  createSlice() {
    return createSlice({
      name: this.module,
      initialState: this.cloneInitState,
      reducers:  this.cloneReducer,
    });
  }
  
}

const branchSlice = new BranchClassExtend();
const data = branchSlice.createSlice();


export const branchSliceAction  = data.actions;
export default data.reducer;