import { Tag} from "antd";
import { ColumnsType } from "antd/es/table/InternalTable";
import { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import SelectSearch from "~/components/common/SelectSearch/SelectSearch";
import WhiteBox from "~/components/common/WhiteBox";
import WithPermission from "~/components/common/WithPermission";
import useTranslate from "~/lib/translation";
import POLICIES from "~/modules/policy/policy.auth";
import { concatAddress } from "~/utils/helpers";
import { useChangeDocumentTitle } from "~/utils/hook";
import { useBranchQueryParams, useGetBranches,useDeleteBranch, useBranchPaging,useUpdateBranchParams } from "../branch.hook";
import Action from "../components/Action";
import BranchForm from "../components/BranchForm";
import useBranchContext from "../store/BranchContext";
import StatusTagWarehouse from "../components/StatsusTagWarehouse";
import { STATUS_LINK_WAREHOUSE_EN } from "../constants";

export default function Branch() {

  useChangeDocumentTitle("Danh sách chi nhánh");
  const {
    // branches,
    canDeleteWarehouse,
    canUpdateWarehouse,
    getListWarehouse,
    canUpdateBranch,
    canDeleteBranch,
  } = useBranchContext();

  const {id : tenantId} = useParams();
  const [isOpen,setIsOpen] = useState(false);
  const [id,setId] = useState<any>();
  const { t }: any = useTranslate();
  const [query] = useBranchQueryParams(tenantId);
  const [keyword, { setKeyword, onParamChange }] = useUpdateBranchParams(query);
  const [data, isLoading] = useGetBranches(query);
  const [isSubmitLoading,onDelete] = useDeleteBranch();
  const paging = useBranchPaging();
  
  const onOpen = useCallback((_id?:any) => {
    _id && setId(_id);
    setIsOpen(true);
  },[]);
  const onClose = useCallback(() => {
    setId(null);
    setIsOpen(false);
  },[]);
  const columns : ColumnsType = [
    {
      title : "Tên chi nhánh",
      dataIndex : "name",
      key : "name",
      render : (name,rc) => <Link to={`/branch-detail/${rc?._id}/info`}>{name}</Link>
    },
    // {
    //   title : "Email",
    //   dataIndex : "email",
    //   key : "email",
    // },
    {
      title : "Địa chỉ",
      dataIndex : "address",
      key : "address",
      render(value, record, index) {
        return concatAddress(value)
      },
    },
    {
      title: "Trạng thái liên kết kho",
      key: "statusLinkWarehouse",
      align: "center",
      width: 180,
      render: (value, record) => {
        const id = record?._id;
        return <StatusTagWarehouse status={getListWarehouse(id)?.length ? STATUS_LINK_WAREHOUSE_EN.LINKED : STATUS_LINK_WAREHOUSE_EN.NOT_LINKED } />
      },
    },
    {
      title: "Các kho đã liên kết",
      key: "listWarehouse",
      align: "center",
      width: 180,
      render: (value, record) => {
        const id = record?._id;
        return getListWarehouse(id)?.map((item: any) => (
          <Tag>{item?.name?.vi}</Tag>
        ))
      },
    },
    ...((canDeleteWarehouse || canUpdateWarehouse || canDeleteBranch || canUpdateBranch )
      ? [
          {
            title: "Thao tác",
            key: "action",
            align: "center" as any,
            width: 150,
            render(value: any, record: any, index: any) {
              return <Action id={record?._id} onDelete = {onDelete} onOpen = {onOpen} />;
            },
          },
        ]
      : []),
  ]
  return (
    <>
      <Breadcrumb title={t("Danh sách chi nhánh")} />
      <WhiteBox>
        <SelectSearch
        showSelect = {false}
        isShowButtonAdd
        handleOnClickButton={() => onOpen()}
        permissionKey={[POLICIES.WRITE_BRANCH]}
        onSearch={(e: any) => onParamChange({ keyword: e })}
        />
        <TableAnt
          dataSource={data}
          loading={isLoading}
          rowKey={(rc) => rc?._id}
          columns={columns}
          size='small'
          pagination={{
            ...paging,
            onChange(page, pageSize) {
              onParamChange({ page, limit: pageSize });
            },
            showTotal: (total) => `Tổng cộng: ${total}`,
          }}
          stickyTop
        />
      </WhiteBox>
      <ModalAnt width={1200} open={isOpen} destroyOnClose footer={null} onCancel={onClose}>
        <BranchForm id={id} onCancel={onClose}/>
      </ModalAnt>
    </>
  );
}
