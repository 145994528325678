export const POTENTIAL_SELLER_REFCOLLECTION = {
    pharma_profile : "pharma_profile",
    partner : "partner",
    visitor : "visitor",
}
export const POTENTIAL_SELLER_REFCOLLECTION_VI = {
    pharma_profile : "B2B",
    partner : "B2C",
    visitor : "Visitor",
}
export const POTENTIAL_SELLER_STATUS = {
    NEW : "NEW",
    CONVERTED : "CONVERTED",
    CANCELLED : "CANCELLED",
}
export const POTENTIAL_SELLER_STATUS_VI = {
    NEW : "Mới",
    CONVERTED : "Đã chuyển đổi",
    CANCELLED : "Đã huỷ",
}