import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../potentialSeller.api'; 
import { potentialSellerActions } from './reducer';

function* getListPotentialSeller({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(potentialSellerActions.getListSuccess(data));
    yield put(potentialSellerActions.clearAction());
  } catch (error:any) {
    yield put(potentialSellerActions.getListFailed(error));
  }
}

function* getByIdPotentialSeller({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(potentialSellerActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(potentialSellerActions.getByIdFailed(error));
  }
}

function* createPotentialSeller({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(potentialSellerActions.createSuccess(data));
    yield delay(300);
    yield put(potentialSellerActions.resetAction());
  } catch (error:any) {
    yield put(potentialSellerActions.createFailed(error));
  }
}

function* updatePotentialSeller({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(potentialSellerActions.updateSuccess(data));
    yield delay(300);
    yield put(potentialSellerActions.resetAction());
  } catch (error:any) {
    yield put(potentialSellerActions.updateFailed(error));
  }
}
function* deletePotentialSeller({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(potentialSellerActions.deleteSuccess(data));
    yield delay(300);
    yield put(potentialSellerActions.resetAction());
  } catch (error:any) {
    yield put(potentialSellerActions.deleteFailed(error));
  }
}

function* convertPotentialSeller({payload} : any) : any {
  try {
    const data = yield call(api.convert,payload);
    yield put(potentialSellerActions.convertSuccess(data));
    yield delay(300);
    yield put(potentialSellerActions.resetAction());
  } catch (error:any) {
    yield put(potentialSellerActions.convertFailed(error));
  }
}
export default function* potentialSellerSaga() {
  yield takeLatest(potentialSellerActions.getListRequest, getListPotentialSeller);
  yield takeLatest(potentialSellerActions.getByIdRequest, getByIdPotentialSeller);
  yield takeLatest(potentialSellerActions.createRequest, createPotentialSeller);
  yield takeLatest(potentialSellerActions.updateRequest, updatePotentialSeller);
  yield takeLatest(potentialSellerActions.deleteRequest, deletePotentialSeller);
  yield takeLatest(potentialSellerActions.convertRequest, convertPotentialSeller);
}
