import SectionScreen from "./screens/Section";
import sectionApi from "./section.api";
import * as sectionHook from "./section.hook";
import * as sectionService from "./section.service";
import * as sectionModels from "./section.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : SectionScreen,
    },
    api : sectionApi,
    hook : sectionHook,
    service : sectionService,
    model : sectionModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;