import {  MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Popconfirm, Typography } from "antd";
import React from "react";
import useBranchContext from "../store/BranchContext";

type propsType = {
  isSubmitLoading?: boolean;
  title?: string;
  id: string | null;
  onDelete: (id?: string | null) => void;
  onOpen: (id?: string | null) => void;
};
export default function Action({id,onDelete,onOpen}: propsType): React.JSX.Element {
  const {
    openFormApiKey,
    deleteWarehouseLink,
    openFormDeleteWarehouseLinked,
    canUpdateWarehouse,
    canDeleteWarehouse,
    isSubmitLoading,
    canUpdateBranch,
    canDeleteBranch
  } = useBranchContext();
  const items: MenuProps["items"] = [
    ...(canUpdateWarehouse ?[{
      label: (
        <Button
          onClick={() => openFormApiKey(id)}
          type="primary"
          size="small"
          style={{ width: "100%" }}
          loading={isSubmitLoading}
        >
          Liên kết kho
        </Button>
      ),
      key: "1",
    }]:[]),
    ...(canDeleteWarehouse ?[{
      label: (
        <Button
          style={{ marginRight: "10px", width: "100%" }}
          danger
          type="primary"
          onClick={() => openFormDeleteWarehouseLinked(id)}
          size="small"
          loading={isSubmitLoading}
        >
          {" "}
          Xoá mã liên kết{" "}
        </Button>
      ),
      key: "2",
    }] : []),
    ...(canUpdateBranch ?[{
      label: (
        <Button
          type="primary" size="small" onClick={() => onOpen(id)}
          style={{ width: "100%" }}
        >Cập nhật</Button>
      ),
      key: '3'
    }]: []),
    ...(canDeleteBranch ?[{
      label: (
        <Popconfirm onConfirm={() => onDelete(id)} title="Xác nhận xoá">
          <Button
          style={{ width: "100%" }}
            type="primary"
            danger
            size="small"
          >Xoá</Button>
        </Popconfirm>
      ),
      key: "4",
    }] : []),
  ];

  return (
    <Dropdown
      placement="bottomLeft"
      menu={{
        items,
      }}
      trigger={["click"]}
    >
      <Button type="primary" shape="circle" icon={<MoreOutlined />}></Button>
    </Dropdown>
  );
}
