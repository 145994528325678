import CategoryParentScreen from "./screens/CategoryParent";
import categoryParentApi from "./categoryParent.api";
import * as categoryParentHook from "./categoryParent.hook";
import * as categoryParentService from "./categoryParent.service";
import * as categoryParentModels from "./categoryParent.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : CategoryParentScreen,
    },
    api : categoryParentApi,
    hook : categoryParentHook,
    service : categoryParentService,
    model : categoryParentModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;