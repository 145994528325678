import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import Breadcrumb from "~/components/common/Breadcrumb";
import WhiteBox from "~/components/common/WhiteBox";
import WithPermissionWithNotification from "~/components/common/WithPermissionWithNotification";
import PermissionConstructor from "~/modules/branch/components/PermissionConstructor";
import Branch from "~/modules/branch/screens/Branch";
import POLICIES from "~/modules/policy/policy.auth";
import CustomerDivisionResource from "~/pages/Dashboard/CustomerDivisionResource/index";
import ResourceConstructor from "../components/ResourceConstructor";
import TenantForm from "../components/TenantForm";

export default function TenantDetail() {
  const [currentTab, setCurrentTab] = useState("info");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  
  const onChange = (key: any) => {
    setCurrentTab(key);
    navigate(`/tenant-detail/${id}/${key}`);
  };

  useEffect(() => {
    switch (true) {
        case pathname.includes('info'):
            setCurrentTab('info')
            break;
        case pathname.includes('resourceConstructor'):
            setCurrentTab('resourceConstructor')
            break;
        case pathname.includes('branches'):
            setCurrentTab('branches')
            break;
        case pathname.includes('customerDivision'):
            setCurrentTab('customerDivision')
            break;
        case pathname.includes('permissionEmployee'):
            setCurrentTab('permissionEmployee')
            break;
    
        default:
            break;
    }
  },[pathname]);

  return (
    <WhiteBox>
        <Breadcrumb
          title="Thông tin đối tác"
        />
      <Tabs
        activeKey={currentTab}
        onChange={(key) => onChange(key)}
        defaultActiveKey={pathname}
        destroyInactiveTabPane
      >
        <TabPane tab="Thông tin" key="info">
          <TenantForm id={id} />
        </TabPane>
        <TabPane tab="Chức năng" key="resourceConstructor">
          <WithPermissionWithNotification permission={POLICIES.READ_RESOURCETENANT}>
          <ResourceConstructor type="Tenant"/>
          </WithPermissionWithNotification>
        </TabPane>
        <TabPane tab="Chi nhánh" key="branches">
          <WithPermissionWithNotification permission={POLICIES.READ_BRANCH}>
          <Branch />
          </WithPermissionWithNotification>
        </TabPane>
        <TabPane tab="Hệ khách hàng" key="customerDivision">
          <CustomerDivisionResource />
        </TabPane>
        <TabPane tab="Phân quyền TDV" key="permissionEmployee">
        <WithPermissionWithNotification permission={POLICIES.READ_RESOURCEEMPLOYEE}>
          <PermissionConstructor type="Employee" />
        </WithPermissionWithNotification>
        </TabPane>
      </Tabs>
    </WhiteBox>
  );
}
