import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../wpPolicy.api'; 
import { wpPolicyActions } from './reducer';

function* getListWpPolicy({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(wpPolicyActions.getListSuccess(data));
    yield put(wpPolicyActions.clearAction());
  } catch (error:any) {
    yield put(wpPolicyActions.getListFailed(error));
  }
}

function* getByIdWpPolicy({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(wpPolicyActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(wpPolicyActions.getByIdFailed(error));
  }
}

function* createWpPolicy({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(wpPolicyActions.createSuccess(data));
    yield delay(300);
    yield put(wpPolicyActions.resetAction());
  } catch (error:any) {
    yield put(wpPolicyActions.createFailed(error));
  }
}

function* updateWpPolicy({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(wpPolicyActions.updateSuccess(data));
    yield delay(300);
    yield put(wpPolicyActions.resetAction());
  } catch (error:any) {
    yield put(wpPolicyActions.updateFailed(error));
  }
}
function* deleteWpPolicy({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(wpPolicyActions.deleteSuccess(data));
    yield delay(300);
    yield put(wpPolicyActions.resetAction());
  } catch (error:any) {
    yield put(wpPolicyActions.deleteFailed(error));
  }
}


export default function* wpPolicySaga() {
  yield takeLatest(wpPolicyActions.getListRequest, getListWpPolicy);
  yield takeLatest(wpPolicyActions.getByIdRequest, getByIdWpPolicy);
  yield takeLatest(wpPolicyActions.createRequest, createWpPolicy);
  yield takeLatest(wpPolicyActions.updateRequest, updateWpPolicy);
  yield takeLatest(wpPolicyActions.deleteRequest, deleteWpPolicy);
}
