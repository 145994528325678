import { EnvironmentOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { get } from 'lodash';
import React from 'react';
import WhiteBox from '~/components/common/WhiteBox';
import { concatAddress } from '~/utils/helpers';
import { useGetBranch } from '../branch.hook';
type propsType = {
    id? : string
}
export default function BranchInfo({id}:propsType) : React.JSX.Element {
    const [branch,loading] = useGetBranch(id);
    
    return (
        <div className='info--container' style={{padding : '20px 50px'}}>
            <WhiteBox>
                <Flex vertical>
                <Typography.Text className='info--name'  strong>{get(branch,'name','')}</Typography.Text>
                <Typography.Text className='info--detail' type='secondary'><EnvironmentOutlined /> {concatAddress(get(branch,'address',''))}</Typography.Text>
                <Typography.Text className='info--detail' type='secondary'><MailOutlined /> {get(branch,'email','')}</Typography.Text>
                <Typography.Text className='info--detail' type='secondary'><PhoneOutlined /> {get(branch,'phoneNumber','')}</Typography.Text>
                </Flex>
            </WhiteBox>
        </div>
    )
}