import { get } from "lodash";

export const NEWS_SECURITY = {
    PUBLISHED : "PUBLISHED",
    PRIVATE : "PRIVATE",
    EDITING : "EDITING",
};
export const NEWS_SECURITY_VI = {
    PUBLISHED : "Công khai",
    PRIVATE : "Nội bộ",
    EDITING : "Đang sửa",
};

export const NEWS_SECURITY_OPTIONS = Object.keys(NEWS_SECURITY_VI).map((k) => ({
    label : get(NEWS_SECURITY_VI,k),
    value : k
}))