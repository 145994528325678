import { Badge, Flex, Input, Typography } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "~/components/common/Layout/List/Detail/Header";
import Layout from "~/components/common/Layout/List/Detail/Layout";
import ListInDetailCommon from "~/components/common/Layout/List/Detail/ListInDetailCommon";
import { STATUS_COLOR, STATUS_NAMES } from "~/constants/defaultValue";
import { PATH_APP } from "~/routes/allPath";
import MainContentTab from "../components/MainContentTab";
import {
  useGetVisitor,
  useGetVisitors,
  useGetVisitor_onlyGet,
  useUpdateVisitorParams,
  useVisitorPaging,
  useVisitorQueryParams,
} from "../visitor.hook";
const CLONE_STATUS_NAMES: any = STATUS_NAMES;
const CLONE_STATUS_COLOR: any = STATUS_COLOR;
export default function VisitorDetail(): React.JSX.Element {
  const { id: visitorId }: any = useParams();
  useGetVisitor(visitorId);
  const [query] = useVisitorQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateVisitorParams(query);
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const onSearch = () => {
        onParamChange({keyword});
        onClose();
    }
  const [visitor]: any = useGetVisitor_onlyGet();
  

  return (
    <>
      <Layout
        HeaderLeft={
          <Header.HeaderLeft
            onChangeStatus={(status) => onParamChange({ status })}
            SearchProp={{
                openSearch : showDrawer,
                open,
                onClose,
                onSearch,
                querySearch : ['keyword'],
                SearchComponent : <Input
                placeholder="Nhập để tìm kiếm"
                allowClear
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            }}
          />
        }
        HeaderRight={
          <Header.HeaderRight
            path={PATH_APP.visitor.root}
            name={
              <Flex gap={10} align="center">
                <h4>
                  {get(visitor, "fullName", "") +
                    " - " +
                    get(visitor, "code", "")}
                </h4>
                <Typography.Text type="secondary"  style={{ fontSize: 14 , width : 140 }}>
                  <Badge
                    style={{ marginRight: 2 }}
                    status={CLONE_STATUS_COLOR[get(visitor, "status", "")]}
                  />
                  {CLONE_STATUS_NAMES[get(visitor, "status", "")]}
                </Typography.Text>
              </Flex>
            }
          />
        }
        MainContent={<MainContentTab />}
        List={
          <ListInDetailCommon
            fieldName="fullName"
            path={"/visitor-detail"}
            useGets={useGetVisitors}
            usePaging={useVisitorPaging}
            query={query}
            onParamChange={onParamChange}
            fieldCode="code"
            moduleName="visitor"
          />
        }
      />
    </>
  );
}
