import { Button, Col, Divider, Form, Input, Radio, Row, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "~/components/common/Breadcrumb";
import LangFormItem from "~/components/common/LangFormItem";
import RenderLoading from "~/components/common/RenderLoading";
import UploadImage from "~/components/common/Upload/UploadImage";
import { LANGUAGE } from "~/constants/defaultValue";
import FormListItem from "../components/FormListItem";
import { useGetUiByKey, useUpdateUiByKey } from "../ui.hook";
import { useFailedAnt } from "~/utils/hook";
import { get } from "lodash";

type propsType = {};
const key = "banner";
export default function Banner(props: propsType): React.JSX.Element {
  const [form] = Form.useForm();
  const [reFetch,setReFetch] = useState(false);
  const mutate = useCallback(() => setReFetch(!reFetch),[reFetch])
  const [data, loading] = useGetUiByKey({key,reFetch});
  const [language, setLanguage] = useState<"vi" | "en">(LANGUAGE.VI);
  const { onFinishFailed, ErrorComponent,setErrors } = useFailedAnt();
  const [submitLoading,onUpdate] = useUpdateUiByKey(mutate);
  const onFinish = (values: any) => {
    setErrors([]); // Clear Error When Finish Successfully
    onUpdate({key,...values})
    
  };

  useEffect(() => {
    form.setFieldsValue({
        ...get(data,['value',key])
    })
  },[data])
  return (
    <div>
      <Breadcrumb title={"Cấu hình banner"} />
      <Row justify="end" style={{ marginBottom: 20 }}>
        <Radio.Group
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <Radio.Button
            value={LANGUAGE.VI}
          >
            VI
          </Radio.Button>
          <Radio.Button
            value={LANGUAGE.EN}
          >
            EN
          </Radio.Button>
        </Radio.Group>
      </Row>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        labelAlign="left"
        labelCol={{ span: 4 }}
      >
        <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"}/>
        <Tabs
          tabBarExtraContent={
            <Button loading={submitLoading} htmlType="submit" type="primary">
              Cập nhật
            </Button>
          }
        >
          <Tabs.TabPane forceRender tab="Trang chủ" key={"HOMEPAGE"}>
            <Divider>Bên trái</Divider>
            <FormListItem
              form={form}
              language={language}
              module="HOMEPAGE"
              name="Trang chủ"
              loading={loading}
              side="left"
            />
            <Divider>Bên phải</Divider>
            <FormListItem
              form={form}
              language={language}
              module="HOMEPAGE"
              name="Trang chủ"
              loading={loading}
              side="right"
            />
          </Tabs.TabPane>
          <Tabs.TabPane forceRender tab="Xem tất cả sản phẩm" key={"ALL"}>
            <Divider>Bên trái</Divider>
            <FormListItem
              form={form}
              language={language}
              module="ALL"
              name="Xem tất cả sản phẩm"
              loading={loading}
              side="left"
            />
            <Divider>Bên phải</Divider>
            <FormListItem
              form={form}
              language={language}
              module="ALL"
              name="Xem tất cả sản phẩm"
              loading={loading}
              side="right"
            />
          </Tabs.TabPane>
          <Tabs.TabPane forceRender tab="Ưu đãi" key={"PROMOTION"}>
            <Divider>Bên trái</Divider>
            <FormListItem
              form={form}
              language={language}
              module="PROMOTION"
              name="Ưu đãi"
              loading={loading}
              side="left"
            />
            <Divider>Bên phải</Divider>
            <FormListItem
              form={form}
              language={language}
              module="PROMOTION"
              name="Ưu đãi"
              loading={loading}
              side="right"
            />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </div>
  );
}
