import ClientSubscribeScreen from "./screens/ClientSubscribe";
import clientSubscribeApi from "./clientSubscribe.api";
import * as clientSubscribeHook from "./clientSubscribe.hook";
import * as clientSubscribeService from "./clientSubscribe.service";
import * as clientSubscribeModels from "./clientSubscribe.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : ClientSubscribeScreen,
    },
    api : clientSubscribeApi,
    hook : clientSubscribeHook,
    service : clientSubscribeService,
    model : clientSubscribeModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;