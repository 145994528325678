import React from "react";
import SaleScreen from "../components/createBillScreen/SaleScreen";

type propsType = {};
export default function CreateBill(props: propsType): React.JSX.Element {

  return (
    <SaleScreen />
  );
}
