import { Flex, Input } from "antd";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ModalAnt from "~/components/Antd/ModalAnt";
import Header from "~/components/common/Layout/List/Detail/Header";
import Layout from "~/components/common/Layout/List/Detail/Layout";
import ListInDetailCommon from "~/components/common/Layout/List/Detail/ListInDetailCommon";
import POLICIES from "~/modules/policy/policy.auth";
import { PATH_APP } from "~/routes/allPath";
import { ObjOrString } from "~/utils/helpers";
import MainContentTab from "../components/MainContentTab";
import WpPolicyForm from "../components/WpPolicyForm";
import {
    useDeleteWpPolicy,
    useGetWpPolicy, useGetWpPolicys, useGetWpPolicy_onlyGet, useUpdateWpPolicyParams, useWpPolicyPaging, useWpPolicyQueryParams
} from "../wpPolicy.hook";
export default function WpPolicyDetail(): React.JSX.Element {
  const { id: wpPolicyId }: any = useParams();
  useGetWpPolicy(wpPolicyId);
  const [id, setId] = useState<any>();
  const [query] = useWpPolicyQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateWpPolicyParams(query);
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const onSearch = () => {
        onParamChange({keyword});
        onClose();
    }
  const [wpPolicy]: any = useGetWpPolicy_onlyGet();
  
  const [isOpenForm, setIsOpenForm] = useState(false);
  const onCloseForm = useCallback(() => {
    setIsOpenForm(false);
    setId(null);
  }, []);
  const onOpenForm = useCallback((idd?: any) => {
    setIsOpenForm(true);
    idd && setId(idd);
  }, []);
  const [, deleteWpPolicy] = useDeleteWpPolicy();

  return (
    <>
      <Layout
        HeaderLeft={
          <Header.HeaderLeft
            PERMISSION_WRITE={POLICIES.WRITE_POLICY}
            onChangeStatus={(status) => onParamChange({ status })}
            onAdd={() => onOpenForm()}
            SearchProp={{
                openSearch : showDrawer,
                open,
                onClose,
                onSearch,
                querySearch : ['keyword'],
                SearchComponent : <Input
                placeholder="Nhập để tìm kiếm"
                allowClear
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            }}
          />
        }
        HeaderRight={
          <Header.HeaderRight
            PERMISSION_UPDATE={POLICIES.UPDATE_POLICY}
            PERMISSION_DELETE={POLICIES.DELETE_POLICY}
            path={PATH_APP.policy.root}
            onDeleteClick={() => deleteWpPolicy(wpPolicyId)}
            onEditClick={() => onOpenForm(wpPolicyId)}
            name={
              <Flex gap={10} align="center">
                <h4>
                  {ObjOrString(get(wpPolicy, "title", ""))}
                </h4>
                {/* <Typography.Text type="secondary"  style={{ fontSize: 14 , width : 100 }}>
                  <Badge
                    style={{ marginRight: 2 }}
                    status={CLONE_STATUS_COLOR[get(wpPolicy, "status", "")]}
                  />
                  {CLONE_STATUS_NAMES[get(wpPolicy, "status", "")]}
                </Typography.Text> */}
              </Flex>
            }
          />
        }
        MainContent={<MainContentTab />}
        List={
          <ListInDetailCommon
            fieldName="title"
            path={"/policy-detail"}
            useGets={useGetWpPolicys}
            usePaging={useWpPolicyPaging}
            query={query}
            onParamChange={onParamChange}
            moduleName="wpPolicy"
          />
        }
      />
      <ModalAnt
        width={1100}
        open={isOpenForm}
        onCancel={onCloseForm}
        footer={[]}
        destroyOnClose
      >
        <WpPolicyForm
          onCancel={onCloseForm}
          id={id}
        />
      </ModalAnt>
    </>
  );
}
