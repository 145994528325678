import TenantScreen from "./screens/Tenant";
import tenantApi from "./tenant.api";
import * as tenantHook from "./tenant.hook";
import * as tenantService from "./tenant.service";
import * as tenantModels from "./tenant.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";
import TenantDetail from "./screens/TenantDetail";

const moduleExport = {
    page : {
        index : TenantScreen,
        detail : TenantDetail,
    },
    api : tenantApi,
    hook : tenantHook,
    service : tenantService,
    model : tenantModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;