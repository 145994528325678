import { put, call, takeLatest } from 'redux-saga/effects';
import api from '../news.api'; 
import { newsActions } from './reducer';

function* getListNews({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(newsActions.getListSuccess(data));
    yield put(newsActions.clearAction());
  } catch (error:any) {
    yield put(newsActions.getListFailed(error));
  }
}

function* getByIdNews({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(newsActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(newsActions.getByIdFailed(error));
  }
}

function* createNews({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(newsActions.createSuccess(data));
  } catch (error:any) {
    yield put(newsActions.createFailed(error));
  }
}

function* updateNews({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(newsActions.updateSuccess(data));
  } catch (error:any) {
    yield put(newsActions.updateFailed(error));
  }
}
function* deleteNews({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(newsActions.deleteSuccess(data));
  } catch (error:any) {
    yield put(newsActions.deleteFailed(error));
  }
}


export default function* newsSaga() {
  yield takeLatest(newsActions.getListRequest, getListNews);
  yield takeLatest(newsActions.getByIdRequest, getByIdNews);
  yield takeLatest(newsActions.createRequest, createNews);
  yield takeLatest(newsActions.updateRequest, updateNews);
  yield takeLatest(newsActions.deleteRequest, deleteNews);
}
