import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../newsCategory.api'; 
import { newsCategoryActions } from './reducer';

function* getListNewsCategory({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(newsCategoryActions.getListSuccess(data));
  
  } catch (error:any) {
    yield put(newsCategoryActions.getListFailed(error));
  }
}

function* getByIdNewsCategory({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(newsCategoryActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(newsCategoryActions.getByIdFailed(error));
  }
}

function* createNewsCategory({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(newsCategoryActions.createSuccess(data));
    yield delay(300);
    yield put(newsCategoryActions.clearAction());
  } catch (error:any) {
    yield put(newsCategoryActions.createFailed(error));
  }
}

function* updateNewsCategory({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(newsCategoryActions.updateSuccess(data));
    yield delay(300);
    yield put(newsCategoryActions.clearAction());
  } catch (error:any) {
    yield put(newsCategoryActions.updateFailed(error));
  }
}
function* deleteNewsCategory({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(newsCategoryActions.deleteSuccess(data));
    yield delay(300);
    yield put(newsCategoryActions.clearAction());
  } catch (error:any) {
    yield put(newsCategoryActions.deleteFailed(error));
  }
}


export default function* newsCategorySaga() {
  yield takeLatest(newsCategoryActions.getListRequest, getListNewsCategory);
  yield takeLatest(newsCategoryActions.getByIdRequest, getByIdNewsCategory);
  yield takeLatest(newsCategoryActions.createRequest, createNewsCategory);
  yield takeLatest(newsCategoryActions.updateRequest, updateNewsCategory);
  yield takeLatest(newsCategoryActions.deleteRequest, deleteNewsCategory);
}
