
import React from 'react';
import { useParams } from 'react-router-dom';
import MainContentTabCommon from '~/components/common/Layout/List/Detail/MainContentTabCommon';
import { useGetVisitor } from '../visitor.hook';
import BillTab from './BillTab';
import QuotationTab from './QuotationTab';
import VisitorView from './VisitorView';
type propsType = {

}
export default function MainContentTab(props:propsType) : React.JSX.Element {
    const {id} = useParams();
    useGetVisitor(id);
    return (
        <MainContentTabCommon items={[
            {
                key : '1',
                label : 'Thông tin',
                children : <VisitorView />
            },
            {
                key : '2',
                label : 'Đơn hàng tạm',
                children : <QuotationTab />
            },
            {
                key : '3',
                label : 'Đơn hàng',
                children : <BillTab />
            },
        ]}/>
    )
}