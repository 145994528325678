import { ClearOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Divider, Drawer, Flex, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import NewsCategorySelect from "~/modules/newsCategory/components/NewsCategorySelect";
type propsType = {
  onCloseSearch: () => void;
  onSearch: (submitData?:any) => void;
  openSearch: boolean;
  query : any
};
const options = [
  {
    value: "PUBLISHED",
    label: <span style={{ color: "green" }}>Công khai</span>,
  },
  {
    value: "PRIVATE",
    label: <span style={{ color: "blue" }}>Nội bộ</span>,
  },
  {
    value: "EDITING",
    label: <span style={{ color: "red" }}>Đang sửa</span>,
  },
];
 const optionsDateNews = [
    { label: 'Lọc theo ngày', value: 'date' },
    { label: 'Lọc theo tháng', value: 'month' },
    { label: 'Lọc theo quý', value: 'quarter' },
    { label: 'Lọc theo năm', value: 'year' },
  ];
export default function NewsSearchDraw({
  onCloseSearch,
  onSearch,
  openSearch,
  query
}: propsType): React.JSX.Element {
  const [modeDate,setModeDate] = useState<'date' | 'week' | 'month' | 'quarter' | 'year'>('date');
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    const {filterDate,category} = values;
    let filterDate_ = `${dayjs(filterDate).startOf(modeDate).format("DD-MM-YYYY")},${dayjs(filterDate).endOf(modeDate).format("DD-MM-YYYY")}`;
    const submitData = {
        ...values,
        category : category?.join(','),
        filterDate :filterDate && filterDate_
    };
    onSearch(submitData)
    onCloseSearch();
  };

  useEffect(() => {
    const {filterDate,category} = query;
    
    form.setFieldsValue({
        ...query,
        category : category?.split(','),
        filterDate : filterDate ? dayjs(filterDate?.split(',')?.[0],'DD-MM-YYYY') : null,
    })
    
  },[query]);
  return (
    <Drawer
      destroyOnClose = {false}
      rootClassName="drawerMidCenter"
      placement={"top"}
      closable={false}
      onClose={onCloseSearch}
      open={openSearch}
      key={"search"}
    >
      <Form labelAlign="left" labelCol={{span : 4}} form={form} onFinish={onFinish}>
        <Form.Item name={"keyword"} label="Tên bài viết">
          <Input allowClear/>
        </Form.Item>
        <Form.Item name={"isFeatured"} label="Nổi bật" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item name={"status"} label="Trạng thái">
          <Select allowClear options={options} />
        </Form.Item>
        <Form.Item name={"category"} label="Danh mục">
          <NewsCategorySelect allowClear mode="multiple"/>
        </Form.Item>
        <Divider>Lọc Theo thời gian</Divider>
        <Flex align={'center'} gap={10}>
        <Select value={modeDate} onChange={(value) => setModeDate(value)} options={optionsDateNews}/>: 
        <Form.Item style={{marginBottom : 'unset'}} name={"filterDate"}>
          <DatePicker picker={modeDate} style={{width : 300}}/>
        </Form.Item>
        </Flex>
      <Flex justify={"center"} gap={10} style={{ marginTop: 10 }}>
        <Button onClick={onCloseSearch}>Huỷ</Button>
        <Button type="dashed" icon={<ClearOutlined />} onClick={() => form.resetFields()}>Làm mới</Button>
        <Button type="primary" htmlType="submit">
          Tìm kiếm
        </Button>
      </Flex>
      </Form>
    </Drawer>
  );
}
