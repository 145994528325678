import { Avatar, Col, Flex, Row } from "antd";
import { get } from "lodash";
import React from "react";
import ViewItem from "~/components/common/ViewItem";
import { NEWS_SECURITY_VI } from "../constants";
import { useGetNewsCategory_onlyGet } from "../newsCategory.hook";
type propsType = {};

export default function NewsCategoryView(props: propsType): React.JSX.Element {
  const [data] = useGetNewsCategory_onlyGet();

  return (
    <Row justify={"space-between"}>
      <Col span={18}>
        <Flex vertical gap={10}>
          <ViewItem label="Tên danh mục" value={get(data, "name")} />
          <ViewItem label="Mô tả" value={get(data, "description")} />
          <ViewItem
            label="Hiển thị"
            value={get(NEWS_SECURITY_VI, get(data, "security"), "")}
          />
          <ViewItem label="Meta title" value={get(data, "metaTitle")} />
          <ViewItem
            label="Meta description"
            value={get(data, "metaDescription")}
          />
          <ViewItem label="Meta keywords" value={get(data, "metaKeywords")} />
        </Flex>
      </Col>
      <Col span={6}>
        <Avatar
          src={get(data, "photo")}
          style={{ width: "100%", height: "100%", aspectRatio: 1 }}
        />
      </Col>
    </Row>
  );
}
