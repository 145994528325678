import {
  Badge,
  Button, DatePicker,
  Flex,
  Form,
  Input, Radio, Row, Segmented
} from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import LangFormItem from "~/components/common/LangFormItem";
import Loading from "~/components/common/Loading/index";
import WithPermission from "~/components/common/WithPermission";
import { LANGUAGE, requireRules, requireRulesCustom, STATUS, STATUS_NAMES } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import { useFailedAnt } from "~/utils/hook";
import { SECTION_KEY } from "../constants";
import {
  useCreateSection,
  useGetSection,
  useUpdateSection
} from "../section.hook";
import SectionKeySelect from "./SectionKeySelect";
type propsType = {
  id?: any;
  onCancel?: any;
};
export default function SectionForm({
  id,
  onCancel,
}: propsType): React.JSX.Element {
  const [language,setLanguage] = useState<any>(LANGUAGE.VI);
  const { onFinishFailed, ErrorComponent, setErrors } = useFailedAnt();
  const [form] = Form.useForm();
  const [isSubmitLoading, createSection] = useCreateSection(onCancel);
  const [, updateSection] = useUpdateSection(onCancel);

  const [section, loading] = useGetSection(id);

  const onFinish = (values: any) => {
    setErrors([]);
    if (id) {
      updateSection({
        _id: id,
        ...values,
      });
    } else {
      createSection(values);
    }
  };

  useEffect(() => {
    if (section && id) {
      const {startTime,endTime} = get(section,'rangerTime',{});
      form.setFieldsValue({
        ...section,
        rangerTime : {
          startTime : startTime ? dayjs(startTime) : null,
          endTime : endTime ? dayjs(endTime) : null,
        }
      });
    }
  }, [id, section]);

  return (
    <Form
      onFinishFailed={onFinishFailed}
      form={form}
      onFinish={onFinish}
      initialValues={{
        sectionKey: SECTION_KEY.SECTION_HIGHLIGHT,
        status : STATUS.ACTIVE,
      }}
      labelAlign='left'
      labelCol={{
        span : 7
      }}
    >
      <Loading loading={loading} />
      <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"} />
      <Row justify="end" style={{ marginBlock: 20 }}>
        <Radio.Group
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <Radio.Button value={LANGUAGE.VI}>VI</Radio.Button>
          <Radio.Button value={LANGUAGE.EN}>EN</Radio.Button>
        </Radio.Group>
      </Row>
      <Form.Item name={"status"} label="Trạng thái">
        <Segmented
          options={[
            {
              label: STATUS_NAMES.ACTIVE,
              value: STATUS.ACTIVE,
              icon: <Badge status="success" />,
            },
            {
              label: STATUS_NAMES.INACTIVE,
              value: STATUS.INACTIVE,
              icon: <Badge status="default" />,
            },
          ]}
        />
      </Form.Item>
      <Form.Item name={"sectionKey"} label="Mẫu template">
        <SectionKeySelect />
      </Form.Item>
      <LangFormItem
        rules={requireRulesCustom("Vui lòng nhập tên section")}
        fieldName="title"
        label="Tên section"
        language={language}
      >
        <Input />
      </LangFormItem>
      <Form.Item
        shouldUpdate
        noStyle
      >
        {({getFieldValue}) => getFieldValue('sectionKey') === SECTION_KEY.SECTION_DEAL && (
          <>
            <Form.Item
              name={["rangerTime", "startTime"]}
              label="Thời gian bắt đầu"
              rules={[
                ...requireRules,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const endTime = getFieldValue([
                      "rangerTime",
                      "endTime",
                    ]);
                    
                    if (value > endTime) {
                      return Promise.reject("Phải bé hơn ngày kết thúc");
                    }
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <DatePicker showTime style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              name={["rangerTime", "endTime"]}
              label="Thời gian kết thúc"
              rules={[
                ...requireRules,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startTime = getFieldValue([
                      "rangerTime",
                      "startTime",
                    ]);
                    
                    if (value < startTime) {
                      return Promise.reject("Phải lớn hơn ngày kết thúc");
                    }
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <DatePicker showTime style={{ width: 200 }} />
            </Form.Item>
          </>
        )}
      </Form.Item>
     <Flex justify={'center'}>
     <WithPermission permission={id ? POLICIES.UPDATE_SECTIONPRODUCT : POLICIES.WRITE_SECTIONPRODUCT}>
     <Button loading={isSubmitLoading} htmlType="submit" type="primary">
        {id ? "Cập nhật" : "Tạo mới"}
      </Button>
     </WithPermission>
     </Flex>
    </Form>
  );
}
