import { Alert, Button, Flex, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useCreateBillStore from '~/modules/sale/bill/storeContext/CreateBillContext';
import { CouponInSelect } from '../coupon.modal';
import Coupon from './Coupon';
import { GiftTwoTone, LoadingOutlined } from '@ant-design/icons';
import { get, intersectionBy, some } from 'lodash';
import { formatter, getValueOfCoupons } from '~/utils/helpers';

type propsType = {

}
export default function SelectCoupon(props:propsType) : React.JSX.Element {
    const {coupons,onChangeCoupleSelect,loadingGetCoupon,couponSelected,onCloseCoupon,bill,totalAmount} = useCreateBillStore();
    
    const [selected,setSelected] = useState<CouponInSelect[]>([]);
    const totalCoupon = useMemo(() => getValueOfCoupons(totalAmount,selected?.filter((item) => item?.applyFor === "BILL")),[totalAmount,selected]);
    const couponByType = useMemo(() => coupons?.reduce((sum : {
      couponFreeShipSingle : CouponInSelect[],
      couponFreeShipMulti : CouponInSelect[],
      couponBillMulti : CouponInSelect[],
      couponBillSingle : CouponInSelect[]
    },cur : CouponInSelect) => {
        if(cur?.applyFor === "SHIP" && !cur?.multiple){
            sum.couponFreeShipSingle.push(cur);
        }
        if(cur?.applyFor === "SHIP" && cur?.multiple){
            sum.couponFreeShipMulti.push(cur);
        }
        if(cur?.applyFor === "BILL" && cur?.multiple){
            sum.couponBillMulti.push(cur);
        }
        if(cur?.applyFor === "BILL" && !cur?.multiple){
            sum.couponBillSingle.push(cur);
        }
        return sum;
    },{
      couponFreeShipSingle : [],
      couponFreeShipMulti : [],
      couponBillMulti : [],
      couponBillSingle : []
    }),[coupons]);

    const whereCouponIn = useMemo(() => {
      const inSingleBill = intersectionBy(selected,couponByType.couponBillSingle,(e : CouponInSelect) => e?._id)?.length > 0;
      const inMultiBill = intersectionBy(selected,couponByType.couponBillMulti,(e : CouponInSelect) => e?._id)?.length > 0;
      const inSingleFreeShip = intersectionBy(selected,couponByType.couponFreeShipSingle,(e : CouponInSelect) => e?._id)?.length > 0;
      const inMultiFreeShip = intersectionBy(selected,couponByType.couponFreeShipMulti,(e : CouponInSelect) => e?._id)?.length > 0;
      return {
        inSingleBill,
        inMultiBill,
        inSingleFreeShip,
        inMultiFreeShip,
      };
    },[selected,couponByType])
    const onAdd = (newCoupon:CouponInSelect) => {
        const listDiffApplyFor = selected?.filter((item) => item?.applyFor !== newCoupon?.applyFor);
        const listSameApplyFor = [...selected?.filter((item) => item?.applyFor === newCoupon?.applyFor),newCoupon];
        if(newCoupon?.multiple){
            setSelected([...listDiffApplyFor,...listSameApplyFor?.filter((item) => item?.multiple === newCoupon?.multiple)]);
        }else{
            setSelected([...listDiffApplyFor,newCoupon]);
        }
    };
    
    const onRemove = (removeId:string) => {
        setSelected(selected?.filter((item:any) => item._id !== removeId))
    };
    const onFinish = () => {
        const listApplyForBill = selected?.filter((item) => item?.applyFor === "BILL");
        const listApplyForShip = selected?.filter((item) => item?.applyFor === "SHIP");
        onChangeCoupleSelect({
          bill : listApplyForBill,
          ship : listApplyForShip
        });
        onCloseCoupon();
    };

    useEffect(() => {
      setSelected([...get(couponSelected,'bill',[]),...get(couponSelected,'ship',[])]);
    },[couponSelected]);

    const PropsSection = {
      selected,
      onRemove,
      onAdd,
    };

    return (
        <div>
            <Alert message={
              <div>
                <span>Bạn đã chọn {<Typography.Text type='success' strong>{selected?.length}</Typography.Text>} mã giảm giá</span>
                {(whereCouponIn.inMultiFreeShip || whereCouponIn.inSingleFreeShip) && <Typography.Paragraph><GiftTwoTone /> Đã áp dụng Ưu đãi phí vận chuyển</Typography.Paragraph>}
                {totalCoupon > 0 && <Typography.Text><GiftTwoTone /> Bạn cũng tiết kiệm được <Typography.Text strong>{formatter(totalCoupon)}</Typography.Text> đ từ voucher giảm giá</Typography.Text>}
              </div>
            } type="success" />
            <Flex style={{marginTop : 5}} justify={'space-between'}>
              {loadingGetCoupon ? <LoadingOutlined /> : <>
                {bill?.dataTransportUnit?.payer !== "SYSTEM" && <div>
                <SectionListDiscount {...PropsSection} data={couponByType.couponFreeShipSingle} disabled={selected?.length !== 0 && (whereCouponIn.inMultiFreeShip || whereCouponIn.inSingleFreeShip)}/>
                <SectionListDiscount {...PropsSection} data={couponByType.couponFreeShipMulti} disabled={selected?.length !== 0 && whereCouponIn.inSingleFreeShip}/>
                </div>}
            <div>
                <SectionListDiscount {...PropsSection} data={couponByType.couponBillSingle} disabled={selected?.length !== 0 && (whereCouponIn.inMultiBill || whereCouponIn.inSingleBill)}/>
                <SectionListDiscount {...PropsSection} data={couponByType.couponBillMulti} disabled={selected?.length !== 0 && whereCouponIn.inSingleBill}/>
            </div>
            </>}
            
        </Flex>
        <Flex style={{marginTop : 8}} justify={'end'}>
        <Button onClick={onFinish} type='primary'>
            Xác nhận
        </Button>
        </Flex>
        </div>
    )
}

const SectionListDiscount = ({
  data,
  onAdd,
  onRemove,
  selected,
  disabled,
}: {
  data: CouponInSelect[];
  onAdd : (p?:any) => void;
  onRemove : (p?:any) => void;
  selected : CouponInSelect[],
  disabled? : boolean
}) => {

  return (
    <div style={{ marginBottom: 8 }}>
      {data?.map((item: CouponInSelect) => (
        <Coupon 
        disabled={some(selected,(e) => e?._id === item?._id) ? false : disabled} // Not Disabled for Self
        target='BILL' isChecked={selected?.some((sled) => sled?._id === item?._id)} onRemove={onRemove} onAdd={onAdd} key={item._id} coupon={item} />
      ))}
    </div>
  );
};