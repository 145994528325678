import { Tabs } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormProduct from "../components/FormProduct";
import { useUpdateProduct } from "../product.hook";
type propsType = {};
export default function ProductDetail(props: propsType): React.JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, onUpdate] = useUpdateProduct();
  const goProductList = () => {
    navigate('/products-list')
  }
  return (
    <div>
      <FormProduct id={id} onUpdate={onUpdate} onCancel={goProductList}/>
    </div>
  );
}
