import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Tabs,
} from "antd";
import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import BaseBorderBox from "~/components/common/BaseBorderBox/index";
import Breadcrumb from "~/components/common/Breadcrumb";
import LangFormItem from "~/components/common/LangFormItem";
import { LANGUAGE } from "~/constants/defaultValue";
import { useFailedAnt } from "~/utils/hook";
import FormListItem from "../components/FormListItem";
import { useGetUiByKey, useUpdateUiByKey } from "../ui.hook";

type propsType = {};
const key = "footer";
export default function Footer(props: propsType): React.JSX.Element {
  const [form] = Form.useForm();
  const [reFetch, setReFetch] = useState(false);
  const mutate = useCallback(() => setReFetch(!reFetch), [reFetch]);
  const [data, loading] = useGetUiByKey({ key, reFetch });
  const [language, setLanguage] = useState<"vi" | "en">(LANGUAGE.VI);
  const { onFinishFailed, ErrorComponent, setErrors } = useFailedAnt();
  const [submitLoading, onUpdate] = useUpdateUiByKey(mutate);
  const onFinish = (values: any) => {
    
    setErrors([]); // Clear Error When Finish Successfully
    onUpdate({ key, ...values});
  };

  useEffect(() => {
    form.setFieldsValue({
      ...get(data, ["value",key]),
    });
  }, [data]);
  return (
    <div>
      <Breadcrumb title={"Cấu hình footer"} />
      <Row justify="end" style={{ marginBottom: 20 }}>
        <Radio.Group
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <Radio.Button value={LANGUAGE.VI}>VI</Radio.Button>
          <Radio.Button value={LANGUAGE.EN}>EN</Radio.Button>
        </Radio.Group>
      </Row>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        labelAlign="left"
        labelCol={{ span: 4 }}
        className="form-custom"
      >
        <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"} />
        <BaseBorderBox title={"Thông tin"}>
          <Form.Item name={"phoneNumber"} label="Hotline">
            <Input />
          </Form.Item>
          <LangFormItem
            fieldName={"address"}
            label="Địa chỉ"
            language={language}
          >
            <Input />
          </LangFormItem>
          <Form.Item name={"email"} label="Email">
            <Input />
          </Form.Item>
          <h5>Mạng xã hội</h5>
          <Form.Item name={["social", "facebook"]} label="Facebook">
            <Input placeholder="Đường dẫn Facebook" />
          </Form.Item>
          <Form.Item name={["social", "zalo"]} label="Zalo">
            <Input placeholder="Đường dẫn Zalo" />
          </Form.Item>
          <Form.Item name={["social", "instagram"]} label="Instagram">
            <Input placeholder="Đường dẫn Instagram" />
          </Form.Item>
          <Form.Item name={["social", "youtube"]} label="Youtube">
            <Input placeholder="Đường dẫn Youtube" />
          </Form.Item>
        </BaseBorderBox>
        <BaseBorderBox title={"Menu"}>
          <Form.List name={"menu"}>
            {(fields, { add, remove }) => {
              
              return (
                <>
                
                  <Row gutter={16}>
                    {fields?.map((field, index) => (
                      <>
                        
                        <Col span={24 / 3}>
                        <Flex justify={'end'}>
                        <Button size="small" onClick={() => remove(index)} danger>Xoá cột</Button>
                        </Flex>
                          <BaseBorderBox title={'Tiêu đề cột'}>
                          <LangFormItem
                            fieldName={[index, "title"]}
                            language={language}
                          >
                            <Input placeholder="Tiêu đề Menu" />
                          </LangFormItem>
                          </BaseBorderBox>
                        <BaseBorderBox title="Các mục">
                        <Form.List name={[index, "menuItem"]}>
                            {(
                              fieldsItem,
                              { add: addItem, remove: remoteItem }
                            ) => (
                              <>
                                <Flex gap={8} vertical>
                                  {fieldsItem?.map((field, indexItem) => (
                                    <>
                                      <Flex justify={'end'}>
                                        <Button size="small" onClick={() => remoteItem(indexItem)} style={{marginBottom : 5}} danger>Xoá mục {indexItem + 1}</Button>
                                      </Flex>
                                      <LangFormItem
                                        fieldName={[indexItem, "label"]}
                                        language={language}
                                      >
                                        <Input
                                          placeholder={"Tiêu đề " + (indexItem + 1)}
                                        />
                                      </LangFormItem>
                                      <Form.Item name={[indexItem, "url"]}>
                                        <Input
                                          placeholder={"Đường dẫn " + (indexItem + 1)}
                                        />
                                      </Form.Item>
                                      <Divider/>
                                    </>
                                  ))}
                                  <Button
                                    onClick={() => addItem()}
                                    icon={<PlusOutlined />}
                                    type='primary'
                                  >
                                    Thêm Menu con
                                  </Button>
                                </Flex>
                              </>
                            )}
                          </Form.List>
                        </BaseBorderBox>
                        </Col>
                      </>
                    ))}
                    {fields?.length < 3 && <Col span={24 / 3}>
                      <Button
                        icon={<PlusOutlined />}
                        type="dashed"
                        onClick={() => add()}
                      >
                        Thêm
                      </Button>
                    </Col>}
                  </Row>
                </>
              )
            }}
          </Form.List>
        </BaseBorderBox>
        <Flex justify={'center'}>
        <Button loading={submitLoading} htmlType="submit" type="primary">
          Cập nhật
        </Button>
        </Flex>
      </Form>
    </div>
  );
}
