import NewsCategoryScreen from "./screens/NewsCategory";
import newsCategoryApi from "./newsCategory.api";
import * as newsCategoryHook from "./newsCategory.hook";
import * as newsCategoryService from "./newsCategory.service";
import * as newsCategoryModels from "./newsCategory.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : NewsCategoryScreen,
    },
    api : newsCategoryApi,
    hook : newsCategoryHook,
    service : newsCategoryService,
    model : newsCategoryModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;