import React from "react";
import { Table } from "antd";
import {
  useGetlistWorkBoard,
  useUpdateWorkBoardParams,
  useWorkBoardQueryParams,
} from "../workBoard.hook";
import { useExpandrowTableClick } from "~/utils/helpers";
import { ColumnsType } from "antd/es/table";
import { DataType } from "../workBoard.modal";

interface WorkFlowProps {
  columns: ColumnsType<DataType>;
  typeSystem?: "EMPLOYEE_SYSTEM" | "STAFF_SYSTEM";
}

const WorkBoardType: React.FC<WorkFlowProps> = ({ columns, typeSystem }) => {
  const { select, setSelect, onClick } = useExpandrowTableClick();
  const [query] = useWorkBoardQueryParams(typeSystem);
  const [keyword, { onParamChange, setKeyword }] =
    useUpdateWorkBoardParams(query);
  const [board, isLoadingList] = useGetlistWorkBoard(query);

  return (
    <div>
      <Table
        rowKey={(rc) => rc._id}
        columns={columns}
        loading={isLoadingList}
        dataSource={board}
        onRow={(item) => ({
          onClick: onClick(item),
        })}
        expandable={{
          expandedRowKeys: select,
          onExpandedRowsChange: (e: any) => {
            onParamChange({ page: 1 }); // Reset to the first page when expanding rows
            setSelect(e);
          },
        }}
        bordered
        pagination={false}
      />
    </div>
  );
};

export default WorkBoardType;
