import { Typography } from "antd";
import React from "react";
import { useMatchPolicy } from "~/modules/policy/policy.hook";

interface WithPermissionProps {
  children: React.ReactNode;
  permission: string | any;
}
const WithPermissionWithNotification = ({ children, permission }: WithPermissionProps) => {
  const isMatchPolicy = useMatchPolicy(permission);
  return (
    <>
      {isMatchPolicy ? (
        children
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Typography.Text strong type="secondary" style={{textAlign : 'center'}}>Bạn không có quyền truy cập trang này</Typography.Text>
        </div>
      )}
    </>
  );
};

export default WithPermissionWithNotification;
