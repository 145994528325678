import { Tabs } from "antd";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import customerDivisionApi from "~/api/customerDivision";
import WhiteBox from "~/components/common/WhiteBox";
import WithPermissionWithNotification from "~/components/common/WithPermissionWithNotification";
import POLICIES from "~/modules/policy/policy.auth";
import ResourceConstructor from "~/modules/tenant/components/ResourceConstructor";
import { useFetchState } from "~/utils/helpers";
export default function CustomerDivision(): React.JSX.Element {
  const [dataSource, isLoading] = useFetchState({
    api: customerDivisionApi.getAll,
    useDocs: false,
  });
  const [currentTab, setCurrentTab] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  
  const onChange = (key: any) => {
    setCurrentTab(key);
    navigate(`/tenant-detail/${id}/customerDivisionDetail/${key}/customerDivisionResourceConstructor`);
  };

useEffect(() => {
  if(!currentTab){
    navigate(`/tenant-detail/${id}/customerDivisionDetail/${get(dataSource,[0,'_id'])}/customerDivisionResourceConstructor`);
  }
},[dataSource,id,currentTab])
  
  return (
    <div>
      <WhiteBox>
        <Tabs
          type="card"
          activeKey={currentTab}
          onChange={(key) => onChange(key)}
          defaultActiveKey={pathname}
        >
          {dataSource?.map((item: any) => (
            <Tabs.TabPane tab={item?.title} key={item?._id}>
              <WithPermissionWithNotification permission={POLICIES.READ_RESOURCECUSTOMERCHANNEL}>
                <ResourceConstructor type="CustomerChannel"/>
              </WithPermissionWithNotification>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </WhiteBox>
    </div>
  );
}
