import { Tabs } from 'antd';
import React from 'react';
import NewsView from './NewsView';
type propsType = {

}
export default function MainContentTab(props:propsType) : React.JSX.Element {
    return (
        <Tabs
      className={"layoutDetail--right__mainContent__tab"}
      destroyInactiveTabPane
      items={[
        {
          key: "1",
          label: "Thông tin",
          children: <NewsView />,
        },
      ]}
    />
    )
}