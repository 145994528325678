import VisitorScreen from "./screens/Visitor";
import visitorApi from "./visitor.api";
import * as visitorHook from "./visitor.hook";
import * as visitorService from "./visitor.service";
import * as visitorModels from "./visitor.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : VisitorScreen,
    },
    api : visitorApi,
    hook : visitorHook,
    service : visitorService,
    model : visitorModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;