import { get } from "lodash";
import requester from "~/api/requester";

const apis = {
    getAll: (query?: any) => requester.get(`/api/v1/client-subscribe`, query),
    getCoupons: (id?: any) => requester.get(`/api/v1/client-subscribe-coupons/${id}`),
    getCouponsReady: (id?: any) => requester.get(`/api/v1/client-subscribe-coupons-ready/${id}`),
    update: (data?: any) => requester.put(`/api/v1/client-subscribe/${get(data,'_id')}`, data),
    sendMail: (id?: any) => requester.post(`/api/v1/client-subscribe-send`,{id}),
    // getById: (id?: any) => requester.get(`/api/v1/clientSubscribe/${id}`),
    // create: (data?: any) => requester.post(`/api/v1/clientSubscribe`, data),
    // delete: (id?: any) => requester.delete(`/api/v1/clientSubscribe/${id}`),
}
export default apis;
