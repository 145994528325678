import { Segmented } from 'antd';
import React from 'react';
import { SECTION_KEY_OPTIONS_FOR_SEGMENTED } from '../constants';
type propsType = {

};

export default function SectionKeySelect(props:propsType) : React.JSX.Element {
    return (
        <Segmented {...props} options={SECTION_KEY_OPTIONS_FOR_SEGMENTED}/>
    )
}