export const STATUS_QUOTATION = {
    NEW : "NEW",
    CONFIRMED : "CONFIRMED",
}
export const STATUS_QUOTATION_VI = {
    NEW : "Mới",
    CONFIRMED : "Đã xử lý",
    CANCELLED : "Đã huỷ",
}

export const DEFAULT_DEBT_TYPE = "COD";