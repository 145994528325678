import { Button, Checkbox, Col, Flex, Row, Select, Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table/InternalTable";
import { get } from "lodash";
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableAnt from "~/components/Antd/TableAnt";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import { filterSlug } from "~/utils/helpers";
import {
  useAccessResourceCustomerChannel,
   useAccessResourceTenant,
   useDenyResourceCustomerChannel,
   useDenyResourceTenant,
   useGetResourceConstructorCustomerChannel,
   useGetResourceConstructorTenant,
   useGetResourceCustomerChannel,
   useGetResourceTenant
} from "../tenant.hook";
const hook = {
  useGetResourceConstructor: {
    Tenant: useGetResourceConstructorTenant,
    CustomerChannel: useGetResourceConstructorCustomerChannel,
  },
  useGetResource: {
    Tenant: useGetResourceTenant,
    CustomerChannel: useGetResourceCustomerChannel,
  },
  useAccessResource: {
    Tenant: useAccessResourceTenant,
    CustomerChannel: useAccessResourceCustomerChannel,
  },
  useDenyResource: {
    Tenant: useDenyResourceTenant,
    CustomerChannel: useDenyResourceCustomerChannel,
  },
};
const POLICY = {
  Tenant : POLICIES.UPDATE_RESOURCETENANT,
  CustomerChannel : POLICIES.UPDATE_RESOURCECUSTOMERCHANNEL,
}
type Props = {
  type: "Tenant" | "CustomerChannel";
};
export default function ResourceConstructor({
  type,
}: Props): React.JSX.Element {
  const canUpdate = useMatchPolicy(POLICY[type])
  const { id: idParams,divisionId } = useParams();
  const [filter, setFilter] = useState<"all" | "checked" | "unChecked">("all");
  const id = useMemo(() => idParams, [idParams]);
  const queryGetResource = useMemo(() => {
    if(type === "CustomerChannel") return {divisionId,tenantId : id};
    if(type === "Tenant") return id;
  },[id,divisionId,type])
  const [keyword, setKeyword] = useState<any>();
  const [resourcesConstructor, isResourcesConstructorLoading] =
    hook.useGetResourceConstructor[type](queryGetResource);
  const [searchResource, setSearchResource] = useState([]);
  const [resource, isLoading] = hook.useGetResource[type](queryGetResource);
  const dataSource = useMemo(() => {
    if (!keyword && filter === "all") return resourcesConstructor;
    return searchResource;
  }, [resourcesConstructor, searchResource, keyword]);

  const [isSubmitLoading, access] = hook.useAccessResource[type]();
  const [, deny] = hook.useDenyResource[type]();

  const getPayload = (resources : any[]) => {
    switch (type) {
      case "Tenant":
        return { tenantId: id, resources }
      case "CustomerChannel":
        return { tenantId : id, resources,divisionId }
    
      default:
        break;
    }
    
  }
  const onChange = (checked: boolean, key: any) => {
    if (checked) {
      access(getPayload([key]));
    } else {
      deny(getPayload([key]));
    }
  };

  const onCheckAll = () => {
    const dataNotYetChecked = dataSource?.filter(
      (item: any) => !resource[item?.key]
    ); // get all data not yet checked
    access(getPayload(dataNotYetChecked?.map((item: any) => item?.key)));
  };
  const onUnCheckAll = () => {
    const dataChecked = dataSource?.filter(
      (item: any) => !!resource[item?.key]
    ); // get all data  checked
    deny(getPayload(dataChecked?.map((item: any) => item?.key)));
  };

  useEffect(() => {
    const filterKeyword = resourcesConstructor?.filter((item: any) => filterSlug(keyword || "", item?.name));
    const filterChecked = filterKeyword?.filter((item:any) => {
        if(filter === 'all') return true;
        if(filter === 'checked') return !!get(resource, get(item, "key"));
        if(filter === 'unChecked') return !get(resource, get(item, "key"));
    })
    setSearchResource(filterChecked);
  }, [keyword, filter]);

  const onFilter = (value: "all" | "checked" | "unChecked") => {
    setFilter(value);
  };
  const columns: ColumnsType = [
    {
      title: "Chức năng",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cấp phép",
      dataIndex: "checked",
      key: "checked",
      width: 100,
      align: "center",
      render: (checked: any, rc: any) => (
        <Tooltip title={!canUpdate && "Bạn không có quyền cập nhật"}>
          <Checkbox
          checked={!!get(resource, get(rc, "key"))}
          onChange={(e) => onChange(e.target.checked, get(rc, "key", ""))}
          disabled={!canUpdate}
        />
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="white-box">
      <Row style={{ marginBottom: 10 }} justify="space-between" align="middle">
        <Col span={16}>
          <Flex gap={10}>
          <Search
            style={{width : 350}}
            placeholder="Tìm chức năng"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            enterButton
            allowClear
          />
          <Select
            variant="filled"
            style={{width : 150}}
            onChange={onFilter}
            value={filter}
            options={[
              { value: "all", label: "Tất cả" },
              { value: "checked", label: "Đã cấp phép" },
              { value: "unChecked", label: "Chưa cấp phép" },
            ]}
          />
          </Flex>
        </Col>
      <WithPermission permission={POLICY[type]}>
      <Col>
          <Flex gap={10}>
            <Button loading={isLoading} onClick={onCheckAll} ghost type="primary" size="small">
              Chọn tất cả
            </Button>
            <Button loading={isLoading} onClick={onUnCheckAll} danger size="small">
              Bỏ tất cả
            </Button>
          </Flex>
        </Col>
      </WithPermission>
      </Row>
      <TableAnt
        // pagination={false}
        loading={isResourcesConstructorLoading}
        rowKey={(rc) => rc?.key}
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={{
          showTotal : (total) => `Tổng cộng ${total}`
        }}
      />
    </div>
  );
}
