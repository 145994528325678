import { OptionProps } from "antd/es/select/index";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import pharmacyModule from "~/modules/pharmacy";
import { PropSearchPharmacyV2 } from "~/modules/pharmacy/pharmacy.modal";
import ProductModule from "~/modules/product";
import ProductGroupModule from "~/modules/productGroup";
import { useFetchState } from "~/utils/hook";
import { DSM_getOptions,DSM_getOptionsProduct } from "./DebounceSelectMultiple.service";
// DSM = Debounce Select Multiple
// interface Value
export type GlobalDebounceSelectMultiple = {
  DSM_setting : {
    dataSource : {
      pharma_profile : OptionProps[],
      partner : OptionProps[],
      product : OptionProps[],
      productGroup : OptionProps[],
      visitor : OptionProps[],
    },
    values : {
      pharma_profile : string[],
      partner : string[],
      product : string[],
      productGroup : string[],
      visitor : string[],
    },
    loading : {
      pharma_profile : boolean,
      partner : boolean,
      product : boolean,
      productGroup : boolean,
      visitor : boolean,
    },
  }
};
// Init Value
const DebounceSelectMultiple = createContext<GlobalDebounceSelectMultiple>({
  DSM_setting : {
    dataSource : {
      partner : [],
      pharma_profile : [],
      product : [],
      productGroup : [],
      visitor : [],
    },
    values : {
      pharma_profile : [],
      partner : [],
      product : [],
      productGroup : [],
      visitor : [],
    },
    loading : {
      pharma_profile : false,
      partner : false,
      product : false,
      productGroup : false,
      visitor : false,
    },
  }
});

type DebounceSelectMultipleProviderProps = {
  children: ReactNode;
  valuesPharmacy? : string[];
  valuesPartner? : string[];
  valuesProduct? : string[]; 
  valuesVisitor? : string[]; 
  valuesProductGroup? : string[]; 
  usePharmacy? : boolean;
  usePartner? : boolean;
  useProduct? : boolean;
  useProductGroup? : boolean;
  useVisitor? : boolean;
  shouldSyncValues? : boolean; // Should we need values to Call Api
};

export function DebounceSelectMultipleProvider({
  children,
  valuesPharmacy,
  valuesPartner,
  valuesProduct,
  valuesVisitor,
  valuesProductGroup,
  usePharmacy = false,
  usePartner = false,
  useProduct = false,
  useProductGroup = false,
  useVisitor = false,
  shouldSyncValues = true,
}: DebounceSelectMultipleProviderProps): JSX.Element {

  const [dataIsReady, setDataIsReady] = useState({ // Check Data is Exists Will Not Call Api Again
    pharma_profile: false,
    partner: false,
    product: false,
    productGroup: false,
    visitor: false,
  });
  
  // Query data
  const queryPharmacy : PropSearchPharmacyV2 | undefined = useMemo(
    () => {
      const submitQuery : PropSearchPharmacyV2 | undefined = ({
        customerType : "pharma_profile",
        keyword: "",
        ...valuesPharmacy && {optionWith : {
          id : valuesPharmacy
        }}
      });
      return shouldSyncValues ? (valuesPharmacy && submitQuery) : submitQuery
    },
    [valuesPharmacy,shouldSyncValues]
  );

  const queryPartner : PropSearchPharmacyV2 | undefined = useMemo(
    () => {
      const submitQuery : PropSearchPharmacyV2 | undefined = ({
        customerType : "partner",
        keyword: "",
        ...valuesPartner && {optionWith : {
          id : valuesPartner
        }}
      });
      return shouldSyncValues ? (valuesPartner && submitQuery) : submitQuery
    },
    [valuesPartner,shouldSyncValues]
  );

  const queryVisitor : PropSearchPharmacyV2 | undefined = useMemo(
    () => {
      const submitQuery : PropSearchPharmacyV2 | undefined = ({
        customerType : "visitor",
        keyword: "",
        ...valuesVisitor && {optionWith : {
          id : valuesVisitor
        }}
      });
      return shouldSyncValues ? (valuesVisitor && submitQuery) : submitQuery
    },
    [valuesVisitor,shouldSyncValues]
  );

  
  const queryProduct : any = useMemo(
    () => {
      const submitQuery : any = ({
        keyword: "",
        ...valuesProduct && {optionWith : {
          id : valuesProduct
        }}
      })
      return shouldSyncValues ? (valuesProduct && submitQuery) : submitQuery
    },
    [valuesProduct,shouldSyncValues]
  );


  // Fetch data
  const [pharmacies, DSM_dataSourcePharmacyRootLoading] = useFetchState({
    api: pharmacyModule.api.searchV2,
    query : queryPharmacy,
    conditionRun : usePharmacy && (shouldSyncValues ?  !!valuesPharmacy : true) && !dataIsReady.pharma_profile,
    useDocs : false
  });
  const [partners, DSM_dataSourcePartnerRootLoading] = useFetchState({
    api: pharmacyModule.api.searchV2,
    query : queryPartner,
    conditionRun : usePartner && (shouldSyncValues ?  !!valuesPartner : true) && !dataIsReady.partner,
    useDocs : false
  });

  const [visitors, DSM_dataSourceVisitorRootLoading] = useFetchState({
    api: pharmacyModule.api.searchV2,
    query : queryVisitor,
    conditionRun : useVisitor && (shouldSyncValues ? !!valuesVisitor : true) && !dataIsReady.visitor,
    useDocs : false
  });
  
  const [products, DSM_dataSourceProductRootLoading] = useFetchState({
    api: ProductModule.api.getOptions,
    query : queryProduct,
    conditionRun : useProduct && (shouldSyncValues ?  !!valuesProduct : true) && !dataIsReady.product,
    useDocs : false,
  });

  const [productsGroup, DSM_dataSourceProductGroupRootLoading] = useFetchState({
    api: ProductGroupModule.api.getAllPublic,
    conditionRun : useProductGroup,
    useDocs : false,
  });
  
  // Convert data
  const DSM_dataSourcePharmacyRoot : OptionProps[] = useMemo(
    () => DSM_getOptions(pharmacies),
    [pharmacies]
  );

  const DSM_dataSourcePartnerRoot : OptionProps[] = useMemo(
    () => DSM_getOptions(partners),
    [partners]
  );
  const DSM_dataSourceVisitorRoot : OptionProps[] = useMemo(
    () => DSM_getOptions(visitors),
    [visitors]
  );

  const DSM_dataSourceProductGroupRoot : OptionProps[] = useMemo(
    () => DSM_getOptions(productsGroup),
    [productsGroup]
  );

  const DSM_dataSourceProductRoot : OptionProps[] = useMemo(
    () => DSM_getOptionsProduct(products),
    [products]
  );

    // Export data
  const DSM_setting = useMemo(
    () => ({
      dataSource: {
        pharma_profile: DSM_dataSourcePharmacyRoot,
        partner: DSM_dataSourcePartnerRoot,
        product: DSM_dataSourceProductRoot,
        productGroup: DSM_dataSourceProductGroupRoot,
        visitor: DSM_dataSourceVisitorRoot,
      },
      loading: {
        pharma_profile: DSM_dataSourcePharmacyRootLoading,
        partner: DSM_dataSourcePartnerRootLoading,
        product: DSM_dataSourceProductRootLoading,
        productGroup: DSM_dataSourceProductGroupRootLoading,
        visitor: DSM_dataSourceVisitorRootLoading,
      },
      values: {
        pharma_profile: valuesPharmacy || [],
        partner: valuesPartner || [],
        product: valuesProduct || [],
        productGroup: valuesProductGroup || [],
        visitor: valuesVisitor || [],
      },
    }),
    [
      DSM_dataSourcePharmacyRoot,
      DSM_dataSourcePartnerRoot,
      DSM_dataSourceProductRoot,
      DSM_dataSourceProductGroupRoot,
      DSM_dataSourceVisitorRoot,
      valuesPharmacy,
      valuesPartner,
      valuesProduct,
      valuesProductGroup,
      valuesVisitor,
      DSM_dataSourcePharmacyRootLoading,
      DSM_dataSourcePartnerRootLoading,
      DSM_dataSourceProductRootLoading,
      DSM_dataSourceProductGroupRootLoading,
      DSM_dataSourceVisitorRootLoading,
    ]
  );

  // Check If Data is available Will Set Data is Ready
  useEffect(() => {
    setDataIsReady({
      pharma_profile : !!DSM_dataSourcePharmacyRoot?.length,
      partner : !!DSM_dataSourcePartnerRoot?.length,
      product : !!DSM_dataSourceProductRoot?.length,
      productGroup : !!DSM_dataSourceProductGroupRoot?.length,
      visitor : !!DSM_dataSourceVisitorRoot?.length,
    })
  },[DSM_setting])
  return (
    <DebounceSelectMultiple.Provider
      value={{
        // Value
        DSM_setting,
      }}
    >
      {children}
    </DebounceSelectMultiple.Provider>
  );
}

const useDebounceSelectMultipleStore = (): GlobalDebounceSelectMultiple =>
  useContext(DebounceSelectMultiple);

export default useDebounceSelectMultipleStore;
