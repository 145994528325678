
export const STATUS_REQUEST_GROUP = {
    NEW: "NEW",
    PROCESSING: "PROCESSING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
  } as const;
  export const STATUS_REQUEST_GROUP_VI = {
    NEW: "Đã tiếp nhận",
    PROCESSING: "Đang xử lý",
    COMPLETED: "Đã hoàn thành",
    CANCELLED: "Đã huỷ",
  } as const;

  export const STATUS_REQUEST_GROUP_COLOR = {
    NEW : "default",
    PROCESSING : "processing",
    COMPLETED : "success",
    CANCELLED : "error",
  } as const;

  export const STATUS_REQUEST_GROUP_DISABLED = {
    NEW : [STATUS_REQUEST_GROUP.NEW],
    PROCESSING : [STATUS_REQUEST_GROUP.NEW,STATUS_REQUEST_GROUP.PROCESSING],
    COMPLETED : [STATUS_REQUEST_GROUP.NEW,STATUS_REQUEST_GROUP.COMPLETED,STATUS_REQUEST_GROUP.PROCESSING,STATUS_REQUEST_GROUP.CANCELLED],
    CANCELLED : [STATUS_REQUEST_GROUP.NEW,STATUS_REQUEST_GROUP.COMPLETED,STATUS_REQUEST_GROUP.PROCESSING,STATUS_REQUEST_GROUP.CANCELLED],
  } as const;