import { Badge, Flex, Input, Modal, Typography } from "antd";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "~/components/common/Layout/List/Detail/Header";
import Layout from "~/components/common/Layout/List/Detail/Layout";
import ListInDetailCommon from "~/components/common/Layout/List/Detail/ListInDetailCommon";
import { STATUS_COLOR, STATUS_NAMES } from "~/constants/defaultValue";
import POLICIES from "~/modules/policy/policy.auth";
import { PATH_APP } from "~/routes/allPath";
import { ObjOrString } from "~/utils/helpers";
import {
    useCategoryParentPaging,
    useCategoryParentQueryParams, useDeleteCategoryParent, useGetCategoryParentId_onlyGet,
    useGetCategoryParents,
    useUpdateCategoryParent,
    useUpdateCategoryParentParams
} from "../categoryParent.hook";
import CategoryParentForm from "../components/CategoryParentForm";
import MainContentTab from "../components/MainContentTab";

const CLONE_STATUS_NAMES: any = STATUS_NAMES;
const CLONE_STATUS_COLOR: any = STATUS_COLOR;

export default function CategoryParentDetail(): React.JSX.Element {
  const { id: categoryParentId }: any = useParams();
  const [id, setId] = useState<any>();
  const [query] = useCategoryParentQueryParams();
  const [categoryParent]: any = useGetCategoryParentId_onlyGet();
  const [keyword, { setKeyword, onParamChange }] = useUpdateCategoryParentParams(query);
  const [isOpenForm, setIsOpenForm] = useState(false);
  // const [destroy, setDestroy] = useState(false);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSearch = () => {
    onParamChange({ keyword });
    onClose();
  };

  const onCloseForm = useCallback(() => {
    setIsOpenForm(false);
    setId(null);
  }, []);

  const onOpenForm = useCallback((idd?: any) => {
    setIsOpenForm(true);
    setId(idd);
  }, []);


  const [, handleUpdate] = useUpdateCategoryParent(() => {
    onCloseForm();
  });
  const [, handleDelete] = useDeleteCategoryParent();

  return (
    <>
      <Layout
        HeaderLeft={
          <Header.HeaderLeft
            PERMISSION_WRITE={POLICIES.WRITE_CATEGORYPARENT}
            onChangeStatus={(status) => onParamChange({ status })}
            onAdd={() => onOpenForm()}
            SearchProp={{
              openSearch: showDrawer,
              open,
              onClose,
              onSearch,
              SearchComponent: (
                <Input
                  placeholder="Nhập để tìm kiếm"
                  allowClear
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              ),
              querySearch: ["keyword"],
            }}
          />
        }
        HeaderRight={
          <Header.HeaderRight
            PERMISSION_DELETE={POLICIES.DELETE_CATEGORYPARENT}
            PERMISSION_UPDATE={POLICIES.UPDATE_CATEGORYPARENT}
            path={PATH_APP.categoryParent.root}
            onDeleteClick={() => handleDelete(categoryParentId)}
            onEditClick={() => onOpenForm(categoryParentId)}
            name={
              <Flex gap={10} align="center">
                <h4>
                  {ObjOrString(get(categoryParent, "name", ""))}
                </h4>
                <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                  <Badge
                    style={{ marginRight: 2 }}
                    status={
                      CLONE_STATUS_COLOR[get(categoryParent, "status", "")]
                    }
                  />
                  {CLONE_STATUS_NAMES[get(categoryParent, "status", "")]}
                </Typography.Text>
              </Flex>
            }
          />
        }
        MainContent={<MainContentTab />}
        List={
          <ListInDetailCommon
            fieldName={"name"}
            path={"/categoryParent-detail"}
            useGets={useGetCategoryParents}
            usePaging={useCategoryParentPaging}
            query={query}
            onParamChange={onParamChange}
            moduleName="categoryParent"
          />
        }
      />
      <Modal
        open={isOpenForm}
        onCancel={() => setIsOpenForm(false)}
        className="form-modal"
        footer={null}
        style={{ top: 50 }}
        destroyOnClose
      >
        <CategoryParentForm
          id={id}
          onCancel={onCloseForm}
          handleUpdate={handleUpdate}
        />
      </Modal>
    </>
  );
}
