import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { InstanceModuleRedux } from "~/redux/instanceModuleRedux";
import { initStateSlice } from "~/redux/models";
interface cloneInitState extends initStateSlice {
 // Add cloneInitState Type Here
  resourceConstructorTenant : any,
  loadingGetResourceConstructorTenant : boolean,
  getResourceConstructorTenantFailed : any,

  resourceTenant : any,
  loadingGetResourceTenant : boolean,
  getResourceTenantFailed : any,

  accessResourceTenantSuccess: any,
  accessResourceTenantFailed: any,

  denyResourceTenantSuccess: any,
  denyResourceTenantFailed: any,

  resourceConstructorCustomerChannel : any,
  loadingGetResourceConstructorCustomerChannel : boolean,
  getResourceConstructorCustomerChannelFailed : any,

  resourceCustomerChannel : any,
  loadingGetResourceCustomerChannel : boolean,
  getResourceCustomerChannelFailed : any,

  accessResourceCustomerChannelSuccess: any,
  accessResourceCustomerChannelFailed: any,

  denyResourceCustomerChannelSuccess: any,
  denyResourceCustomerChannelFailed: any,


}
class TenantClassExtend extends InstanceModuleRedux {
  cloneReducer;
  cloneInitState : any;
  constructor() {
    super('tenant');
    this.cloneReducer = {
      ...this.initReducer,
      // Want Add more reducer Here...
      getListSuccess: (state:initStateSlice , { payload }: any) => {
        state.isLoading = false;
        state.list = payload;
      },
      getResourceConstructorTenantRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceConstructorTenant = true;
        state.getResourceConstructorTenantFailed = null;
      },
      getResourceConstructorTenantSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceConstructorTenant = payload;
        state.loadingGetResourceConstructorTenant = false;
      },
      getResourceConstructorTenantFailed: (state:cloneInitState , { payload }: any) => {
        state.getResourceConstructorTenantFailed = payload;
        state.loadingGetResourceConstructorTenant = false;
      },
      getResourceTenantRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceTenant = true;
        state.getResourceTenantFailed = null;
      },
      getResourceTenantSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceTenant = payload;
        state.loadingGetResourceTenant = false;
      },
      getResourceTenantFailed: (state:cloneInitState , { payload }: any) => {
        state.getResourceTenantFailed = payload;
        state.loadingGetResourceTenant = false;
      },
      accessResourceTenantRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceTenant = true;
        state.accessResourceTenantFailed = null;
      },
      accessResourceTenantSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceTenant = payload;
        state.loadingGetResourceTenant = false;
      },
      accessResourceTenantFailed: (state:cloneInitState , { payload }: any) => {
        state.accessResourceTenantFailed = payload;
        state.loadingGetResourceTenant = false;
      },
      denyResourceTenantRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceTenant = true;
        state.denyResourceTenantFailed = null;
      },
      denyResourceTenantSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceTenant = payload;
        state.loadingGetResourceTenant = false;
      },
      denyResourceTenantFailed: (state:cloneInitState , { payload }: any) => {
        state.denyResourceTenantFailed = payload;
        state.loadingGetResourceTenant = false;
      },
      getResourceConstructorCustomerChannelRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceConstructorCustomerChannel = true;
        state.getResourceConstructorCustomerChannelFailed = null;
      },
      getResourceConstructorCustomerChannelSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceConstructorCustomerChannel = payload;
        state.loadingGetResourceConstructorCustomerChannel = false;
      },
      getResourceConstructorCustomerChannelFailed: (state:cloneInitState , { payload }: any) => {
        state.getResourceConstructorCustomerChannelFailed = payload;
        state.loadingGetResourceConstructorCustomerChannel = false;
      },
      getResourceCustomerChannelRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceCustomerChannel = true;
        state.getResourceCustomerChannelFailed = null;
      },
      getResourceCustomerChannelSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceCustomerChannel = payload;
        state.loadingGetResourceCustomerChannel = false;
      },
      getResourceCustomerChannelFailed: (state:cloneInitState , { payload }: any) => {
        state.getResourceCustomerChannelFailed = payload;
        state.loadingGetResourceCustomerChannel = false;
      },
      accessResourceCustomerChannelRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceCustomerChannel = true;
        state.accessResourceCustomerChannelFailed = null;
      },
      accessResourceCustomerChannelSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceCustomerChannel = payload;
        state.loadingGetResourceCustomerChannel = false;
      },
      accessResourceCustomerChannelFailed: (state:cloneInitState , { payload }: any) => {
        state.accessResourceCustomerChannelFailed = payload;
        state.loadingGetResourceCustomerChannel = false;
      },
      denyResourceCustomerChannelRequest: (state:cloneInitState , { payload }: any) => {
        state.loadingGetResourceCustomerChannel = true;
        state.denyResourceCustomerChannelFailed = null;
      },
      denyResourceCustomerChannelSuccess: (state:cloneInitState , { payload }: any) => {
        state.resourceCustomerChannel = payload;
        state.loadingGetResourceCustomerChannel = false;
      },
      denyResourceCustomerChannelFailed: (state:cloneInitState , { payload }: any) => {
        state.denyResourceCustomerChannelFailed = payload;
        state.loadingGetResourceCustomerChannel = false;
      },
      updateSuccess: (state:initStateSlice, { payload }:{payload:any}) => {
        state.isSubmitLoading = false;
        state.byId = payload;
        state.list = state.list?.map((item:any) => get(item,'_id') === get(payload,'_id') ? payload : item);
        state.listSearch = state.listSearch?.map((item:any) => get(item,'_id') === get(payload,'_id') ? payload : item);
        state.updateSuccess = payload;
      },
    }
    this.cloneInitState = {
      ...this.initialState,
      resourceConstructorTenant: null,
      loadingGetResourceConstructorTenant: false,
      getResourceConstructorTenantFailed: null,
      resourceTenant: null,
      loadingGetResourceTenant: false,
      getResourceTenantFailed: null,
      accessResourceTenantSuccess: null,
      accessResourceTenantFailed: null,
      denyResourceTenantSuccess: null,
      denyResourceTenantFailed: null,
      resourceConstructorCustomerChannel: null,
      loadingGetResourceConstructorCustomerChannel: false,
      getResourceConstructorCustomerChannelFailed: null,
      resourceCustomerChannel: null,
      loadingGetResourceCustomerChannel: false,
      getResourceCustomerChannelFailed: null,
      accessResourceCustomerChannelSuccess: null,
      accessResourceCustomerChannelFailed: null,
      denyResourceCustomerChannelSuccess: null,
      denyResourceCustomerChannelFailed: null,
      // Want Add more State Here...
    };
  }
  createSlice() {
    return createSlice({
      name: this.module,
      initialState: this.cloneInitState,
      reducers:  this.cloneReducer,
    });
  }
  
}

const newSlice = new TenantClassExtend();
const data = newSlice.createSlice();


export const tenantActions = data.actions;
export default data.reducer;
