import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../categoryParent.api'; 
import { categoryParentActions } from './reducer';

function* getListCategoryParent({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(categoryParentActions.getListSuccess(data));
  } catch (error:any) {
    yield put(categoryParentActions.getListFailed(error));
  }
}

function* getByIdCategoryParent({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(categoryParentActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(categoryParentActions.getByIdFailed(error));
  }
}

function* createCategoryParent({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(categoryParentActions.createSuccess(data));
    yield delay(300);
    yield put(categoryParentActions.resetAction());
  } catch (error:any) {
    yield put(categoryParentActions.createFailed(error));
  }
}

function* updateCategoryParent({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(categoryParentActions.updateSuccess(data));
    yield delay(300);
    yield put(categoryParentActions.resetAction());
  } catch (error:any) {
    yield put(categoryParentActions.updateFailed(error));
  }
}
function* deleteCategoryParent({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(categoryParentActions.deleteSuccess(data));
  } catch (error:any) {
    yield put(categoryParentActions.deleteFailed(error));
  }
}


export default function* categoryParentSaga() {
  yield takeLatest(categoryParentActions.getListRequest, getListCategoryParent);
  yield takeLatest(categoryParentActions.getByIdRequest, getByIdCategoryParent);
  yield takeLatest(categoryParentActions.createRequest, createCategoryParent);
  yield takeLatest(categoryParentActions.updateRequest, updateCategoryParent);
  yield takeLatest(categoryParentActions.deleteRequest, deleteCategoryParent);
}
