import { Tooltip } from 'antd';
import React from 'react';
type propsType = {
    children : any,
    isMatch : boolean,
    type : "update" | "delete" | "create";
    permissionName? : string,
};
const typeMess : any = {
    update : "Bạn cần có quyền cập nhật",
    delete : "Bạn cần có quyền xoá",
    create : "Bạn cần có quyền tạo mới",
} as const
export default function BtnAuth({children,isMatch,type,permissionName}:propsType) : React.JSX.Element {
    
    return (
        <Tooltip title={!isMatch ? `${typeMess[type]} ${permissionName ? permissionName : ''}` : null}>
            {children}
        </Tooltip>
    )
}