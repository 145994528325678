import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../clientSubscribe.api'; 
import { clientSubscribeActions } from './reducer';

function* getListClientSubscribe({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(clientSubscribeActions.getListSuccess(data));
    yield put(clientSubscribeActions.clearAction());
  } catch (error:any) {
    yield put(clientSubscribeActions.getListFailed(error));
  }
}

// function* getByIdClientSubscribe({payload:id} : any) : any {
//   try {
//     const data = yield call(api.getById,id);
//     yield put(clientSubscribeActions.getByIdSuccess(data));
//   } catch (error:any) {
//     yield put(clientSubscribeActions.getByIdFailed(error));
//   }
// }

// function* createClientSubscribe({payload} : any) : any {
//   try {
//     const data = yield call(api.create,payload);
//     yield put(clientSubscribeActions.createSuccess(data));
//   } catch (error:any) {
//     yield put(clientSubscribeActions.createFailed(error));
//   }
// }

function* updateClientSubscribe({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(clientSubscribeActions.updateSuccess(data));
    yield delay(300);
    yield put(clientSubscribeActions.resetAction());
  } catch (error:any) {
    yield put(clientSubscribeActions.updateFailed(error));
  }
}
// function* deleteClientSubscribe({payload : id} : any) : any {
//   try {
//     const data = yield call(api.delete,id);
//     yield put(clientSubscribeActions.deleteSuccess(data));
//   } catch (error:any) {
//     yield put(clientSubscribeActions.deleteFailed(error));
//   }
// }


export default function* clientSubscribeSaga() {
  yield takeLatest(clientSubscribeActions.getListRequest, getListClientSubscribe);
  // yield takeLatest(clientSubscribeActions.getByIdRequest, getByIdClientSubscribe);
  // yield takeLatest(clientSubscribeActions.createRequest, createClientSubscribe);
  yield takeLatest(clientSubscribeActions.updateRequest, updateClientSubscribe);
  // yield takeLatest(clientSubscribeActions.deleteRequest, deleteClientSubscribe);
}
