import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../tenantRole.api'; 
import { tenantRoleActions } from './reducer';

function* getListTenantRole({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(tenantRoleActions.getListSuccess(data));
    yield put(tenantRoleActions.clearAction());
  } catch (error:any) {
    yield put(tenantRoleActions.getListFailed(error));
  }
}

function* getByIdTenantRole({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(tenantRoleActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(tenantRoleActions.getByIdFailed(error));
  }
}

function* createTenantRole({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(tenantRoleActions.createSuccess(data));
    yield delay(500);
    yield put(tenantRoleActions.clearAction());
  } catch (error:any) {
    yield put(tenantRoleActions.createFailed(error));
  }
}

function* updateTenantRole({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(tenantRoleActions.updateSuccess(data));
    yield delay(500);
    yield put(tenantRoleActions.clearAction());
  } catch (error:any) {
    yield put(tenantRoleActions.updateFailed(error));
  }
}
function* deleteTenantRole({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(tenantRoleActions.deleteSuccess(data));
    yield delay(500);
    yield put(tenantRoleActions.clearAction());
  } catch (error:any) {
    yield put(tenantRoleActions.deleteFailed(error));
  }
}


export default function* tenantRoleSaga() {
  yield takeLatest(tenantRoleActions.getListRequest, getListTenantRole);
  yield takeLatest(tenantRoleActions.getByIdRequest, getByIdTenantRole);
  yield takeLatest(tenantRoleActions.createRequest, createTenantRole);
  yield takeLatest(tenantRoleActions.updateRequest, updateTenantRole);
  yield takeLatest(tenantRoleActions.deleteRequest, deleteTenantRole);
}
