import { get, omit } from "lodash";
import requester from "~/api/requester";
import { addProductIntoSection, bodyUpdateProductInSideSection, paramSearchProduct } from "./section.modal";

const apis = {
    getAll: (query?: any) => requester.get(`/api/v1/section-product`, query),
    getById: (id?: any) => requester.get(`/api/v1/section-product/${id}?raw=true`),
    create: (data?: any) => requester.post(`/api/v1/section-product`, data),
    update: (data?: any) => requester.put(`/api/v1/section-product/${get(data,'_id')}`, data),
    delete: (id?: any) => requester.delete(`/api/v1/section-product/${id}`),
    getProductSection: (id?: any) => requester.get(`/api/v1/section-product-by-section/${id}`),
    createProductSection: (payload : addProductIntoSection) => requester.post(`/api/v1/assign-product-to-section`,payload),
    updateProductSection: (payload?: bodyUpdateProductInSideSection) => requester.put(`/api/v1/update-product-to-section/${payload?._id}`,omit(payload,'_id')),
    deleteProductSection: (id : any) => requester.delete(`/api/v1/remove-product-to-section/${id}`),
    searchProductSection: (payload : paramSearchProduct) => requester.post(`/api/v1/search-product-by-section`,payload),
}
export default apis;
