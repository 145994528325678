import { Typography } from "antd";
import { ColumnsType } from "antd/es/table/InternalTable";
import { get } from "lodash";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import TableAnt from "~/components/Antd/TableAnt";
import Status from "~/components/common/Status/index";
import ToolTipBadge from "~/components/common/ToolTipBadge";
import { useBillQueryParamsState, useGetBills } from "~/modules/sale/bill/bill.hook";
import { STATUS_BILL, STATUS_BILL_VI } from "~/modules/sale/bill/constants";
import {
    useQuotationPaging
} from "~/modules/sale/quotation/quotation.hook";
import { PATH_APP } from "~/routes/allPath";
import { concatAddress, formatter } from "~/utils/helpers";
type propsType = {};
export default function BillTab(props: propsType): React.JSX.Element {
  const { id } = useParams();
  const defaultQuery = useMemo(() => ({ visitorIds: id }), [id]);
  const [query, onParamChange] = useBillQueryParamsState(defaultQuery);
  const [data, loading] = useGetBills(query);
  const paging = useQuotationPaging();
  const columns: ColumnsType = [
      {
        title: <p style={{ color: "#333", fontWeight: "bold" }}>Mã đơn hàng</p>,
        dataIndex: "codeSequence",
        key: "codeSequence",
        align: "center",
        width: 150,
        render(codeSequence) {
          return (
            <Link
              className="link_"
              target='_blank'
              to={PATH_APP.bill.root + "?keyword=" +codeSequence}
            >
              {codeSequence}
            </Link>
          );
        },
      },
     
      {
        title: "Tình trạng",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 300,
        render(status, record, index) {
          return (
            <ToolTipBadge
              title={
                status === STATUS_BILL.CANCELLED && get(record, "note", "")
              }
            >
              <Status
                status={status}
                statusVi={get(STATUS_BILL_VI, status, "")}
              />
            </ToolTipBadge>
          );
        }
      },
      {
        title: "Giá trị đơn hàng",
        dataIndex: "totalPrice",
        key: "totalPrice",
        align: "center",
        width: 150,
        render(totalPrice, record, index) {
          return <Typography.Text>{formatter(totalPrice)}</Typography.Text>;
        },
      },
      {
        title: "Khách đã trả",
        dataIndex: "customerPair",
        key: "customerPair",
        align: "center",
        width: 180,
        render(customerPair, record, index) {
          return (
            <Typography.Text>
              {formatter(customerPair)}
            </Typography.Text>
          );
        },
      },      
      {
        title: "Khách phải trả",
        dataIndex: "customerPay",
        key: "customerPay",
        align: "center",
        width: 180,
        render(customerPay, record, index) {
          return <Typography.Text>{formatter(customerPay)}</Typography.Text>;
        },
      },
      {
        title: "Địa chỉ",
        dataIndex: "deliveryAddressId",
        key: "deliveryAddressId",
        width: 350,
        render(value, record, index) {
          return concatAddress(value);
        },
      },
      {
        title: "Ghi chú",
        key: "note",
        width: 100,
        align: "left",
        render(note?: any, record?: any, index?: any) {
          return (
            <>
            <Typography.Paragraph
              ellipsis={{
                tooltip: note,
                rows: 2,
              }}
              >
              {record?.note || ''}
            </Typography.Paragraph>
            <Typography.Paragraph
              ellipsis={{
                tooltip: note,
                rows: 2,
              }}
              >
              {record?.noteBillSplit || ''}
            </Typography.Paragraph>
              </>
          );
        },
      },
      {
        title: "Lý do huỷ",
        dataIndex: "cancelNote",
        key: "cancelNote",
        width: 150,
        align: "left",
        render(cancelNote?: any) {
          return (
            <Typography.Paragraph
              ellipsis={{
                tooltip: cancelNote,
                rows: 2,
              }}
            >
              {cancelNote}
            </Typography.Paragraph>
          );
        },
      },
    ]
  return (
    <div>
      <TableAnt
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1500 }}
        pagination={{
          ...paging,
          onChange(page, pageSize) {
            onParamChange({ page, limit: pageSize });
          },
          showTotal: (total) => `Tổng cộng: ${total}`,
        }}
      />
    </div>
  );
}
