import { omit } from 'lodash';
import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import api from '../tenant.api'; 
import { tenantActions } from './reducer';
const getOldResourceTenant = (state : any) => state.tenant.resourceTenant;
const getOldResourceCustomerChannel = (state : any) => state.tenant.resourceCustomerChannel;

function* getListTenant({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(tenantActions.getListSuccess(data));
    yield put(tenantActions.clearAction());
  } catch (error:any) {
    yield put(tenantActions.getListFailed(error));
  }
}

function* getByIdTenant({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(tenantActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(tenantActions.getByIdFailed(error));
  }
}

function* createTenant({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(tenantActions.createSuccess(data));
    yield delay(500);
    yield put(tenantActions.clearAction());
  } catch (error:any) {
    yield put(tenantActions.createFailed(error));
  }
}

function* updateTenant({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(tenantActions.updateSuccess(data));
    yield delay(500);
    yield put(tenantActions.clearAction());
  } catch (error:any) {
    yield put(tenantActions.updateFailed(error));
  }
}
function* deleteTenant({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(tenantActions.deleteSuccess(data));
    yield delay(500);
    yield put(tenantActions.clearAction());
  } catch (error:any) {
    yield put(tenantActions.deleteFailed(error));
  }
}
function* getResourceConstructorTenant({ payload: tenantId } : any)  : any{
  try {
    const data = yield call(api.getResourceConstructorTenant, tenantId);
    yield put(tenantActions.getResourceConstructorTenantSuccess(data));
  } catch (error : any) {
    yield put(tenantActions.getResourceConstructorTenantFailed(error));

  }
};
function* getResourceTenant({ payload: tenantId } : any)  : any{
  try {
    const data = yield call(api.getResourceTenant, tenantId);
    yield put(tenantActions.getResourceTenantSuccess(data));
  } catch (error : any) {
    yield put(tenantActions.getResourceTenantFailed(error));

  }
};
function* accessResourceTenant({ payload: query } : any)  : any{
  try {
    const data = yield call(api.accessResourceTenant, query);
    const oldResource = yield select(getOldResourceTenant);
    const newResource = {
      ...oldResource,
    }
    query?.resources?.forEach((rs : any) => newResource[rs] = rs);
    yield put(tenantActions.accessResourceTenantSuccess(data));
    yield put(tenantActions.getResourceTenantSuccess(newResource));
  } catch (error : any) {
    yield put(tenantActions.accessResourceTenantFailed(error));

  }
};
function* denyResourceTenant({ payload: query } : any)  : any{
  try {
    const data = yield call(api.denyResourceTenant, query);
    const oldResource = yield select(getOldResourceTenant);
    const newResource : any = omit(oldResource,query?.resources?.map((rs : any) => rs));
    data?.resources?.forEach((rs : any) => newResource[rs] = rs);
    yield put(tenantActions.denyResourceTenantSuccess(data));
    yield put(tenantActions.getResourceTenantSuccess(newResource));
  } catch (error : any) {
    yield put(tenantActions.denyResourceTenantFailed(error));

  }
};
function* getResourceConstructorCustomerChannel({ payload } : any)  : any{
  try {
    const data = yield call(api.getResourceConstructorCustomerChannel, payload);
    yield put(tenantActions.getResourceConstructorCustomerChannelSuccess(data));
  } catch (error : any) {
    yield put(tenantActions.getResourceConstructorCustomerChannelFailed(error));

  }
};
function* getResourceCustomerChannel({ payload } : any)  : any{
  try {
    const data = yield call(api.getResourceCustomerChannel, payload);
    yield put(tenantActions.getResourceCustomerChannelSuccess(data));
  } catch (error : any) {
    yield put(tenantActions.getResourceCustomerChannelFailed(error));

  }
};
function* accessResourceCustomerChannel({ payload: query } : any)  : any{
  try {
    const data = yield call(api.accessResourceCustomerChannel, query);
    const oldResource = yield select(getOldResourceCustomerChannel);
    const newResource = {
      ...oldResource,
    }
    query?.resources?.forEach((rs : any) => newResource[rs] = rs);
    yield put(tenantActions.accessResourceCustomerChannelSuccess(data));
    yield put(tenantActions.getResourceCustomerChannelSuccess(newResource));
  } catch (error : any) {
    yield put(tenantActions.accessResourceCustomerChannelFailed(error));

  }
};
function* denyResourceCustomerChannel({ payload: query } : any)  : any{
  try {
    const data = yield call(api.denyResourceCustomerChannel, query);
    const oldResource = yield select(getOldResourceCustomerChannel);
    const newResource : any = omit(oldResource,query?.resources?.map((rs : any) => rs));
    data?.resources?.forEach((rs : any) => newResource[rs] = rs);
    yield put(tenantActions.denyResourceCustomerChannelSuccess(data));
    yield put(tenantActions.getResourceCustomerChannelSuccess(newResource));
  } catch (error : any) {
    yield put(tenantActions.denyResourceCustomerChannelFailed(error));

  }
};




export default function* tenantSaga() {
  yield takeLatest(tenantActions.getListRequest, getListTenant);
  yield takeLatest(tenantActions.getByIdRequest, getByIdTenant);
  yield takeLatest(tenantActions.createRequest, createTenant);
  yield takeLatest(tenantActions.updateRequest, updateTenant);
  yield takeLatest(tenantActions.deleteRequest, deleteTenant);
  yield takeLatest(tenantActions.getResourceConstructorTenantRequest, getResourceConstructorTenant);
  yield takeLatest(tenantActions.getResourceTenantRequest, getResourceTenant);
  yield takeLatest(tenantActions.accessResourceTenantRequest, accessResourceTenant);
  yield takeLatest(tenantActions.denyResourceTenantRequest, denyResourceTenant);
  yield takeLatest(tenantActions.getResourceConstructorCustomerChannelRequest, getResourceConstructorCustomerChannel);
  yield takeLatest(tenantActions.getResourceCustomerChannelRequest, getResourceCustomerChannel);
  yield takeLatest(tenantActions.accessResourceCustomerChannelRequest, accessResourceCustomerChannel);
  yield takeLatest(tenantActions.denyResourceCustomerChannelRequest, denyResourceCustomerChannel);
}
